import { Notifier } from '@airbrake/browser';
import PropTypes from 'prop-types';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    if ($tlf && $tlf.airbrake) {
      const airbrake = new Notifier({
        projectId: $tlf.airbrake.jsProjectId,
        projectKey: $tlf.airbrake.jsApiKey,
        environment: $tlf.environment,
        keysBlocklist: [/ssn/, /password/, /social_security/, /account_number/],
      });

      airbrake.notify({
        error,
        params: { info },
      });
    }
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
