import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import childrenShape from 'react/shared/shapes/children';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderRadius: '6px',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    position: 'relative',
  },
  title: {
    fontWeight: '600',
  },
  iconText: {
    width: 'calc(100% - 80px) !important',
  },
  dismissableIconText: {
    width: 'calc(100% - 90px) !important',
  },
  dismissableText: {
    width: 'calc(100% - 40px) !important',
  },
  iconWrapper: {
    width: '32px !important',
    [theme.breakpoints.down(768)]: {
      width: '64px',
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
  },
  icon: {
    fontSize: '32px',
  },
  dismissIcon: {
    fontSize: '16px',
  },
  dismissIconWrapper: {
    position: 'absolute',
    top: 0,
    right: '-2px',
    width: '40px',
  },
  callToActionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  mobileCallToActionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  warning: {
    backgroundColor: PALETTE.orangeLight,
    borderColor: PALETTE.orange,
    '& $icon': {
      color: PALETTE.orange,
    },
  },
  error: {
    backgroundColor: PALETTE.redLight,
    borderColor: PALETTE.red,
    '& $icon': {
      color: PALETTE.red,
    },
  },
  info: {
    backgroundColor: PALETTE.blueLight,
    borderColor: PALETTE.blue,
    '& $icon': {
      color: PALETTE.blue,
    },
  },
  promo: {
    backgroundColor: PALETTE.emeraldLight,
    borderColor: PALETTE.emerald,
    '& $icon': {
      color: PALETTE.evergreen,
    },
  },
  neutral: {
    backgroundColor: PALETTE.grey,
    borderColor: PALETTE.grey4,
    '& $icon': {
      color: PALETTE.grey4,
    },
  },
  mobileColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

export default function TrueLinkBanner({
  dismissable,
  onDismiss,
  text,
  icon,
  title,
  variant,
  callToAction,
  className,
  svg,
}) {
  const classes = useStyles();
  const [isDismissed, setIsDismissed] = useState(false);
  const isTablet = useMediaQuery('(max-width: 768px');

  const handleDismiss = () => {
    setIsDismissed(true);
    onDismiss();
  };

  if (isDismissed) {
    return null;
  }

  let containerStyles;

  return (
    <div
      className={classNames(classes[variant], classes.root, {
        [className]: className,
      })}
      style={containerStyles}
    >
      <Grid alignItems="center" container direction={isTablet ? 'column' : 'row'}>
        {icon && (
          <Grid alignItems="center" className={classes.iconWrapper} item xs={1}>
            <TrueLinkIcon ariaLabel={`${variant} Icon`} className={classes.icon} icon={icon} />
          </Grid>
        )}
        {svg && (
          <Grid alignItems="center" className={classes.iconWrapper} item xs={1}>
            <img alt={`${variant} Icon`} className={classes.img} src={svg} />
          </Grid>
        )}
        <Grid
          className={classNames({
            [classes.dismissableText]: dismissable && !(icon || svg),
            [classes.iconText]: (icon || svg) && !dismissable,
            [classes.dismissableIconText]: (icon || svg) && dismissable,
            [classes.mobileColumn]: isTablet && classes.mobileColumn,
          })}
          container
          direction={isTablet ? 'column' : 'row'}
          item
          xs={dismissable || icon || svg ? 10.5 : 12}
        >
          <Grid
            container
            direction="column"
            item
            md={callToAction ? 10 : 12}
            sm={callToAction ? 7 : 12}
            xs={12}
          >
            {title && (
              <Grid item>
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
            )}
            {text && (
              <Grid item>
                <Typography>{text}</Typography>
              </Grid>
            )}
          </Grid>
          {callToAction && (
            <Grid
              className={isTablet ? classes.mobileCallToActionWrapper : classes.callToActionWrapper}
              item
              md={2}
              sm={5}
              xs={12}
            >
              {callToAction}
            </Grid>
          )}
        </Grid>
        {dismissable && (
          <span className={classes.dismissIconWrapper}>
            <TrueLinkIcon
              ariaLabel="Dismiss button"
              className={classes.dismissIcon}
              icon="x"
              onClick={handleDismiss}
            />
          </span>
        )}
      </Grid>
    </div>
  );
}

export const bannerVariants = ['warning', 'error', 'info', 'neutral', 'promo'];

TrueLinkBanner.propTypes = {
  className: PropTypes.string,
  callToAction: childrenShape,
  dismissable: PropTypes.oneOf([true]),
  icon: PropTypes.string,
  svg: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  variant: PropTypes.oneOf(bannerVariants).isRequired,
  onDismiss: PropTypes.func,
};
