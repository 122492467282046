import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import ImageViewer from './ImageViewer';
import PdfViewer from './PdfViewer';
import PALETTE from 'react/shared/theme/palette';
export default function DocumentViewModal({ url, name, onClose }) {
  const isPdfView = /\.pdf(\?.*)?$/i.test(url);

  const topBar = (
    <AppBar sx={{ position: 'relative', backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar sx={{ color: PALETTE.white }}>
        <Typography component="div" sx={{ ml: 2, flex: 1 }} variant="h6">
          {name}
        </Typography>
        <IconButton
          aria-label="close"
          color="inherit"
          id="cta-close-document-view-modal"
          onClick={onClose}
          sx={{ '& .MuiSvgIcon-root': { fill: PALETTE.white } }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );

  return (
    <Dialog
      PaperProps={{
        sx: { borderRadius: 1, backgroundColor: PALETTE.blackTransparent },
      }}
      className="document-view-modal"
      fullScreen
      onClose={onClose}
      open
      sx={{ margin: '30px 100px' }}
    >
      {topBar}
      <DialogContent
        sx={{
          padding: isPdfView ? 0 : 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: isPdfView ? 'flex-start' : 'center',
          height: '85vh',
          overflow: 'auto',
        }}
      >
        {isPdfView ? <PdfViewer url={url} /> : <ImageViewer url={url} />}
      </DialogContent>
    </Dialog>
  );
}

DocumentViewModal.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
