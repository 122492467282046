import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import api from './api';
import ScheduledAutoTransfersSection from './auto_transfers/ScheduledAutoTransfersSection';
import BankAccounts from './bank_accounts/BankAccounts';
import DirectDepositInfoBanner from './info_banners/DirectDepositInfoBanner';
import FamilyFundingAdminInfoBanner from './info_banners/FamilyFundingAdminInfoBanner';
import ScheduledTransfersSection from './transfers/ScheduledTransfersSection';
import TransferHistorySection from './transfers/TransferHistorySection';
import TransfersInTheNextSevenDaysSection from './transfers/TransfersInTheNextSevenDaysSection';
import Promotions from 'react/member/components/referrals/Promotions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkSurveyBanner from 'react/shared/components/customer_banners/TrueLinkSurveyBanner';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles({
  fundingTab: {
    '& input[disabled]': {
      opacity: 0.3,
    },
  },
  fundingTabSection: {
    marginBottom: '40px',
    [theme.breakpoints.down('md')]: {
      margin: '2%',
      width: '100%',
      overflowX: 'scroll',
    },
  },
});

export default function CardFundingTabContainer({
  accountId,
  card,
  copyableBankAccounts,
  isFamilyFunder,
  isViewOnly,
  mockPublicToken,
}) {
  const classes = useStyles();
  const [account, setAccount] = useState(null);

  useEffect(() => {
    api.getAccount(accountId).then((response) => {
      setAccount(response.data.data);
    });
  }, [accountId]);

  if (account === null) return <LoadingIndicator />;

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <TrueLinkSurveyBanner email={account.meta?.contactInfo?.email} />
          <Promotions />
          <div className={classes.fundingTab} id="le_funding_tab">
            <div className={classes.fundingTabSection}>
              <BankAccounts
                account={account}
                card={card}
                copyableBankAccounts={copyableBankAccounts}
                isViewOnly={isViewOnly}
                mockPublicToken={mockPublicToken}
              />
              {account.meta.allowsFamilyFunder && !isFamilyFunder && (
                <FamilyFundingAdminInfoBanner />
              )}
            </div>
            <div className={classes.fundingTabSection}>
              <TransfersInTheNextSevenDaysSection
                account={account}
                cardLast4={card?.last_4_digits}
                cardStatus={card?.status}
                isViewOnly={isViewOnly}
              />
            </div>
            <div className={classes.fundingTabSection}>
              <ScheduledAutoTransfersSection
                account={account}
                cardStatus={card?.status}
                isViewOnly={isViewOnly}
              />
            </div>
            <div className={classes.fundingTabSection}>
              <ScheduledTransfersSection
                account={account}
                cardStatus={card?.status}
                isViewOnly={isViewOnly}
              />
            </div>
            <div className={classes.fundingTabSection}>
              <TransferHistorySection />
            </div>
            {!isFamilyFunder && !isViewOnly && <DirectDepositInfoBanner />}
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

CardFundingTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  card: PropTypes.object,
  copyableBankAccounts: PropTypes.array,
  isFamilyFunder: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  mockPublicToken: PropTypes.string,
};
