import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import _contains from 'underscore/modules/contains';
import _map from 'underscore/modules/map';
import CardTransactionAttachmentRow from './CardTransactionAttachmentRow';
import Processing from 'images/processing.gif';
import DashboardActions from 'react/member/actions/dashboard_actions';
import TransactionAttachmentStore from 'react/member/stores/TransactionAttachmentStore';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

export default function CardTransactionAttachments({
  canEdit,
  cardTransactionId,
  isCardholderView,
}) {
  const [attachments, setAttachments] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const fileInputRef = useRef(null);

  const onUpload = () => {
    setShowSpinner(false);
  };

  useEffect(() => {
    const onFetchAttachments = (transactionId, newAttachments) => {
      if (cardTransactionId === transactionId) {
        setAttachments(newAttachments);
      }
    };

    TransactionAttachmentStore.on('attachments.fetch', onFetchAttachments);
    TransactionAttachmentStore.on('attachment.upload', onUpload);

    if (cardTransactionId) {
      DashboardActions.fetchTransactionAttachmentsByTransaction(cardTransactionId);
    }

    return () => {
      TransactionAttachmentStore.off('attachments.fetch', onFetchAttachments);
      TransactionAttachmentStore.off('attachment.upload', onUpload);
    };
  }, [cardTransactionId]);

  const allowedExtensionsErrorMessage = (fileName) =>
    `You tried to upload "${fileName}". We currently do not support this file type. Please use these file types: ${$tlf.domains.allowedExtensions.join(
      ', ',
    )}`;

  const upload = (evt) => {
    Truelink.flashClear();
    const fileInput = evt.target;
    if (fileInput.value !== '') {
      const fileName = fileInput.files[0].name;
      const ext = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
      if (_contains($tlf.domains.allowedExtensions, ext)) {
        setShowSpinner(true);
        TransactionAttachmentStore.uploadAttachmentToTransaction(fileInput, cardTransactionId);
      } else {
        Truelink.flash('error', allowedExtensionsErrorMessage(fileName));
      }
    }
  };

  const uploadButton = () => {
    if (!canEdit) return null;

    const onClick = (evt) => {
      evt.preventDefault();
      fileInputRef.current.click();
    };
    return (
      <div className="inline-block">
        <TrueLinkLink ariaLabel="Add a receipt" onClick={onClick}>
          + Add receipt
        </TrueLinkLink>
      </div>
    );
  };

  const renderAttachments = () => {
    const links =
      attachments.length !== 0 ? (
        <div>
          {_map(attachments, (attachment) => (
            <CardTransactionAttachmentRow
              attachment={attachment}
              canEdit={canEdit}
              cardTransactionId={cardTransactionId}
              isCardholderView={isCardholderView}
              key={attachment.id}
            />
          ))}
        </div>
      ) : null;

    return (
      <div className="new-form__data">
        {links}
        {uploadButton()}
        {showSpinner && (
          <span style={{ marginLeft: 15 }}>
            <span style={{ margin: 8, verticalAlign: 'middle' }}>
              <img
                alt="Loading..."
                className="inline-block"
                height={20}
                src={Processing}
                style={{ marginTop: -6 }}
                width={20}
              />
            </span>
          </span>
        )}
      </div>
    );
  };

  // we do this so that we always have an array of attachments
  const blankInput = (
    <input name="attachment_ids[]" readOnly style={{ display: 'none' }} value="" />
  );

  return (
    <div className="new-form__section new-form--compact new-form__section--inset">
      <div className="new-form-field">
        <div className="new-form__label">
          <label htmlFor="attachment[file]">Receipts:</label>
        </div>
        {blankInput}
        {renderAttachments()}
        <input
          name="attachment[file]"
          onChange={upload}
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
        />
      </div>
    </div>
  );
}

CardTransactionAttachments.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  cardTransactionId: PropTypes.string.isRequired,
  isCardholderView: PropTypes.bool,
};
