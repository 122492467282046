import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AmountCell from './AmountCell';
import AttachmentCell from './AttachmentCell';
import DescriptionCell from './DescriptionCell';
import EditCell from './EditCell';
import EditableCell from './EditableCell';
import WealthAccountEventModal from './WealthAccountEventModal';
import api from './api';
import DateCell from 'react/member/components/dashboard/deposits/individual_trusts/DateCell';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function IndividualDepositsTab({ clientSlug }) {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const flipperIndividualTrustDepositAttachments = $tlf.flipperIndividualTrustDepositAttachments;
  const [selectedDepositId, setSelectedDepositId] = useState(null);

  const updateWealthAccountEvent = useCallback(
    (rowIndex, columnId, value) => {
      const wealthAccountEvent = deposits[rowIndex];

      wealthAccountEvent[columnId] = value;
      api.putWealthAccountEvent(clientSlug, wealthAccountEvent);
      Truelink.flash('success', 'Deposit Updated!');
    },
    [clientSlug, deposits],
  );

  const fetchDeposits = useCallback(async () => {
    const { data } = await api.getWealthAccountEvents(clientSlug);
    setDeposits(data);
  }, [clientSlug]);

  useEffect(() => {
    fetchDeposits();
    setLoading(false);
  }, [clientSlug, fetchDeposits]);

  const columnsWithoutAttachment = useMemo(
    () => [
      {
        Header: 'Process Date',
        accessor: 'posted_at',
        Cell: DateCell,
      },
      {
        Header: 'Type',
        accessor: 'type_description',
      },
      {
        Header: 'Description',
        accessor: (row) => ({ key: 'memo', memo: row.memo, updateWealthAccountEvent }),
        Cell: EditableCell,
      },
      {
        Header: 'Amount',
        accessor: (row) => tlFieldTransformers.formatMoney(row.amount),
        Cell: AmountCell,
      },
    ],
    [updateWealthAccountEvent],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Process Date',
        accessor: 'posted_at',
        Cell: DateCell,
      },
      {
        Header: 'Type',
        accessor: 'type_description',
      },
      {
        Header: 'Description',
        accessor: 'memo',
        Cell: DescriptionCell,
        getCellProps: () => ({
          onClick: (cell) => {
            setSelectedDepositId(cell.row.original.id);
          },
        }),
      },
      {
        Header: 'Attachment',
        accessor: 'has_attachments',
        Cell: AttachmentCell,
        getCellProps: () => ({
          onClick: (cell) => {
            setSelectedDepositId(cell.row.original.id);
          },
        }),
      },
      {
        Header: 'Amount',
        accessor: (row) => tlFieldTransformers.formatMoney(row.amount),
        Cell: AmountCell,
      },
      {
        disableSort: true,
        sortable: false,
        accessor: 'actions',
        Cell: EditCell,
        getCellProps: () => ({
          onClick: (cell) => {
            setSelectedDepositId(cell.row.original.id);
          },
        }),
      },
    ],
    [setSelectedDepositId],
  );

  const selectedDeposit = deposits.find((deposit) => deposit.id === selectedDepositId);

  return (
    <div>
      <TrueLinkTable
        columns={flipperIndividualTrustDepositAttachments ? columns : columnsWithoutAttachment}
        data={deposits}
        initialSortBy={[{ id: 'posted_at', desc: true }]}
        loading={loading}
        minColumnWidth={5}
        noDataText="No data available in table"
        pageSize={20}
        paginated
        sortable
      />
      {selectedDepositId && (
        <WealthAccountEventModal
          amount={selectedDeposit.amount}
          beneficiarySlug={clientSlug}
          id={selectedDeposit.id}
          memo={selectedDeposit.memo}
          onCancel={() => setSelectedDepositId(null)}
          onSuccess={async () => {
            setSelectedDepositId(null);
            fetchDeposits();
          }}
          refetchEvents={async () => {
            fetchDeposits();
          }}
        />
      )}
    </div>
  );
}

IndividualDepositsTab.propTypes = {
  clientSlug: PropTypes.string.isRequired,
};
