import axios from 'axios';

// Creates an attachment given appropriate form data
// Form data should include the following:
// attachment[file] - the file to be uploaded
export const createAttachment = async ({ file, attachable }) => {
  // This data format conforms to the old Rails API, which expects an attachable_id and attachable_type
  // Eventually when we have a v2 attachments#create api we'll need to change the format to match the
  // JSON API spec like we have elsewhere in the v2 api.
  const attachableType =
    attachable.type === 'disbursement_category_amount'
      ? 'DisbursementCategoryAmount'
      : attachable.type;
  const formData = new window.FormData();
  formData.append('attachment[file]', file);
  const uploadUrl = RailsRoutes.api_v2_attachments_path({
    format: 'json',
    attachableId: attachable.id,
    attachableType,
  });
  const res = await axios.post(uploadUrl, formData, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
};

// Fetches attachments for a given attachable, where attachable has an id and type
export const fetchAttachments = async ({ attachable }) => {
  const url = RailsRoutes.api_v2_attachments_path({
    format: 'json',
    attachableId: attachable.id,
    attachableType: attachable.type,
  });
  const res = await axios.get(url);
  return res.data;
};
