import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DocumentViewButton from 'react/shared/components/document_viewer/DocumentViewButton';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import PALETTE from 'react/shared/theme/palette';
export default function PendingDisbursementCategoryAmountAttachment({
  attachment,
  isEditing,
  deleteAttachment,
  setFieldValue,
  fieldName,
  value,
}) {
  const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] = useState(false);
  const onDeleteAttachment = () => {
    setShowDeleteAttachmentModal(false);
    deleteAttachment(attachment.id);
  };

  const onChange = () => {
    if (value) {
      setFieldValue(fieldName, null);
    } else {
      setFieldValue(fieldName, attachment.id);
    }
  };

  const smartpayablesInsertControl = () => {
    // Temporarily need to handle both of these until all attachment APIs are on V2
    const isPDFAttachment =
      attachment.attributes.content_type === 'application/pdf' ||
      attachment.attributes.contentType === 'application/pdf';
    // Temporarily need to handle both of these until all attachment APIs are on V2
    const isSmartpayablesInsert =
      attachment.attributes.smartpayables_insert || attachment.attributes.smartpayablesInsert;

    if (!isEditing) {
      return isSmartpayablesInsert ? (
        <span>
          <i className="icon-checkmark" />
          <input key={attachment.id} name={fieldName} type="hidden" value={attachment.id} />
        </span>
      ) : undefined;
    } else if (isPDFAttachment) {
      return (
        <input
          checked={value && value === attachment.id}
          disabled={value && value !== attachment.id}
          key={attachment.id}
          name={fieldName}
          onChange={onChange}
          type="checkbox"
          value={value}
        />
      );
    }
    return <span className="italic">File must be a PDF to include as insert</span>;
  };

  return (
    <tr>
      <td className="break-word" style={{ verticalAlign: 'middle' }}>
        <a className="inline-block" href={RailsRoutes.api_v2_attachment_path(attachment.id)}>
          {attachment.attributes.name}
        </a>
      </td>
      <td>
        <div className="inline-block">{smartpayablesInsertControl()}</div>
      </td>
      <td className="align-right">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'flex-end' }}>
          {$tlf.flipperShowViewDocumentButton && (
            <DocumentViewButton attachment={attachment.attributes} />
          )}
          <Button
            aria-label="view document"
            color="secondary"
            href={RailsRoutes.api_v2_attachment_path(attachment.id)}
            size="small"
            startIcon={<DownloadIcon sx={{ fill: PALETTE.blue }} />}
            sx={{ textTransform: 'capitalize', color: PALETTE.blue }}
          >
            Download
          </Button>
          <Button
            aria-label="delete document"
            id="delete-document-button"
            onClick={() => setShowDeleteAttachmentModal(true)}
            size="small"
            startIcon={<DeleteIcon sx={{ fill: PALETTE.blue }} />}
            sx={{ textTransform: 'capitalize', color: PALETTE.blue }}
          >
            Delete
          </Button>
          {showDeleteAttachmentModal && (
            <AreYouSurePopUp
              key={attachment.id}
              messageText={'Are you sure you want to delete this document?'}
              onClose={() => setShowDeleteAttachmentModal(false)}
              onSubmit={onDeleteAttachment}
              openModal={showDeleteAttachmentModal}
              submitText={'Yes, delete'}
            />
          )}
        </Box>
      </td>
    </tr>
  );
}

PendingDisbursementCategoryAmountAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  deleteAttachment: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  value: PropTypes.string,
  fieldName: PropTypes.string,
};
