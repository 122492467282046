import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import DocumentViewButton from 'react/shared/components/document_viewer/DocumentViewButton';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import bindAll from 'react/shared/utils/bind_all';
export default class DisbursementAttachment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    bindAll(this);
  }

  onDestroy(evt) {
    evt.preventDefault();
    const { disbursement, disbursementCategoryAmount, attachment, actions } = this.props;
    if (disbursementCategoryAmount) {
      actions.destroyBudgetItemAttachment(attachment.id);
    } else if (disbursement) {
      actions.destroyBudgetItemAttachmentForBudgetItem(attachment.id, disbursement);
    } else {
      actions.destroyBudgetItemAttachment(attachment.id);
    }
    this.closeDestructionModal();
  }

  closeDestructionModal() {
    this.setState({ showModal: false });
  }

  showDestructionModal(evt) {
    evt.stopPropagation();
    this.setState({ showModal: true });
  }

  renderRemoveButton() {
    const { attachment, categoryNumber, disbursement } = this.props;
    if (disbursement && disbursement.can_add_documentation === false) {
      return null;
    }

    const inputName = `attachment_ids[${categoryNumber}][]`;
    return (
      <div>
        <Button
          aria-label="delete document"
          id="delete-document-button"
          onClick={this.showDestructionModal}
          size="small"
          startIcon={<DeleteIcon />}
          sx={{ textTransform: 'capitalize' }}
        >
          Delete
        </Button>
        <input defaultValue={attachment.id} name={inputName} style={{ display: 'none' }} />
        {this.state.showModal && (
          <AreYouSurePopUp
            key={attachment.id}
            messageText={'Are you sure you want to delete this document?'}
            onClose={this.closeDestructionModal}
            onSubmit={this.onDestroy}
            openModal={this.state.showModal}
            submitText={'Yes, delete'}
          />
        )}
      </div>
    );
  }

  render() {
    let smartpayablesInsertControl;
    let inputName;
    const { attachment, readOnly } = this.props;

    if (readOnly) {
      inputName = `attachment_smartpayables_insert_ids[${this.props.categoryNumber}][]`;
      smartpayablesInsertControl =
        // Temporarily need to handle both of these until all attachment APIs are on V2
        attachment.smartpayables_insert || attachment.smartpayablesInsert ? (
          <span>
            <i className="icon-checkmark" />
            <input
              key={attachment.id}
              name={inputName}
              type="hidden"
              value={attachment.id.toString()}
            />
          </span>
        ) : undefined;
    } else if (
      // Temporarily need to handle both of these until all attachment APIs are on V2
      attachment.content_type === 'application/pdf' ||
      attachment.contentType === 'application/pdf'
    ) {
      inputName = `attachment_smartpayables_insert_ids[${this.props.categoryNumber}][]`;
      smartpayablesInsertControl = (
        <input
          disabled={this.props.insertDisabled}
          key={attachment.id}
          name={inputName}
          onChange={this.props.checkboxOnchange}
          type="checkbox"
          value={attachment.id.toString()}
        />
      );
    } else {
      smartpayablesInsertControl = (
        <span className="italic">File must be a PDF to include as insert</span>
      );
    }

    const url = RailsRoutes.api_v2_attachment_path(attachment.id);

    return (
      <tr className="disbursement-attachment-row" key={attachment.id}>
        <td className="break-word" style={{ verticalAlign: 'middle' }}>
          <a className="inline-block" href={url}>
            {' '}
            {attachment.name}
          </a>
        </td>
        <td style={{ width: 300 }}>
          <div className="inline-block">{smartpayablesInsertControl}</div>
        </td>
        <td style={{ padding: '0 10px', verticalAlign: 'middle' }}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-end' }}
          >
            {$tlf.flipperShowViewDocumentButton && <DocumentViewButton attachment={attachment} />}
            <Button
              aria-label="view document"
              href={url}
              size="small"
              startIcon={<DownloadIcon />}
              sx={{ textTransform: 'capitalize' }}
            >
              Download
            </Button>
            {this.renderRemoveButton()}
          </Box>
        </td>
      </tr>
    );
  }
}

DisbursementAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  checkboxOnchange: PropTypes.func.isRequired,
  disbursement: PropTypes.object,
  disbursementCategoryAmount: PropTypes.object,
  insertDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  categoryNumber: PropTypes.number,
};
