import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import CategoryDropdown from 'react/member/components/dashboard/CategoryDropdown';
import DisbursementCategoryAmountAttachments from 'react/member/components/dashboard/disbursements/filter_table/disbursements_category_amounts/DisbursementCategoryAmountAttachments';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  newFormField: {
    minHeight: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  newFormLabel: {
    width: '220px',
    lineHeight: '1.2em',
    paddingTop: '9px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  newFormData: {
    width: 'calc(100% - 220px)',
    fontSize: '18px',
    lineHeight: '1.2em',
  },
  DisbursementCategoryAmounts: {
    '& .MuiInputBase-root:not(.MuiInputBase-multiline)': {
      width: '300px',
    },
    '& select': {
      width: '300px',
      height: '45px',
    },
    '& .chosen-single': {
      height: '45px',
      paddingTop: '10px',
    },
    '& .chosen-container': {
      marginBottom: '10px',
    },
  },
}));

export default function DisbursementCategoryAmounts({
  allowInsert,
  canAddAttachments,
  checkInsertAttachmentId,
  clientId,
  disbursementCategoryAmounts,
  disbursementId,
  isEditing,
  handleChange,
  setFieldValue,
  setFieldTouched,
  statusType,
  values,
}) {
  const classes = useStyles();

  const handleCategoryChange = (ev, index) => {
    if (ev.target.value) {
      setFieldValue(`categories[${index}].category`, ev.target.value);
      setFieldTouched(`categories[${index}].category`, true);
    }
  };

  const categoryMemoField = (category, index) => {
    if (isEditing) {
      return (
        <TrueLinkTextInput
          fullWidth
          multiline
          name={`categories[${index}].memo`}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          value={values.categories[index]?.memo}
        />
      );
    } else if (category.attributes.notes) {
      return category.attributes.notes;
    }
    return <Box style={{ fontStyle: 'italic' }}>No note</Box>;
  };

  return (
    disbursementCategoryAmounts && (
      <>
        {disbursementCategoryAmounts.map((category, index) => (
          <tr className={classes.DisbursementCategoryAmounts} key={category.id}>
            <td colSpan={8}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <Box className={classes.newFormField}>
                    <Box className={classes.newFormLabel}>Amount:</Box>{' '}
                    <Box className={classes.newFormData}>
                      {isEditing ? (
                        <TrueLinkTextInput
                          name={`categories[${index}].amount`}
                          onChange={handleChange}
                          setFieldTouched={setFieldTouched}
                          value={values.categories[index]?.amount}
                        />
                      ) : (
                        asMoney(category.attributes.amount.amount)
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.newFormField}>
                    <Box className={classes.newFormLabel}>Category:</Box>{' '}
                    <Box className={classes.newFormData}>
                      {isEditing ? (
                        <CategoryDropdown
                          categoryNumber={category.id}
                          hideLabel
                          initialValue={category.attributes.category}
                          isExistingRecord
                          isRecurringDisbursement={false}
                          onChange={(ev) => handleCategoryChange(ev, index)}
                          organizationSlug={clientId}
                        />
                      ) : (
                        category.attributes.category
                      )}
                    </Box>
                  </Box>
                  <Box
                    borderBottom={'1px solid lightgrey'}
                    className={classes.newFormField}
                    mb={'16px'}
                    pb={'24px'}
                  >
                    <Box className={classes.newFormLabel}>Internal Notes:</Box>{' '}
                    <Box className={classes.newFormData}>{categoryMemoField(category, index)}</Box>
                  </Box>
                  <DisbursementCategoryAmountAttachments
                    allowInsert={allowInsert}
                    canAddAttachments={canAddAttachments}
                    checkInsertAttachmentId={checkInsertAttachmentId}
                    disbursementCategoryAmount={category}
                    disbursementId={disbursementId}
                    fieldName={`categories[${index}].selected_insert`}
                    isEditing={isEditing}
                    setFieldValue={setFieldValue}
                    statusType={statusType}
                    value={values?.categories?.[index]?.selected_insert}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </>
    )
  );
}

DisbursementCategoryAmounts.propTypes = {
  allowInsert: PropTypes.bool,
  canAddAttachments: PropTypes.bool,
  checkInsertAttachmentId: PropTypes.string,
  clientId: PropTypes.string,
  disbursementCategoryAmounts: PropTypes.array,
  disbursementId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  handleChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  statusType: PropTypes.string,
  values: PropTypes.object,
};
