import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

export default function DescriptionCell({ value, cell, column }) {
  const cellProps = column.getCellProps();

  if (value) {
    return <>{value}</>;
  }

  return (
    <TrueLinkLink
      onClick={(e) => {
        e.preventDefault();
        cellProps.onClick(cell);
      }}
    >
      +Add
    </TrueLinkLink>
  );
}

DescriptionCell.propTypes = {
  value: PropTypes.string,
  column: PropTypes.object.isRequired,
  cell: PropTypes.object.isRequired,
};
