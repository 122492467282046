import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MoneyTransferSvg from 'images/money-transfer.svg';
import JotFormSurveyModal from 'react/shared/components/customer_banners/JotFormSurveyModal';
import TrueLinkBanner from 'react/shared/components/true_link/lab/TrueLinkBanner';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles(() => ({
  surveyBanner: {
    '& p': {
      fontSize: '16px !important',
    },
  },
}));

export default function TrueLinkSurveyBanner({ email }) {
  const classes = useStyles();

  const formId = '250545814228154';
  const formUrl = `https://form.jotform.com/${formId}?&email=${email}}`;

  const hideSurveyBanner = window.localStorage.getItem('hideSurveyBanner');

  const [showSurveyBanner, setShowSurveyBanner] = useState(!hideSurveyBanner);
  const [showSurveyModal, setShowSurveyModal] = useState(false);

  const handleDismiss = () => {
    window.localStorage.setItem('hideSurveyBanner', 'true');
    setShowSurveyBanner(false);
  };

  const handleSubmit = () => {
    setShowSurveyModal(false);
    handleDismiss();
  };

  if (!showSurveyBanner || !$tlf.flipperShowInstantFundingPrompt) {
    return null;
  }

  return (
    <div className={classes.surveyBanner}>
      <TrueLinkBanner
        callToAction={
          <TrueLinkButton
            id="cta-survey-banner-notify-me"
            onClick={() => setShowSurveyModal(true)}
            variant="primary"
          >
            Notify me
          </TrueLinkButton>
        }
        dismissable
        onDismiss={handleDismiss}
        svg={MoneyTransferSvg}
        text={`Instant funding is in the works. Be the first to know when it's available, and get early access!`}
        title="Instantly transfer funds to your cardholders – even on weekends and holidays"
        variant="promo"
      />
      {showSurveyModal && (
        <JotFormSurveyModal
          formUrl={formUrl}
          onClose={() => setShowSurveyModal(false)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

TrueLinkSurveyBanner.propTypes = {
  email: PropTypes.string,
};
