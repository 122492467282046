import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardOneTimePayeeUpdateForm from 'react/member/components/dashboard/disbursements/disbursement_details/DashboardOneTimePayeeUpdateForm';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';

export default function DisbursementPayeeDetails({
  bankRoutingNumber,
  bankAccountNumber,
  clientAccountNumber,
  disposition,
  isEditing,
  paymentMethod,
  swiftCode,
  specialInstructions,
  statusFilter,
  disbursement,
  addressAttributes: { street1, street2, city, state, zip },
}) {
  const [showOneTimePayeeUpdateModal, setShowOneTimePayeeUpdateModal] = useState(false);

  function address() {
    // null values get rendered as the string 'null' when concatenated.
    return `${street1 || ''} ${street2 || ''} ${city || ''}, ${state || ''} ${zip || ''}`;
  }
  function hasAddress() {
    return address().replace(',', '').trim().length > 0;
  }

  function toggleOneTimePayeeUpdateModal() {
    setShowOneTimePayeeUpdateModal(!showOneTimePayeeUpdateModal);
  }

  function renderOneTimePayeeUpdateModalLink() {
    return (
      <div>
        <a href="#" onClick={toggleOneTimePayeeUpdateModal}>
          One Time Edit
        </a>

        <InfoTooltip
          placement="right"
          tooltipText="This is a one time edit of the payee address. This edit does not affect the existing payee record on the ‘Payees’ tab."
        />
      </div>
    );
  }

  const canOneTimeEdit =
    ['additional_approval', 'pending', 'proposed'].includes(statusFilter) &&
    isEditing &&
    ['Check', 'External Check'].includes(paymentMethod);
  const isCheckOrWire = ['Check', 'External Check', 'Wire'].includes(paymentMethod);
  const isExternalCheck = paymentMethod === 'External Check';
  const isWire = paymentMethod === 'Wire';
  const showClientAccountNumber = ['Check', 'External Check'].includes(paymentMethod);
  const showBankAccountNumbers =
    ['EFT', 'Wire'].includes(paymentMethod) && (bankRoutingNumber || bankAccountNumber);
  const showSpecialInstructions = isCheckOrWire && specialInstructions;

  return (
    <div>
      {isCheckOrWire && hasAddress() && (
        <div className="new-form-field">
          <div className="new-form__label">Payee Address:</div>
          <div className="new-form__data">
            {address()}
            {canOneTimeEdit && renderOneTimePayeeUpdateModalLink()}
            {showOneTimePayeeUpdateModal && (
              <DashboardOneTimePayeeUpdateForm
                closeModal={toggleOneTimePayeeUpdateModal}
                disbursement={disbursement}
              />
            )}
          </div>
        </div>
      )}
      {isWire && swiftCode && (
        <div className="new-form-field">
          <div className="new-form__label">SWIFT Code:</div>
          <div className="new-form__data">{swiftCode}</div>
        </div>
      )}
      {showBankAccountNumbers && (
        <div className="new-form-field">
          <div className="new-form__label">Payee Bank Information:</div>
          <div className="new-form__data">
            <div style={{ display: 'inline-block', width: 86 }}>Routing #:</div>
            <div style={{ display: 'inline-block' }}>{bankRoutingNumber}</div>
            <br />
            <div style={{ display: 'inline-block', width: 86 }}>Account #:</div>
            <div style={{ display: 'inline-block' }}>{bankAccountNumber}</div>
          </div>
        </div>
      )}
      {showClientAccountNumber && (
        <div className="new-form-field">
          <div className="new-form__label">Memo:</div>
          <div className="new-form__data">
            <span>{clientAccountNumber}</span>
          </div>
        </div>
      )}
      {isExternalCheck && typeof disposition == 'string' && (
        <div className="new-form-field">
          <div className="new-form__label">Disposition:</div>
          <form className="new-form__data">
            <span>{disposition}</span>
          </form>
        </div>
      )}
      {showSpecialInstructions && (
        <div className="new-form-field">
          <div className="new-form__label">Special Instructions:</div>
          <div className="new-form__data">
            <span>{specialInstructions}</span>
          </div>
        </div>
      )}
    </div>
  );
}

DisbursementPayeeDetails.propTypes = {
  bankRoutingNumber: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  clientAccountNumber: PropTypes.string,
  disposition: PropTypes.string,
  specialInstructions: PropTypes.string,
  addressAttributes: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  swiftCode: PropTypes.string,
  isEditing: PropTypes.bool,
  paymentMethod: PropTypes.string,
  statusFilter: PropTypes.string,
  disbursement: PropTypes.object,
};

DisbursementPayeeDetails.defaultProps = {
  addressAttributes: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  },
};
