import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DocumentViewModal from './DocumentViewModal';
import PALETTE from 'react/shared/theme/palette';

export default function DocumentViewButton({ attachment }) {
  const [openDocumentModal, setOpenDocumentModal] = useState(false);

  const supportedContentTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/octet-stream',
    'application/pdf',
  ];

  const isSupported = supportedContentTypes.includes(attachment.contentType);
  const buttonColor = isSupported ? PALETTE.blue : PALETTE.grey3;

  return (
    <div>
      <Tooltip
        aria-label={isSupported ? undefined : 'View document (Unsupported format)'}
        componentsProps={{
          tooltip: {
            sx: { whiteSpace: 'nowrap', maxWidth: 'none' },
          },
        }}
        disableHoverListener={isSupported}
        disableInteractive
        title="View is not supported for this file type. Please download to view."
      >
        <span>
          <Button
            aria-label="view document"
            disabled={!isSupported}
            id="view-document-button"
            onClick={() => setOpenDocumentModal(true)}
            size="small"
            startIcon={<VisibilityIcon sx={{ fill: buttonColor }} />}
            sx={{
              '&.MuiButton-root': {
                textTransform: 'capitalize',
                color: buttonColor,
              },
            }}
          >
            View
          </Button>
        </span>
      </Tooltip>
      {openDocumentModal && (
        <DocumentViewModal
          name={attachment.name}
          onClose={() => setOpenDocumentModal(false)}
          url={attachment.url}
        />
      )}
    </div>
  );
}

DocumentViewButton.propTypes = {
  attachment: PropTypes.object.isRequired,
};
