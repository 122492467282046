import classNames from 'classnames';
import { ErrorMessage, Form, Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import * as Yup from 'yup';
import DashboardActions from 'react/member/actions/dashboard_actions';
import FormSelectSearchableField from 'react/shared/components/forms/form_select_searchable_field';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';
import TlFieldOptions from 'react/shared/utils/tl_field_options';
import TlFieldValidators from 'react/shared/utils/tl_field_validators';

export default class DashboardTrustBeneficiaryProfileContactInfoForm extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  initialValues() {
    const { trustBeneficiary } = this.props;
    return {
      mailStatement: !!trustBeneficiary.mailStatement,
      email: trustBeneficiary.email || '',
      person: {
        address: {
          street1: trustBeneficiary.person.address.street1 || '',
          street2: trustBeneficiary.person.address.street2 || '',
          city: trustBeneficiary.person.address.city || '',
          state: trustBeneficiary.person.address.state || '',
          zip: trustBeneficiary.person.address.zip || '',
        },
        mobile: trustBeneficiary.person.mobile || '',
        alternatePhone: trustBeneficiary.person.alternatePhone[0] || '',
      },
    };
  }

  validationSchema() {
    return Yup.object().shape({
      mailStatement: Yup.boolean().test({
        message: (_) => `A valid address is required to enable statement mailing`,
        test: (value, context) => {
          // If it's not set, automatically valid
          if (value === false) return true;

          // Check if any of the necessary components are empty
          const address = context.options.context.person.address;
          if (
            _isEmpty(address.street1) ||
            _isEmpty(address.city) ||
            _isEmpty(address.state) ||
            _isEmpty(address.zip)
          )
            return false;

          // Otherwise it's fine
          return true;
        },
      }),
      email: Yup.string().email('Invalid format'),
      person: Yup.object().shape({
        address: Yup.object().shape({
          street1: Yup.string()
            .max(30, 'must be less than 30 characters')
            .when('$mailStatement', ([mailStatement], schema) => {
              if (mailStatement === true) return schema.required('Address 1 is required');
            }),
          street2: Yup.string().max(30, 'must be less than 30 characters'),
          city: Yup.string()
            .max(40, 'must be less than 40 characters')
            .when('$mailStatement', ([mailStatement], schema) => {
              if (mailStatement === true) return schema.required('City is required');
            }),
          state: Yup.string().when('$mailStatement', ([mailStatement], schema) => {
            if (mailStatement === true) return schema.required('State is required');
          }),
          zip: Yup.lazy((value) =>
            !value
              ? Yup.string().when('$mailStatement', ([mailStatement], schema) => {
                  if (mailStatement === true) return schema.required('Zip is required');
                })
              : Yup.string().matches(TlFieldValidators.zipRegExp, 'Zip is not valid'),
          ),
        }),
        mobile: Yup.lazy((value) =>
          !value
            ? Yup.string()
            : Yup.string().matches(TlFieldValidators.phoneRegExp, 'Invalid format'),
        ),
        alternatePhone: Yup.lazy((value) =>
          !value
            ? Yup.string()
            : Yup.string().matches(TlFieldValidators.phoneRegExp, 'Invalid format'),
        ),
      }),
    });
  }

  onSubmit(values, { setSubmitting }) {
    const onRequestCompleted = () => {
      setSubmitting(false);
    };
    DashboardActions.updateTrustBeneficiaryData(
      this.props.trustBeneficiary.slug,
      values,
      onRequestCompleted,
    );
  }

  renderForm({ isSubmitting, isValid, values }) {
    return (
      <Form>
        <div className="form-content-edit tl-form-edit align-left">
          <div className="tl-fields-row">
            <div className="tl-field span12">
              <label className="tl-field-label" htmlFor="person.address.street1">
                Address 1
              </label>
              <div className="tl-field-data">
                <Field
                  id="contact-info-address-street1"
                  name="person.address.street1"
                  placeholder="Address 1"
                  type="text"
                />
              </div>
              <ErrorMessage name="person.address.street1">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className="tl-fields-row">
            <div className="tl-field span12">
              <label className="tl-field-label" htmlFor="person.address.street2">
                Address 2
              </label>
              <div className="tl-field-data">
                <Field name="person.address.street2" placeholder="Address 2" type="text" />
              </div>
              <ErrorMessage name="person.address.street2">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className="tl-fields-row">
            <div className="tl-field span6">
              <label className="tl-field-label" htmlFor="person.address.city">
                City
              </label>
              <div className="tl-field-data">
                <Field name="person.address.city" placeholder="City" type="text" />
              </div>
              <ErrorMessage name="person.address.city">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="tl-field span3">
              <label className="tl-field-label" htmlFor="person.address.state">
                State
              </label>
              <div className="tl-field-data">
                <Field
                  component={FormSelectSearchableField}
                  name="person.address.state"
                  options={TlFieldOptions.stateOptions()}
                  reactSelectProps={{ id: 'beneficiary-contact-info-state' }}
                />
              </div>
              <ErrorMessage name="person.address.state">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="tl-field span3" />
          </div>
          <div className="tl-fields-row">
            <div className="tl-field span3">
              <label className="tl-field-label" htmlFor="person.address.zip">
                Zip
              </label>
              <div className="tl-field-data">
                <Field name="person.address.zip" placeholder="Zip" type="text" />
              </div>
              <ErrorMessage name="person.address.zip">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="tl-field centered span3">
              <label className="tl-field-label" htmlFor="mailStatement">
                <span className="tl-checkbox-input">
                  <Field
                    checked={values.mailStatement}
                    component="input"
                    id="mailStatement"
                    name="mailStatement"
                    type="checkbox"
                  />
                </span>
                Mail Statement
              </label>
              <ErrorMessage name="mailStatement">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className="tl-fields-row">
            <div className="tl-field span6">
              <label className="tl-field-label" htmlFor="person.mobile">
                Primary Phone
              </label>
              <div className="tl-field-data">
                <Field
                  id="contact-info-mobile"
                  name="person.mobile"
                  placeholder="Phone"
                  type="text"
                />
              </div>
              <ErrorMessage name="person.mobile">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="tl-field span6">
              <label className="tl-field-label" htmlFor="person.alternatePhone">
                Secondary Phone
              </label>
              <div className="tl-field-data">
                <Field name="person.alternatePhone" placeholder="Phone" type="text" />
              </div>
              <ErrorMessage name="person.alternatePhone">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className="tl-fields-row">
            <div className="tl-field span12">
              <label className="tl-field-label" htmlFor="email">
                Email
              </label>
              <div className="tl-field-data">
                <Field id="contact-info-email" name="email" placeholder="Email" type="email" />
              </div>
              <ErrorMessage name="email">
                {(msg) => <div className="tl-field-error">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className="tl-form-actions-row">
            <TrueLinkButton
              className={classNames('btn btn-success', {
                disabled: !isValid || isSubmitting,
              })}
              disabled={!isValid || isSubmitting}
              type="submit"
              variant="none"
            >
              Update
            </TrueLinkButton>
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <div className="form-content-edit tl-form-edit" id="contact-info-form">
        <Formik
          component={this.renderForm}
          initialValues={this.initialValues()}
          isInitialValid
          onSubmit={this.onSubmit}
          validationSchema={this.validationSchema()}
        />
      </div>
    );
  }
}
DashboardTrustBeneficiaryProfileContactInfoForm.propTypes = {
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    mailStatement: PropTypes.bool,
    email: PropTypes.string,
    person: PropTypes.shape({
      address: PropTypes.shape({
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
      }).isRequired,
      mobile: PropTypes.string,
      alternatePhone: PropTypes.array,
    }).isRequired,
  }).isRequired,
};
