import PropTypes from 'prop-types';
import React from 'react';
import DateRange from './DateRange';
// Remove this file when the `bulk_trust_reporting` flipper flag is cleaned up
// because it fully duplicates the functionality of the `DateRange` component
export default function TimePeriod({
  format,
  rangeMinDate,
  rangeMaxDate,
  rangeStartDate,
  rangeEndDate,
}) {
  if (format === 'range') {
    return (
      <DateRange
        endDate={rangeEndDate}
        maxDate={rangeMaxDate}
        minDate={rangeMinDate}
        startDate={rangeStartDate}
      />
    );
  }

  return null;
}

TimePeriod.propTypes = {
  format: PropTypes.string,
  rangeMinDate: PropTypes.string,
  rangeMaxDate: PropTypes.string,
  rangeStartDate: PropTypes.string,
  rangeEndDate: PropTypes.string,
};
