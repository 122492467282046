import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkMaskedTextInput from 'react/shared/components/true_link/lab/form/TrueLinkMaskedTextInput';
import PALETTE from 'react/shared/theme/palette';
import ErrorText from 'react/signups/card/common/ErrorText';

export default function CardholderLoginFormFields({ onChange, values, setFieldTouched }) {
  return (
    <>
      <Box marginLeft="auto" marginRight="auto" padding="16px 24px" textAlign="center">
        <FormControl fullWidth>
          <FormLabel focused={false} htmlFor="card" sx={{ fontSize: '18px', color: PALETTE.grey1 }}>
            Last four digits of your card number
          </FormLabel>
          <Box alignItems="center" display="flex" justifyContent="center" marginTop="8px">
            <Typography fontWeight="700" marginRight="10px" variant="body1">
              0000 0000 0000
            </Typography>
            <Box borderRadius="4px" height="48px" width="64px">
              <TrueLinkMaskedTextInput
                errorOverride
                mask="9999"
                name="card"
                onChange={onChange}
                placeholder=""
                required
                setFieldTouched={setFieldTouched}
                value={values.card}
              />
            </Box>
          </Box>
          <ErrorText name="card" />
        </FormControl>

        <Divider sx={{ width: '100%', marginBottom: 2, paddingBottom: 2 }} />

        <FormControl fullWidth>
          <FormLabel focused={false} htmlFor="ssn" sx={{ fontSize: '18px', color: PALETTE.grey1 }}>
            Last four digits of your Social Security number
          </FormLabel>
          <Box alignItems="center" display="flex" justifyContent="center" marginTop="8px">
            <Typography marginRight="12px" variant="body1">
              000 - 00 -
            </Typography>
            <Box borderRadius="4px" height="48px" width="64px">
              <TrueLinkMaskedTextInput
                errorOverride
                mask="9999"
                name="ssn"
                onChange={onChange}
                placeholder=""
                required
                setFieldTouched={setFieldTouched}
                value={values.ssn}
              />
            </Box>
          </Box>
          <ErrorText name="ssn" />
        </FormControl>

        <Divider sx={{ width: '100%', marginBottom: 2, paddingBottom: 2 }} />

        <FormControl fullWidth>
          <FormLabel focused={false} htmlFor="dob" sx={{ fontSize: '18px', color: PALETTE.grey1 }}>
            Your date of birth
          </FormLabel>
          <Box alignItems="center" display="flex" justifyContent="center" marginTop="8px">
            <Box borderRadius="4px" textAlign="center" width="115px">
              <TrueLinkMaskedTextInput
                errorOverride
                mask="99/99/9999"
                name="dob"
                onChange={onChange}
                placeholder="mm/dd/yyyy"
                required
                setFieldTouched={setFieldTouched}
                value={values.dob}
              />
            </Box>
          </Box>
          <ErrorText name="dob" />
        </FormControl>

        <Divider sx={{ width: '100%', marginBottom: 1, paddingBottom: 2 }} />
      </Box>
    </>
  );
}

CardholderLoginFormFields.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object,
  setFieldTouched: PropTypes.func,
};
