import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import Container from '@mui/material/Container';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MfaVerifyWizard from 'react/member/components/mfa/mfaVerify/MfaVerifyWizard';
import CardText from 'react/shared/components/true_link/lab/form/wizard/CardText';
import WizardCard from 'react/shared/components/true_link/lab/form/wizard/WizardCard';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  content: {
    marginBottom: '72px',
    marginTop: '72px',
    maxWidth: '700px',
    padding: '0px',
    color: PALETTE.blueDark,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
  },
  mfaIcon: {
    backgroundColor: PALETTE.emeraldLight,
    color: PALETTE.evergreen,
    width: '100px',
    minWidth: '100px',
    height: '100px',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  helperText: {
    textAlign: 'center',
    marginTop: '24px',
    color: PALETTE.black,
  },
}));

export default function MfaVerify({ email, mobile, mfaDeliveryOption }) {
  const queryClient = new QueryClient();
  const classes = useStyles();

  const [step, setStep] = useState(1);

  const wrapperContainer = document.querySelector('#wrapper');
  if (wrapperContainer) {
    wrapperContainer.style.backgroundColor = PALETTE.grey6;
  }
  const footerContainer = document.getElementById('footer');
  if (footerContainer) {
    footerContainer.style.display = 'none';
  }

  const goBack = () => {
    if (step == 1) {
      window.location.href = RailsRoutes.sign_out_path();
    } else if (step == 2) {
      setStep(1);
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container className={classes.content}>
            <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
              <WizardCard className={classes.cardContainer} onBack={goBack} showBackButton>
                <div className={classes.mfaIcon} data-testid="mfa-icon">
                  <VerifiedUserOutlinedIcon sx={{ fontSize: 50 }} />
                </div>
                <MfaVerifyWizard
                  email={email}
                  mfaDeliveryOption={mfaDeliveryOption}
                  phoneNumber={mobile}
                  setStep={setStep}
                  step={step}
                />
              </WizardCard>
            </BrowserRouter>
            <CardText className={classes.helperText}>
              Can't get {mobile ? 'a text' : 'an email'}? Call us at:{' '}
              <a href={`tel:${I18n.t('corp.ad_tollfree_numeric')}`}>
                {` ${I18n.t('corp.ad_tollfree_numeric')}`}
              </a>
            </CardText>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

MfaVerify.propTypes = {
  email: PropTypes.string,
  mobile: PropTypes.string,
  mfaDeliveryOption: PropTypes.string,
};
