import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import ASSET_TYPES from 'react/member/utils/AssetTypes';
import DatePickerField from 'react/shared/components/forms/DatePickerField';
import GenericRadioInput from 'react/shared/components/forms/GenericRadioInput';
import bindAll from 'react/shared/utils/bind_all';

export default class UniqueAssetsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModalAsset: this.props.openModalAsset,
      formErrors: {
        name: null,
      },
    };

    bindAll(this);
  }

  handleAssetTypeChange(e) {
    //when changing asset type, we want to empty the open modal asset because different assets should have different parameters
    //but if we are on the edit view, we still want to edit the same modal asset.
    const asset_type = e.target.value;
    this.setState((prevState) => ({
      openModalAsset: {
        asset_type,
        id: prevState.openModalAsset.id,
      },
    }));
  }

  handleAssetRadioChange(e) {
    const booleanVal = e.target.value === '1';
    const { name } = e.target;
    this.setState((prevState) => ({
      openModalAsset: {
        ...prevState.openModalAsset,
        [name]: booleanVal,
      },
    }));
  }

  handleAssetTextChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      openModalAsset: {
        ...prevState.openModalAsset,
        [name]: value,
      },
    }));
  }

  handleAssetDateChange(date, datePicker) {
    if (date.length > 0) {
      this.setState((prevState) => ({
        openModalAsset: {
          ...prevState.openModalAsset,
          [datePicker.input[0].name]: date,
        },
      }));
    }
  }

  handlePaymentScheduleChange(e) {
    const { value } = e.target;
    this.setState((prevState) => ({
      openModalAsset: {
        ...prevState.openModalAsset,
        payment_schedule: value,
      },
    }));
  }

  ajaxCall(url, data, successMessage, callback, method) {
    Truelink.flash('info', 'Sending...', false);
    axios
      .request({
        url,
        method,
        data,
        dataType: 'json',
      })
      .then(() => {
        Truelink.flashClear();
        if (successMessage) {
          Truelink.flash('success', successMessage, false);
        }
        return callback && callback();
      });
  }

  requiredFields() {
    return ['name'];
  }

  getAssetTypes() {
    return this.alphabetizeObjectKeys(ASSET_TYPES);
  }

  alphabetizeObjectKeys(obj) {
    const sortedKeys = Object.keys(obj).sort();
    const sortedObj = {};
    sortedKeys.forEach((key) => {
      sortedObj[key] = obj[key];
    });
    return sortedObj;
  }

  validateInputs() {
    const formErrors = {};
    this.requiredFields().forEach((field) => {
      if (!this.state.openModalAsset[field]) {
        formErrors[field] = 'Required Field';
      } else {
        formErrors[field] = null;
      }
    });
    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors,
        ...formErrors,
      },
    }));

    const errorsArray = Object.keys(formErrors).map((e) => formErrors[e]);
    return !errorsArray.some((value) => !!value);
  }

  submitModalForm(e) {
    e.preventDefault();
    if (!this.validateInputs()) return;
    let path;
    const data = { unique_asset: this.state.openModalAsset };
    delete data.unique_asset['updated_at'];
    if (this.state.openModalAsset.id) {
      path = RailsRoutes.dashboard_client_unique_asset_path(
        this.props?.client?.id ?? this.props?.clientId,
        this.state.openModalAsset.id,
        { format: 'json' },
      );
      this.ajaxCall(path, data, 'Asset was successfully saved.', this.props.success, 'PUT');
    } else {
      path = RailsRoutes.dashboard_client_unique_assets_path(
        this.props?.client?.id ?? this.props?.clientId,
        {
          format: 'json',
        },
      );
      this.ajaxCall(path, data, 'Asset was successfully saved.', this.props.success, 'POST');
    }
  }

  renderFormTextField(label, name, value, required = false) {
    let labelClass;
    if (required) {
      labelClass = 'new-form__label new-form__label--required';
    } else {
      labelClass = 'new-form__label';
    }
    return (
      <div>
        <div className={labelClass}>{label}</div>
        <div className="new-form__data">
          <input
            name={name}
            onChange={this.handleAssetTextChange}
            style={{ width: 270 }}
            type="text"
            value={value || ''}
          />
          {this.state.formErrors[name] && (
            <p className="form-error">{this.state.formErrors[name]}</p>
          )}
        </div>
      </div>
    );
  }

  renderFormRadioButtons(label, yesId, name, noId, value, yesText = 'Yes', noText = 'No') {
    return (
      <div>
        <div className="new-form__label">{label}</div>
        <div className="new-form__data">
          <label htmlFor={yesId} style={{ float: 'left' }}>
            <GenericRadioInput
              checked={value || false}
              handleChange={this.handleAssetRadioChange}
              id={yesId}
              name={name}
              value="1"
            />
            &nbsp; {yesText}
          </label>
          <label htmlFor={noId} style={{ float: 'left', paddingLeft: 35 }}>
            <GenericRadioInput
              checked={!value}
              handleChange={this.handleAssetRadioChange}
              id={noId}
              name={name}
              value="0"
            />
            &nbsp; {noText}
          </label>
        </div>
      </div>
    );
  }

  renderNumberFieldWithDatePicker(
    label,
    name,
    defaultValue,
    datePickerId,
    datePickerName,
    datePickerValue,
    datePickerOpts = {},
  ) {
    const minDate = datePickerOpts.minDate || '-50y';
    const maxDate = datePickerOpts.maxDate || '+1y';
    return (
      <div>
        <div className="new-form__label">{label}</div>
        <div className="new-form__data">
          $ &nbsp;
          <input
            defaultValue={defaultValue}
            min="0"
            name={name}
            onChange={this.handleAssetTextChange}
            step="0.01"
            style={{ width: 115 }}
            type="number"
          />
          <DatePickerField
            dateFormat="yy-mm-dd"
            handleDateSelect={this.handleAssetDateChange}
            inputProps={{
              id: datePickerId,
              name: datePickerName,
              defaultValue: datePickerValue,
              style: { width: 105, marginLeft: 10 },
            }}
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      </div>
    );
  }

  renderFormNumberField(label, name, defaultValue) {
    return (
      <div>
        <div className="new-form__label">{label}</div>
        <div className="new-form__data">
          <input
            defaultValue={defaultValue}
            name={name}
            onChange={this.handleAssetTextChange}
            step="1"
            style={{ width: 115 }}
            type="number"
          />
        </div>
      </div>
    );
  }

  renderFormDropDown(label, name, value, onChange, options) {
    return (
      <div>
        <div className="new-form__label">{label}</div>
        <div className="new-form__data">
          <select name={name} onChange={onChange} style={{ width: 270 }} value={value || ''}>
            <option value="" />
            {this.renderFormDropDownOptions(options)}
          </select>
        </div>
      </div>
    );
  }

  renderFormDropDownOptions(options) {
    return options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  }

  renderFormDatePicker(label, id, name, defaultValue, datePickerOpts = {}) {
    const minDate = datePickerOpts.minDate || '-50y';
    const maxDate = datePickerOpts.maxDate || '+1y';
    return (
      <div>
        <div className="new-form__label">{label}</div>
        <div className="new-form__data">
          <DatePickerField
            dateFormat="yy-mm-dd"
            handleDateSelect={this.handleAssetDateChange}
            inputProps={{
              id,
              name,
              defaultValue,
              style: { width: 105, marginLeft: 10 },
            }}
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      </div>
    );
  }

  renderAssetForm() {
    switch (this.getAssetTypes()[this.state.openModalAsset.asset_type]) {
      case 'Vehicle Lien':
        return this.renderAutoForm();
      case 'Vehicle':
        return this.renderVehicleForm();
      case 'Business Interest':
        return this.renderBusinessInterestForm();
      case 'Outside Accounts':
        return this.renderOutsideAccountsForm();
      case 'Liability':
        return this.renderLiabilityForm();
      case 'Individual Held Security':
        return this.renderIndividualHeldSecurityForm();
      case 'Tangible Property':
        return this.renderTangiblePropertyForm();
      case 'Real Estate':
        return this.renderRealEstateForm();
      case 'Mineral Interest':
        return this.renderMineralInterestForm();
      case 'Annuities':
        return this.renderAnnuitiesForm();
      default:
        return null;
    }
  }

  renderOutsideAccountsForm() {
    return (
      <div id="unique-asset-outside-accounts-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
        )}
        {this.renderFormTextField('Where Held', 'where_held', this.state.openModalAsset.where_held)}
        {this.renderFormTextField(
          'Account #',
          'account_or_certificate_number',
          this.state.openModalAsset.account_or_certificate_number,
        )}
        {this.renderFormTextField('Manager', 'manager', this.state.openModalAsset.manager)}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderLiabilityForm() {
    return (
      <div id="unique-assets-liability-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
        )}
        {this.renderFormTextField('Payor', 'payor', this.state.openModalAsset.payor)}
        {this.renderFormDatePicker(
          'Last Payment Date',
          'last-payment-date',
          'last_payment_date',
          this.state.openModalAsset.last_payment_date,
        )}
        {this.renderFormRadioButtons(
          'Amortization Schedule',
          'amortization_schedule_option',
          'amortization_schedule',
          'no_amortization_schedule_option',
          this.state.openModalAsset.amortization_schedule,
        )}
        {this.renderFormRadioButtons(
          'Payments Required',
          'payments_required_option',
          'payments_required',
          'payments_not_required_option',
          this.state.openModalAsset.payments_required,
        )}
        {this.renderFormRadioButtons(
          'Payments Current',
          'payments_current_option',
          'payments_current',
          'no_payments_curent_option',
          this.state.openModalAsset.payments_current,
        )}
        {this.renderFormDropDown(
          'Payment Schedule',
          'payment_schedule',
          this.state.openModalAsset.payment_schedule,
          this.handlePaymentScheduleChange,
          ['Quarterly', 'Monthly', 'Annual', 'Other'],
        )}
        {this.renderFormRadioButtons(
          'Secured',
          'secured_option',
          'secured',
          'not_secured_option',
          this.state.openModalAsset.secured,
        )}
        {this.renderFormTextField(
          'Collateral Information',
          'collateral_information',
          this.state.openModalAsset.collateral_information,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderTangiblePropertyForm() {
    return (
      <div id="unique-asset-tangible-property-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
        )}
        {this.renderFormTextField('Where Held', 'where_held', this.state.openModalAsset.where_held)}
        {this.renderFormRadioButtons(
          'Appraisals Required',
          'appraisals_required_option',
          'appraisals_required',
          'not_appraisals_required_option',
          this.state.openModalAsset.appraisals_required,
        )}
        {this.renderFormDatePicker(
          'Date of Next Appraisal',
          'next-appraisal-date',
          'next_appraisal_date',
          this.state.openModalAsset.next_appraisal_date,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderIndividualHeldSecurityForm() {
    return (
      <div id="unique-assets-individual-held-security-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
          this.handleAssetOwnedByTrustChange,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
        )}
        {this.renderFormRadioButtons(
          'Marketable',
          'marketable_option',
          'marketable',
          'non_marketable_option',
          this.state.openModalAsset.marketable,
          'Marketable',
          'Non-marketable',
        )}
        {this.renderFormTextField('Where Held', 'where_held', this.state.openModalAsset.where_held)}
        {this.renderFormTextField(
          'Account #',
          'account_or_certificate_number',
          this.state.openModalAsset.account_or_certificate_number,
        )}
        {this.renderFormDatePicker(
          'Maturity Date',
          'maturity-date',
          'maturity_date',
          this.state.openModalAsset.maturity_date,
        )}
        {this.renderFormRadioButtons(
          'Valuation Required',
          'valuation_required_option',
          'valuation_required',
          'no_valuation_required',
          this.state.openModalAsset.valuation_required,
        )}
        {this.renderFormDatePicker(
          'Date of Next Valuation',
          'next-valuation-date',
          'next_valuation_date',
          this.state.openModalAsset.next_valuation_date,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderRealEstateForm() {
    return (
      <div id="unique-assets-real-estate-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
          this.handleAssetOwnedByTrustChange,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
        )}
        {this.renderFormDatePicker(
          'Date of Purchase',
          'purchase-date',
          'purchase_date',
          this.state.openModalAsset.purchase_date,
        )}
        {this.renderFormTextField(
          'Property Type',
          'property_type',
          this.state.openModalAsset.property_type,
        )}
        {this.renderFormTextField(
          'Homeowners Insurance Provider',
          'insurance_provider',
          this.state.openModalAsset.insurance_provider,
        )}
        {this.renderFormTextField(
          'Policy Number',
          'policy_number',
          this.state.openModalAsset.policy_number,
        )}
        {this.renderFormTextField('Street 1', 'street_1', this.state.openModalAsset.street_1)}
        {this.renderFormTextField('Street 2', 'street_2', this.state.openModalAsset.street_2)}
        {this.renderFormTextField('City', 'city', this.state.openModalAsset.city)}
        {this.renderFormTextField('State', 'state', this.state.openModalAsset.state)}
        {this.renderFormTextField('Zip Code', 'zip_code', this.state.openModalAsset.zip_code)}
        {this.renderFormRadioButtons(
          'Appraisals Required',
          'appraisals_required_option',
          'appraisals_required',
          'no_appraisals_required',
          this.state.openModalAsset.appraisals_required,
        )}
        {this.renderFormDatePicker(
          'Date of Next Appraisal',
          'next-appraisal-date',
          'next_appraisal_date',
          this.state.openModalAsset.next_appraisal_date,
        )}
        {this.renderFormRadioButtons(
          'Inspections Required',
          'inspections_required_option',
          'inspections_required',
          'no_inspections_required',
          this.state.openModalAsset.inspections_required,
        )}
        {this.renderFormDatePicker(
          'Date of Next Inspection',
          'next-inspection-date',
          'next_inspection_date',
          this.state.openModalAsset.next_inspection_date,
        )}
        {this.renderFormTextField(
          'Property Manager',
          'property_manager',
          this.state.openModalAsset.property_manager,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderAutoForm() {
    return (
      <div id="unique-assets-liability-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
          this.handleAssetOwnedByTrustChange,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
          { maxDate: '+1d' },
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
          { maxDate: '+1d' },
        )}
        {this.renderFormDatePicker(
          'Purchase Date',
          'purchase-date',
          'purchase_date',
          this.state.openModalAsset.purchase_date,
          { maxDate: '+1d' },
        )}
        {this.renderFormTextField('Make', 'make', this.state.openModalAsset.make)}
        {this.renderFormTextField('Model', 'model', this.state.openModalAsset.model)}
        {this.renderFormNumberField(
          'Model Year',
          'model_year',
          this.state.openModalAsset.model_year,
        )}
        {this.renderFormTextField('Vin', 'vin', this.state.openModalAsset.vin)}
        {this.renderFormTextField(
          'Auto Insurance Provider',
          'insurance_provider',
          this.state.openModalAsset.insurance_provider,
        )}
        {this.renderFormTextField(
          'Policy Number',
          'insurance_policy_number',
          this.state.openModalAsset.insurance_policy_number,
        )}
        {this.renderFormTextField('Lien #', 'lien_number', this.state.openModalAsset.lien_number)}
        {this.renderFormTextField(
          'Lien County',
          'lien_county',
          this.state.openModalAsset.lien_county,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderVehicleForm() {
    return (
      <div id="unique-assets-liability-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
          this.handleAssetOwnedByTrustChange,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
          { maxDate: '+1d' },
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
          { maxDate: '+1d' },
        )}
        {this.renderFormDatePicker(
          'Purchase Date',
          'purchase-date',
          'purchase_date',
          this.state.openModalAsset.purchase_date,
          { maxDate: '+1d' },
        )}
        {this.renderFormTextField('Make', 'make', this.state.openModalAsset.make)}
        {this.renderFormTextField('Model', 'model', this.state.openModalAsset.model)}
        {this.renderFormNumberField(
          'Model Year',
          'model_year',
          this.state.openModalAsset.model_year,
        )}
        {this.renderFormTextField('Vin', 'vin', this.state.openModalAsset.vin)}
        {this.renderFormTextField(
          'Insurance Provider',
          'insurance_provider',
          this.state.openModalAsset.insurance_provider,
        )}
        {this.renderFormTextField(
          'Policy Number',
          'insurance_policy_number',
          this.state.openModalAsset.insurance_policy_number,
        )}
        {this.renderFormTextField('Lien #', 'lien_number', this.state.openModalAsset.lien_number)}
        {this.renderFormTextField(
          'Lien Holder',
          'lien_holder',
          this.state.openModalAsset.lien_holder,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }
  renderAnnuitiesForm() {
    return (
      <div id="unique-asset-annuities-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Purchase value',
          'purchase_value',
          this.state.openModalAsset.purchase_value,
          'purchase-date',
          'purchase_date',
          this.state.openModalAsset.purchase_date,
          { maxDate: '+1d' },
        )}
        {this.renderFormRadioButtons(
          'Pre-Tax/After-tax',
          'pre_tax_option',
          'pre_tax',
          'after_tax_option',
          this.state.openModalAsset.pre_tax,
          'Pre-Tax',
          'After-Tax',
        )}
        {this.renderFormTextField('Issuer', 'issuer', this.state.openModalAsset.issuer)}
        {this.renderFormDatePicker(
          'Date of Next Contract Change',
          'next-contract-change-date',
          'next_contract_change_date',
          this.state.openModalAsset.next_contract_change_date,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderBusinessInterestForm() {
    return (
      <div id="unique-assets-liability-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
          this.handleAssetOwnedByTrustChange,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
          { maxDate: '+1d' },
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
          { maxDate: '+1d' },
        )}
        {this.renderFormRadioButtons(
          'Income Producing',
          'income_or_royalty_producing_option',
          'income_or_royalty_producing',
          'not_income_or_royalty_producing_option',
          this.state.openModalAsset.income_or_royalty_producing,
        )}
        {this.renderFormRadioButtons(
          'Valuation Required',
          'valuation_required_option',
          'valuation_required',
          'valuation_not_required_option',
          this.state.openModalAsset.valuation_required,
        )}
        {this.renderFormDatePicker(
          'Date of Next Valuation',
          'date-of-next-valuation',
          'next_valuation_date',
          this.state.openModalAsset.next_valuation_date,
        )}
        {this.renderFormTextField(
          'Manager Information',
          'manager',
          this.state.openModalAsset.manager,
        )}
        {this.renderFormDatePicker(
          'Date of Next Capital Call',
          'next-capital-call-date',
          'next_capital_call_date',
          this.state.openModalAsset.next_capital_call_date,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  renderMineralInterestForm() {
    return (
      <div id="unique-assets-mineral-interests-form">
        {this.renderFormTextField('Name', 'name', this.state.openModalAsset.name, true)}
        {this.renderFormRadioButtons(
          'Owned By Trust',
          'owned_by_trust_option',
          'owned_by_trust',
          'not_owned_by_trust_option',
          this.state.openModalAsset.owned_by_trust,
          this.handleAssetOwnedByTrustChange,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Current Market value',
          'current_market_value',
          this.state.openModalAsset.current_market_value,
          'market-value-date',
          'market_value_date',
          this.state.openModalAsset.market_value_date,
        )}
        {this.renderNumberFieldWithDatePicker(
          'Cost Basis',
          'cost_basis',
          this.state.openModalAsset.cost_basis,
          'cost-basis-date',
          'cost_basis_date',
          this.state.openModalAsset.cost_basis_date,
        )}
        {this.renderFormRadioButtons(
          'Income/Royalty Producing',
          'income-or-royalty-producing_option',
          'income_or_royalty_producing',
          'not-income-or-royalty-producing-required',
          this.state.openModalAsset.income_or_royalty_producing,
        )}
        {this.renderFormRadioButtons(
          'Valuation Required',
          'valuation_required_option',
          'valuation_required',
          'no_valuation_required',
          this.state.openModalAsset.valuation_required,
        )}
        {this.renderFormDatePicker(
          'Date of Next Valuation',
          'next-valuation-date',
          'next_valuation_date',
          this.state.openModalAsset.next_valuation_date,
        )}
        {this.renderFormTextField(
          'Manager Information',
          'manager_information',
          this.state.openModalAsset.manager_information,
        )}
        {this.renderFormTextField('Notes', 'notes', this.state.openModalAsset.notes)}
      </div>
    );
  }

  render() {
    return (
      <div id="unique-asset-form">
        <form id="issue_check_form" method="POST" onSubmit={this.submitModalForm}>
          {this.renderFormDropDown(
            'Type',
            'asset_type',
            this.state.openModalAsset.asset_type,
            this.handleAssetTypeChange,
            Object.keys(this.getAssetTypes()),
          )}
          {this.renderAssetForm()}
        </form>
      </div>
    );
  }
}

UniqueAssetsForm.propTypes = {
  client: PropTypes.object,
  clientId: PropTypes.string,
  openModalAsset: PropTypes.object,
  success: PropTypes.func,
};
