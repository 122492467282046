import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import _range from 'underscore/modules/range';
import TransferFundsInfoBox from 'react/member/card/components/funding_tab/transfers/TransferFundsInfoBox';
import TransferSchedule from 'react/member/card/components/funding_tab/transfers/TransferSchedule';
import { bankAccountShape } from 'react/member/card/shapes/BankAccountShape';
import DatePickerField from 'react/shared/components/forms/DatePickerField';
import TrueLinkSelect from 'react/shared/components/true_link/main/form/TrueLinkSelect';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';
import { ordinalize } from 'react/shared/utils/Numbers';
import ErrorText from 'react/signups/card/common/ErrorText';

const useStyles = makeStyles({
  transferFundsForm: {
    '& .datepicker-wrapper': {
      width: '100% !important',
      '&:after': {
        top: '12px',
        height: '20px',
        width: '20px',
      },
    },
    '& input[type="text"].datepicker': {
      width: '100%',
      height: '44px',
      '&:focus': {
        border: `2px solid ${PALETTE.emerald}`,
        boxShadow: 'none',
      },
    },
    '& input[type="number"]': {
      border: 'none',
      margin: '0',

      '&:focus': {
        boxShadow: 'none',
      },
    },
    '& input.datepicker': {
      '&:disabled': {
        backgroundColor: PALETTE.white,
        color: PALETTE.grey4,
      },
    },
    '& #add-memo-button': {
      color: PALETTE.blue,
      textTransform: 'capitalize',
      marginTop: '-10px',
      marginBottom: '20px',
    },
    '& .avenir-bold-p': {
      display: 'none',
    },
  },
  transferAgreement: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  transferAgreementText: {
    fontSize: '14px',
    marginBottom: '24px',
    marginTop: '24px',
  },
  autoTransferAgreement: {
    fontSize: '14px',
    marginBottom: '20px',
    marginTop: '50px',
  },
  title: {
    marginBottom: '5px',
  },
  formSection: {
    marginBottom: '15px',
    width: '100%',
  },
  formSectionMultiFields: {
    display: 'flex',
    gap: '15px',
  },
  error: {
    color: PALETTE.red,
    marginTop: '-15px',
    paddingBottom: '15px',
  },
});

export default function TransferFundsFormFields({
  availableOnDate,
  bomorrow,
  cardStatus,
  emergencyFundingEnabled,
  fundingType,
  isEdit,
  isEffectivelyFullyFunded,
  setFieldTouched,
  setFieldValue,
  editInstance,
  handleChange,
  onDateSelected,
  onFrequencyUpdated,
  showMemoField,
  setShowMemoField,
  transferAdjusted,
  transferAdjustedReason,
  values,
  verifiedBankAccounts,
  transferStartDay,
  transferDatePlus1BDay,
  transferDatePlus5BDay,
}) {
  const classes = useStyles();

  const frequencyOptions = [
    {
      id: 'one_time',
      label: 'One Time',
      value: 'one_time',
    },
    {
      id: 'every_week',
      label: 'Weekly',
      value: 'every_week',
    },
    {
      id: 'every_month',
      label: 'Monthly',
      value: 'every_month',
    },
    {
      id: 'twice_monthly',
      label: 'Twice every month',
      value: 'twice_monthly',
    },
    {
      id: 'when_funds_drop_below',
      label: 'When the balance goes below...',
      value: 'when_funds_drop_below',
    },
  ];

  const editRecurringTransferOptions = [
    {
      id: 'every_week',
      label: 'Weekly',
      value: 'every_week',
    },
    {
      id: 'every_month',
      label: 'Monthly',
      value: 'every_month',
    },
    {
      id: 'twice_monthly',
      label: 'Twice every month',
      value: 'twice_monthly',
    },
  ];

  const isRecurringFrequency = (values) =>
    values === 'every_week' || values === 'every_month' || values === 'twice_monthly';

  const isRecurringFrequencyEdit =
    isEdit &&
    (values.transfer_frequency === 'every_week' ||
      values.transfer_frequency === 'every_month' ||
      values.transfer_frequency === 'twice_monthly');

  const bankAccountOptions = verifiedBankAccounts.map((bankAccount) => ({
    id: bankAccount.id,
    label: `${bankAccount.nickname}${' '}${bankAccount.accountNumber}`,
    value: bankAccount.id,
  }));

  const dayOfWeekOptions = _range(5).map((n, i) => ({
    id: i,
    label: moment.weekdays(n + 1),
    value: i + 1,
  }));

  const dayOfMonthOptions = _range(1, 32).map((day, i) => ({
    id: i,
    label: ordinalize(day),
    value: i + 1,
  }));

  const handleFrequencyChange = (e) => {
    handleChange(e);
    const value = e.target.value;
    if (isRecurringFrequency(value)) {
      onFrequencyUpdated({ ...values, transfer_frequency: value });
    }
  };

  const bankAccountField = (
    <div className={classes.formSection}>
      <div className={classes.title}>Bank Account</div>
      <TrueLinkSelect
        disabled={isEdit}
        id="bank-account"
        label={'Select a bank account'}
        name="bank_account_id"
        onChange={handleChange}
        options={bankAccountOptions}
        placeholder={'Select a bank account'}
        required
        setFieldTouched={setFieldTouched}
        value={values.bank_account_id}
      />
    </div>
  );

  const transferAmountField = (
    <div className={classes.formSection}>
      <div className={classes.title}>Amount To Transfer</div>
      <TrueLinkTextInput
        id="transfer-amount"
        inputProps={{
          maxLength: 5,
        }}
        name="transfer_amount"
        onChange={handleChange}
        placeholder="0.00"
        setFieldTouched={setFieldTouched}
        startAdornment={<span style={{ padding: '0 10px' }}>$</span>}
        type="number"
        value={values.transfer_amount}
      />
    </div>
  );

  const transferMemoField = (
    <div className={classes.formSection} style={{ marginBottom: 40 }}>
      <div className={classes.title}>Memo</div>
      <TrueLinkTextInput
        id="transfer-memo"
        name="transfer_memo"
        onChange={handleChange}
        placeholder="Optional"
        setFieldTouched={setFieldTouched}
        value={values.transfer_memo}
      />
    </div>
  );

  const frequencyField = (
    <div className={classes.formSection}>
      <div className={classes.title}>Frequency</div>
      <TrueLinkSelect
        id="transfer-frequency"
        label={'Select transfer frequency'}
        name="transfer_frequency"
        onChange={handleFrequencyChange}
        options={isRecurringFrequencyEdit ? editRecurringTransferOptions : frequencyOptions}
        placeholder={''}
        required
        setFieldTouched={setFieldTouched}
        value={values.transfer_frequency}
      />
    </div>
  );

  const dayOfWeekField = (
    <div className={classes.formSection}>
      <div className={classes.title}>Starting on</div>
      <TrueLinkSelect
        id="transfer-day-of-week"
        label={'Select day'}
        name="transfer_day_of_week"
        onChange={(e) => {
          handleChange(e);
          onFrequencyUpdated({ ...values, transfer_day_of_week: e.target.value });
        }}
        options={dayOfWeekOptions}
        placeholder={''}
        required
        setFieldTouched={setFieldTouched}
        value={values.transfer_day_of_week}
      />
    </div>
  );

  const dayOfMonthField = (
    <div className={classes.formSection}>
      <div className={classes.title}>On the</div>
      <TrueLinkSelect
        id="transfer-day-of-month"
        label={'Select day'}
        name="transfer_day_of_month"
        onChange={(e) => {
          handleChange(e);
          onFrequencyUpdated({ ...values, transfer_day_of_month: e.target.value });
        }}
        options={dayOfMonthOptions}
        placeholder={''}
        required
        setFieldTouched={setFieldTouched}
        value={values.transfer_day_of_month}
      />
    </div>
  );

  const daysOfMonthField = (
    <div className={classes.formSectionMultiFields}>
      <div className={classes.formSection}>
        <div className={classes.title}>On the</div>
        <TrueLinkSelect
          id="transfer-day-of-month"
          label={'Select day'}
          name="transfer_day_of_month"
          onChange={(e) => {
            handleChange(e);
            onFrequencyUpdated({ ...values, transfer_day_of_month: e.target.value });
          }}
          options={dayOfMonthOptions}
          placeholder={''}
          required
          setFieldTouched={setFieldTouched}
          value={values.transfer_day_of_month}
        />
      </div>
      <div className={classes.formSection}>
        <div className={classes.title}>And the</div>
        <TrueLinkSelect
          id="transfer-day-of-month_2"
          label={'Select day'}
          name="transfer_day_of_month_2"
          onChange={(e) => {
            handleChange(e);
            onFrequencyUpdated({ ...values, transfer_day_of_month_2: e.target.value });
          }}
          options={dayOfMonthOptions}
          placeholder={''}
          required
          setFieldTouched={setFieldTouched}
          value={values.transfer_day_of_month_2}
        />
      </div>
    </div>
  );

  const transferThresholdAmountField = (
    <div className={classes.formSection}>
      <div className={classes.title}>Balance threshold</div>
      <TrueLinkTextInput
        id="transfer-threshold-amount"
        inputProps={{
          maxLength: 5,
        }}
        name="transfer_threshold_amount"
        onChange={handleChange}
        placeholder="0.00"
        setFieldTouched={setFieldTouched}
        startAdornment={<span style={{ padding: '0 10px' }}>$</span>}
        type="number"
        value={values.transfer_threshold_amount}
      />
    </div>
  );

  const transferMemoButton = (
    <div>
      {!showMemoField && (
        <Button id="add-memo-button" onClick={() => setShowMemoField(true)} variant="text">
          + Add Memo
        </Button>
      )}
      {showMemoField && transferMemoField}
    </div>
  );

  const autoTransferMemoPostfix = useMemo(() => {
    let result;
    switch (values.transfer_frequency) {
      case 'every_week': {
        result = `Every ${moment.weekdays(Number(values.transfer_day_of_week))}`;
        break;
      }
      case 'every_month': {
        result = `Monthly on the ${ordinalize(values.transfer_day_of_month)}`;
        break;
      }
      case 'twice_monthly': {
        result = `${ordinalize(values.transfer_day_of_month)} and ${ordinalize(values.transfer_day_of_month_2)} of the month`;
        break;
      }
      case 'when_funds_drop_below': {
        result = `Every time the card balance drops below ${asMoney(values.transfer_threshold_amount)}`;
        break;
      }
    }

    return `(${result})`;
  }, [values]);

  const autoTransferMemoField = (
    <div className={classes.formSection} style={{ marginBottom: 40 }}>
      <div className={classes.title}>Memo</div>
      <TrueLinkTextInput
        id="auto-transfer-memo"
        name="auto_transfer_memo"
        onChange={handleChange}
        postfix={autoTransferMemoPostfix}
        setFieldTouched={setFieldTouched}
        value={values.auto_transfer_memo}
      />
    </div>
  );

  const agreementText = (
    <div className={classes.transferAgreementText}>
      By clicking “Submit” you authorize True Link to complete the transfer described above, and you
      certify that you are authorized to initiate transfers from this account.
    </div>
  );

  const autoTransferAgreement = (
    <div className={classes.transferAgreementText}>
      <div>
        By clicking “Submit” you authorize True Link to complete the transfer described above, and
        you certify that you are authorized to initiate transfers from this account.
      </div>
      <br />
      <div>
        You understand this authorization will remain in effect until you have removed this
        authorization in such time and in such manner as to allow True Link a reasonable opportunity
        to act on it. You also understand that if corrections on the posted amount are necessary, it
        may involve an adjustment (credit or debit) to your account(s).
      </div>
    </div>
  );

  const handleDateSelect = (date) => {
    setFieldValue('transfer_date', date);
    window.setTimeout(() => {
      setFieldTouched('transfer_date', true);
    }, 100);
    onDateSelected(date);
  };

  const validateTransferAmount = () => {
    setFieldTouched('transfer_amount', true);
  };

  const transferDateField = (
    <div className={classes.formSection}>
      <div className={classes.title}>Transfer Date</div>
      <DatePickerField
        dateFormat="yy-mm-dd"
        excludeWeekendsAndHolidays
        handleDateSelect={handleDateSelect}
        inputProps={{
          id: 'transfer_date',
          name: 'transfer_date',
          onChange: handleChange,
          placeholder: 'YYYY-MM-DD',
          value: values.transfer_date,
          disabled: editInstance,
        }}
        maxDate="+1Y"
        minDate={moment().toDate()}
      />
      <ErrorText name="transfer_date" />
      {isEffectivelyFullyFunded && availableOnDate && (
        <div>
          Funds available on <b>{availableOnDate}</b>
        </div>
      )}
    </div>
  );

  const transferFundsInfoBox = (
    <>
      <TransferFundsInfoBox
        availableOnDate={availableOnDate}
        bomorrow={bomorrow}
        cardStatus={cardStatus}
        dayOfMonth={values.transfer_day_of_month}
        dayOfMonth2={values.transfer_day_of_month_2}
        dayOfWeek={values.transfer_day_of_week}
        emergencyFundingEnabled={emergencyFundingEnabled}
        fundingType={fundingType}
        oneTimeTransferDate={values.transfer_date}
        transferAdjusted={transferAdjusted}
        transferAdjustedReason={transferAdjustedReason}
        transferAmount={values.transfer_amount}
        transferDatePlus1BDay={transferDatePlus1BDay}
        transferDatePlus5BDay={transferDatePlus5BDay}
        transferStartDay={transferStartDay}
        transferThreshold={values.transfer_threshold_amount || '0.00'}
        transferType={values.transfer_frequency}
      />
    </>
  );

  const oneTimeTransfer = (
    <>
      {transferDateField}
      {!editInstance && transferMemoButton}
      {transferFundsInfoBox}
      {agreementText}
    </>
  );

  const recurringTransfer = (
    <>
      {values.transfer_frequency === 'every_week' && dayOfWeekField}
      {values.transfer_frequency === 'every_month' && dayOfMonthField}
      {values.transfer_frequency === 'twice_monthly' && daysOfMonthField}
      {values.transfer_frequency === 'when_funds_drop_below' && transferThresholdAmountField}
      {autoTransferMemoField}
      {transferFundsInfoBox}
      {values.transfer_frequency !== 'when_funds_drop_below' && (
        <TransferSchedule onShowTransfer={validateTransferAmount} transfer={values} />
      )}
      {autoTransferAgreement}
    </>
  );

  return (
    <div className={classes.transferFundsForm}>
      {bankAccountField}
      {transferAmountField}
      {!isEdit && frequencyField}
      {isRecurringFrequencyEdit && frequencyField}
      {values.transfer_frequency === 'one_time' && oneTimeTransfer}
      {values.transfer_frequency !== 'one_time' && recurringTransfer}
    </div>
  );
}

TransferFundsFormFields.propTypes = {
  verifiedBankAccounts: PropTypes.arrayOf(bankAccountShape),
  handleChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  onDateSelected: PropTypes.func,
  onFrequencyUpdated: PropTypes.func,
  values: PropTypes.object,
  setShowMemoField: PropTypes.func,
  showMemoField: PropTypes.bool,
  isEdit: PropTypes.bool.isRequired,
  editInstance: PropTypes.bool,
  isEffectivelyFullyFunded: PropTypes.bool,
  availableOnDate: PropTypes.string,
  cardStatus: PropTypes.string,
  emergencyFundingEnabled: PropTypes.bool,
  fundingType: PropTypes.string,
  transferAdjusted: PropTypes.bool,
  transferAdjustedReason: PropTypes.string,
  transferDatePlus1BDay: PropTypes.string,
  transferDatePlus5BDay: PropTypes.string,
  transferStartDay: PropTypes.string,
  bomorrow: PropTypes.string,
};
