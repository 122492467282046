const DashboardActions = flux.createActions([
  // Payees
  'destroyPayee',
  'addBeneficiaryPayee',
  'updateBeneficiaryPayee',
  'fetchPayees',

  // Cards
  'fetchUnlinkedCardsByOrganization',
  'linkTrustBeneficiaryToCard',
  'refreshCurrentCardStatus',
  'reloadOnboardingFlow',

  // Card Transactions
  'fetchTransactionAttachmentsByTransaction',
  'destroyTransactionAttachment',

  // Card Transaction Notes
  'fetchCardTransactionNotes',
  'createCardTransactionNote',
  'updateCardTransactionNote',
  'destroyCardTransactionNote',

  // Clients
  'fetchClientBySlug',
  'fetchClientSelectIdsByOrganization',
  'fetchClientSelectIdsByPortfolio',
  'fetchClientSelectIds',
  'fetchClientsByOrganization',
  'fetchClientsByPortfolio',
  'fetchClients',
  'linkCardToClient',
  'createTrustBeneficiary',

  // Disbursements
  'destroyDisbursement',
  'fetchDisbursements',
  'fetchExternalCheckDisbursementsByOrganization',
  'fetchExternalCheckDisbursements',
  'fetchDraftDisbursementsByOrganization',
  'fetchDraftDisbursements',
  'fetchDisbursementsByPortfolio',
  'checkOverdraw',

  // Draft Disbursements
  'updateDraftDisbursement',
  'deleteDraftDisbursement',
  'fetchDraftDisbursementById',

  // Government Benefits
  'fetchBeneGovernmentBenefits',
  'updateBeneGovernmentBenefits',

  // Recurring Disbursement
  'fetchRecurringDisbursements',
  'requestRecurringDisbursementThreeMonthInfo',
  'cancelRecurringDisbursement',

  'fetchDashboardNotes',
  'fetchPrimaryNote',
  'addClientNote',
  'destroyClientNote',
  'updateDashboardNote',
  'fetchDailyPortfolioCloseouts',
  'fetchLatestCloseout',
  'generateCloseoutReport',
  'generateCloseoutReportForTrust',
  'fetchPositions',
  'fetchPositionsByTrust',
  'fetchPositionsByOrganization',
  'fetchPositionsByClient',
  'fetchPositionsByPortfolio',
  'fetchWealthAccountsByPortfolio',
  'fetchWealthAccountEvents',
  'fetchWealthAccountEventsByTrust',
  'fetchWealthAccountEventsByPortfolio',
  'fetchWealthAccountEventsByOrganization',
  'updateWealthAccountEvent',
  'fetchRecentTransfers',
  'fetchScheduledTransfers',
  'reloadHolidayBanner',
  'createTransfer',
  'updateTransfer',
  'deleteTransfer',
  'fetchScheduledAutoTransfers',
  'createAutoTransfer',
  'updateAutoTransfer',
  'deleteAutoTransfer',
  'fetchOrganization',
  'fetchCategories',
  'getOrFetchCategories',
  'fetchBudgetItemAttachmentsByBudgetItem',
  'fetchAttachmentsByDisbursementCategoryAmount',
  'destroyBudgetItemAttachment',
  'destroyBudgetItemAttachmentForBudgetItem',
  'fetchWealthAccountEventAttachments',
  'destroyWealthAccountEventAttachment',
  'createBudgetItem',
  'approveDisbursement',
  'approveRecurringDisbursement',
  'openDisbursementDenialModal',
  'openRecurringDisbursementDenialModal',
  'denyOneTimeDisbursement',
  'updateDisbursement',
  'returnDisbursementForRevision',
  'denyRecurringDisbursement',
  'updateRecurringDisbursement',
  'fetchHoldings',
  'fetchUniqueAssets',
  'fetchAuthorizedUsers',
  'fetchConnectedBankAccounts',
  'connectBankAccount',
  'removeBankAccount',
  'verifyBankAccount',
  'copyBankAccount',
  'connectFeeNewBankAccount',
  'createPlaidBankAccount',

  // Ruleset and Account Info
  'fetchAccountInfo',
  'fetchRulesetWarnRules',
  'fetchOrganizationInfo',
  'addAdditionalEmail',
  'removeAdditionalEmail',
  'updateNotificationPreference',
  'updateMobileForNotification',

  // Dashboard Deposits
  'fetchClientDeposits',
  'fetchPendingClientDeposits',
  'fetchPortfolioDeposits',
  'fetchPendingPortfolioDeposits',
  'fetchOrganizationDeposits',
  'fetchPendingOrganizationDeposits',
  'fetchDeposits',
  'fetchPendingDeposits',
  'fetchRecurringClientDeposits',
  'fetchApprovedRecurringClientDeposits',
  'fetchRecurringPortfolioDeposits',
  'fetchApprovedRecurringPortfolioDeposits',
  'fetchRecurringOrganizationDeposits',
  'fetchApprovedRecurringOrganizationDeposits',
  'fetchRecurringDeposits',
  'fetchApprovedRecurringDeposits',
  'openDepositCancelModal',
  'cancelOneTimeDeposit',
  'createMultipleDeposits',

  // Dashboard Recurring Deposits
  'cancelRecurringDeposit',

  // Trust Beneficiaries
  'fetchTrustBeneficiaryData',
  'updateTrustBeneficiaryData',

  // Account Receipts
  'updateAccountReceipt',
  'fetchAccountReceiptsAndFolders',
  'dropzoneAccountReceiptUploadSuccess',

  // Client Documents
  'destroyClientDocument',
  'updateClientDocument',
  'fetchClientDocumentsAndFolders',
  'dropzoneClientDocumentUploadSuccess',

  // Client Folders
  'createClientFolder',
  'updateClientFolder',
  'destroyClientFolder',

  // Trust Remainder Beneficiaries
  'fetchTrustRemainderBeneficiaries',
  'createTrustRemainderBeneficiary',
  'updateTrustRemainderBeneficiary',
  'destroyTrustRemainderBeneficiary',

  // V2 Attachments
  'V2destroyAttachment',
]);

export default DashboardActions;
