import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import WmDashboardTitleBar from './WmDashboardTitleBar';
import DashboardClients from 'react/member/components/dashboard/clients/DashboardClients';
import DashboardClient from 'react/member/components/dashboard/clients/dashboard_client/DashboardClient';
import DashboardOrganization from 'react/member/components/dashboard/organizations/DashboardOrganization';
import DashboardOrganizationPendingTab from 'react/member/components/dashboard/organizations/DashboardOrganizationPendingTab';
import DashboardPortfolio from 'react/member/components/dashboard/portfolios/DashboardPortfolio';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import ErrorBoundary from 'react/shared/components/ErrorBoundary';
import theme from 'react/shared/theme/Theme';

export default function WmDashboard({ headerProps, renderComponent, contentProps, userRoleProps }) {
  const wmComponents = {
    clientsIndex: DashboardClients,
    organizationPending: DashboardOrganizationPendingTab,
    portfolioShow: DashboardPortfolio,
    clientShow: DashboardClient,
    organizationShow: DashboardOrganization,
  };
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        //globally default to 5 seconds since default is 0
        staleTime: 1000 * 5,
      },
    },
  });
  const DashboardComponent = wmComponents[renderComponent];

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter
              basename="/"
              future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
            >
              <ErrorBoundary>
                <WmDashboardTitleBar
                  autocompleteSelectEnabled={headerProps.autocompleteSelectEnabled}
                  balanceInfo={headerProps.balanceInfo}
                  client={headerProps.client}
                  organization={headerProps.organization}
                  preloadedTrustBenes={headerProps.preloadedTrustBenes}
                  showIndividualTrustBeneficiaryOverview={
                    contentProps?.showIndividualTrustBeneficiaryOverview
                  }
                  title={headerProps.title}
                  trustBeneficiaries={headerProps.trustBeneficiaries}
                />
              </ErrorBoundary>
              <ErrorBoundary>
                <DashboardComponent {...contentProps} userRoles={userRoleProps} />
              </ErrorBoundary>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </div>
  );
}

WmDashboard.propTypes = {
  renderComponent: PropTypes.string,
  headerProps: PropTypes.shape({
    title: PropTypes.string,
    client: PropTypes.object,
    organization: PropTypes.object,
    preloadedTrustBenes: PropTypes.bool,
    trustBeneficiaries: PropTypes.array,
    balanceInfo: PropTypes.object,
    autocompleteSelectEnabled: PropTypes.bool,
  }).isRequired,
  contentProps: PropTypes.object,
  userRoleProps: userRoleShape,
};
