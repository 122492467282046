import AttachFile from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import PALETTE from 'react/shared/theme/palette';

export default function AttachmentCell({ value, cell, column }) {
  const cellProps = column.getCellProps();

  if (value) {
    return (
      <>
        <AttachFile style={{ fill: PALETTE.black, fontSize: '18px', marginBottom: '-3px' }} />
        Uploaded
      </>
    );
  }

  return (
    <TrueLinkLink
      onClick={(e) => {
        e.preventDefault();
        cellProps.onClick(cell);
      }}
    >
      +Add
    </TrueLinkLink>
  );
}

AttachmentCell.propTypes = {
  cell: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  value: PropTypes.bool,
};
