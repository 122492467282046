/* eslint-disable no-invalid-this */
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';
import * as Yup from 'yup';
import ConnectionModalLabel from './ConnectionModalLabel';
import DashboardTrustBeneficiaryProfileConnectionRelationship from './DashboardTrustBeneficiaryProfileConnectionRelationship';
import connectionShape from 'react/member/shapes/ConnectionShape';
import Actions from 'react/shared/actions/actions';
import TrueLinkBanner from 'react/shared/components/true_link/lab/TrueLinkBanner';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileConnectionForm extends React.Component {
  constructor(props) {
    super(props);

    this.isEdit = !!this.props.connection.id;
    this.statementMailingEnabled = this.props.statementMailingEnabled;

    bindAll(this);
  }

  initialValues() {
    const { connection } = this.props;
    return {
      connection: {
        email: connection.email || '',
        id: connection.id || '',
        isCommon: connection.isCommon || false,
        notes: connection.notes || '',
        companyName: connection.companyName || '',
        person: {
          firstName: connection.person.firstName || '',
          lastName: connection.person.lastName || '',
          mobile: connection.person.mobile || '',
        },
        address: {
          street1: connection.address.street1 || '',
          street2: connection.address.street2 || '',
          city: connection.address.city || '',
          state: connection.address.state || '',
          zip: connection.address.zip || '',
        },
        connectionRelationship: {
          social: connection.connectionRelationship.social || '',
          professional: connection.connectionRelationship.professional || '',
          isStatementRecipient: connection.connectionRelationship.isStatementRecipient || false,
        },
      },
    };
  }

  oldValidationSchema() {
    return Yup.object().shape({
      connection: Yup.object().shape({
        companyName: Yup.string()
          .nullable()
          .when('person', {
            is: (person) => !person.firstName && !person.lastName,
            then: () => Yup.string().required('First/Last Name or Company Name are required'),
          }),
        email: Yup.string().nullable(),
        isCommon: Yup.boolean(),
        notes: Yup.string().nullable(),
        address: Yup.object().shape({
          street1: Yup.string().nullable(),
          street2: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().nullable(),
          zip: Yup.string().nullable(),
        }),
        connectionRelationship: Yup.object().shape({
          social: Yup.string().nullable(),
          professional: Yup.string().nullable(),
        }),
        person: Yup.object().shape({
          firstName: Yup.string().nullable(),
          lastName: Yup.string().nullable(),
          mobile: Yup.string().nullable(),
        }),
      }),
    });
  }

  validationSchema() {
    // This is a custom validation that will make a field required if the connection.connectionRelationship is a statement/tax doc recipient
    // This function is necessary because the Yup library does not support conditional validation based on
    // fields outside of the current object that is being validated. This is used to make address fields and person.firstName required
    const isTaxDocRecipient = this.props.isTaxDocRecipient;
    const requiredForDocumentRecipients = function (value) {
      const { from } = this;
      // Access the "from" connection's connectionRelationship
      const connectionRelationship = from[1]?.value?.connectionRelationship;

      if (connectionRelationship?.isStatementRecipient || isTaxDocRecipient) {
        return !!value;
      }

      return true;
    };

    return Yup.object().shape({
      connection: Yup.object().shape({
        companyName: Yup.string()
          .nullable()
          .when(['person', 'connectionRelationship'], {
            is: (person, connectionRelationship) =>
              !connectionRelationship.isStatementRecipient && !person.firstName && !person.lastName,
            then: () => Yup.string().required('First/Last Name or Company Name are required'),
          }),
        email: Yup.string().nullable(),
        isCommon: Yup.boolean(),
        notes: Yup.string().nullable(),
        address: Yup.object().shape({
          street1: Yup.string()
            .nullable()
            .max(30, 'Street 1 must be 30 characters or less')
            .test(
              'isStatementRecipientCheck',
              'Street 1 is required for document recipients',
              requiredForDocumentRecipients,
            ),
          street2: Yup.string().nullable().max(30, 'Street 2 must be 30 characters or less'),
          city: Yup.string()
            .nullable()
            .max(40, 'City must be 40 characters or less')
            .test(
              'isStatementRecipientCheck',
              'City is required for document recipients',
              requiredForDocumentRecipients,
            ),
          state: Yup.string()
            .nullable()
            .length(2, 'State must be 2 characters')
            .test(
              'isStatementRecipientCheck',
              'State is required for document recipients',
              requiredForDocumentRecipients,
            ),
          zip: Yup.string()
            .nullable()
            .length(5, 'Zip must be 5 numbers')
            .matches(/^\d+$/, 'Zip must be 5 numbers')
            .test(
              'isStatementRecipientCheck',
              'Zip is required for document recipients',
              requiredForDocumentRecipients,
            ),
        }),
        connectionRelationship: Yup.object().shape({
          social: Yup.string().nullable(),
          professional: Yup.string().nullable(),
          isStatementRecipient: Yup.boolean(),
        }),
        person: Yup.object().shape({
          firstName: Yup.string()
            .nullable()
            .test(
              'isStatementRecipientCheck',
              'First Name is required for document recipients',
              requiredForDocumentRecipients,
            ),
          lastName: Yup.string().nullable(),
          mobile: Yup.string()
            .nullable()
            .matches(/^[\d\s().-]{10,20}$/, 'Phone must be a valid number with 10 digits')
            .test(
              'digitCount',
              'Phone must contain exactly 10 digits',
              (value) => !value || (value.match(/\d/g) || []).length === 10,
            ),
        }),
      }),
    });
  }

  onSubmit(values, { setSubmitting }) {
    const { trustBeneficiarySlug } = this.props;

    const onRequestCompleted = () => {
      setSubmitting(false);
    };
    const action = this.isEdit ? Actions.updateConnection : Actions.createConnection;
    action(trustBeneficiarySlug, values.connection, onRequestCompleted);
  }

  renderError(fieldName) {
    return (
      <ErrorMessage name={fieldName}>
        {(msg) => <div className="form-error">{msg}</div>}
      </ErrorMessage>
    );
  }

  renderStringField(label, name, id, required = false) {
    const labelText = required ? `${label}*` : label;
    return (
      <div>
        <ConnectionModalLabel inputId={id} labelContent={labelText}>
          <Field id={id} name={name} type="text" />
        </ConnectionModalLabel>
        {this.renderError(name)}
      </div>
    );
  }

  renderCompanyNameField() {
    return this.renderStringField(
      'Company Name',
      'connection.companyName',
      'connection-company-name',
    );
  }

  render() {
    return (
      <TrueLinkModal
        handleClose={this.props.onClose}
        title={this.isEdit ? 'Edit Connection' : 'New Connection'}
      >
        <Formik
          initialValues={this.initialValues()}
          onSubmit={this.onSubmit}
          validationSchema={this.validationSchema()}
        >
          {({ values, isSubmitting, isValid, setFieldValue, setFieldTouched }) => {
            const changeFieldValue = function (name, val) {
              setFieldValue(name, val);
            };
            const isStatementRecipient =
              values.connection.connectionRelationship.isStatementRecipient;
            const firstNameLabel = isStatementRecipient ? 'First Name*' : 'First Name';
            return (
              <Form>
                <div>
                  <ConnectionModalLabel
                    inputId="connection-first-name"
                    labelContent={firstNameLabel}
                  >
                    <Field
                      id="connection-first-name"
                      name="connection.person.firstName"
                      onBlur={() => {
                        setFieldTouched('connection.companyName', true);
                        setFieldTouched('connection.person.firstName', true);
                      }}
                      type="text"
                    />
                  </ConnectionModalLabel>
                  {this.renderError('connection.person.firstName')}
                </div>
                <div>
                  <ConnectionModalLabel inputId="connection-last-name" labelContent="Last Name">
                    <Field
                      id="connection-last-name"
                      name="connection.person.lastName"
                      onBlur={() => {
                        setFieldTouched('connection.companyName', true);
                      }}
                      type="text"
                    />
                  </ConnectionModalLabel>
                  {this.renderError('connection.person.lastName')}
                </div>
                {this.renderCompanyNameField()}
                {this.renderStringField('Phone', 'connection.person.mobile', 'connection-phone')}
                {this.renderStringField('Email', 'connection.email', 'connection-email')}
                {this.renderStringField(
                  'Street 1',
                  'connection.address.street1',
                  'connection-street1',
                  isStatementRecipient,
                )}
                {this.renderStringField(
                  'Street 2',
                  'connection.address.street2',
                  'connection-street2',
                )}
                {this.renderStringField(
                  'City',
                  'connection.address.city',
                  'connection-city',
                  isStatementRecipient,
                )}
                {this.renderStringField(
                  'State',
                  'connection.address.state',
                  'connection-state',
                  isStatementRecipient,
                )}
                {this.renderStringField(
                  'Zip',
                  'connection.address.zip',
                  'connection-zip',
                  isStatementRecipient,
                )}
                <ConnectionModalLabel
                  inputId="connection-professional-relationship"
                  labelContent="Professional Relationship"
                >
                  <DashboardTrustBeneficiaryProfileConnectionRelationship
                    handleChange={(event) =>
                      changeFieldValue(
                        'connection.connectionRelationship.professional',
                        event.target.value,
                      )
                    }
                    id="connection-professional-relationship"
                    trustBeneficiarySlug={this.props.trustBeneficiarySlug}
                    type="professionalRelationship"
                    value={values.connection.connectionRelationship.professional}
                  />
                </ConnectionModalLabel>
                <ConnectionModalLabel
                  inputId="connection-social-relationship"
                  labelContent="Social Relationship"
                >
                  <DashboardTrustBeneficiaryProfileConnectionRelationship
                    handleChange={(event) =>
                      changeFieldValue(
                        'connection.connectionRelationship.social',
                        event.target.value,
                      )
                    }
                    id="connection-social-relationship"
                    trustBeneficiarySlug={this.props.trustBeneficiarySlug}
                    type="socialRelationship"
                    value={values.connection.connectionRelationship.social}
                  />
                </ConnectionModalLabel>
                <div>
                  <TrueLinkTooltip
                    placement="right"
                    tooltipContent={
                      <Typography>
                        A "Shared Connection" can be linked to multiple beneficiaries. Any changes
                        made to a "Shared Connection" will automatically apply to all beneficiaries
                        associated with it.
                      </Typography>
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.connection.isCommon}
                          onChange={() =>
                            setFieldValue('connection.isCommon', !values.connection.isCommon)
                          }
                          value={values.connection.isCommon}
                        />
                      }
                      disabled={this.isEdit}
                      label="Shared Connection"
                      sx={{
                        '& .MuiTypography-root': { fontSize: '1.13rem', fontWeight: 'bold' },
                        border: 0,
                        marginLeft: '-12px',
                      }}
                    />
                    <i className="info-circle" style={{ marginBottom: '5px' }} />
                  </TrueLinkTooltip>
                </div>
                <TrueLinkTooltip
                  placement="right"
                  tooltipContent={
                    <Typography>
                      Check this box and enter a valid mailing address if you would like this
                      Connection to receive a statement.
                    </Typography>
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.connection.connectionRelationship.isStatementRecipient}
                        onChange={() =>
                          setFieldValue(
                            'connection.connectionRelationship.isStatementRecipient',
                            !values.connection.connectionRelationship.isStatementRecipient,
                          )
                        }
                        value={values.connection.connectionRelationship.isStatementRecipient}
                      />
                    }
                    label="Statement Recipient"
                    sx={{
                      '& .MuiTypography-root': { fontSize: '1.13rem', fontWeight: 'bold' },
                      border: 0,
                      marginLeft: '-12px',
                    }}
                  />
                  <i className="info-circle" style={{ marginBottom: '5px' }} />
                </TrueLinkTooltip>
                {!this.statementMailingEnabled && isStatementRecipient && (
                  <Box sx={{ maxWidth: '420px' }}>
                    <TrueLinkBanner
                      sx={{ marginBottom: '1em' }}
                      text={
                        <p className="gray-text">
                          Statements are currently turned <strong>off</strong> for this beneficiary.
                          If you want this connection to receive a statement then go to the Contact
                          Info section and click "Edit". In the edit modal, check the "Mail
                          Statement" option.
                        </p>
                      }
                      variant="info"
                    />
                  </Box>
                )}
                <ConnectionModalLabel inputId="connection-notes" labelContent="Notes">
                  <Textarea
                    id="connection-notes"
                    minRows={3}
                    name="notes"
                    onChange={(event) => changeFieldValue('connection.notes', event.target.value)}
                    value={values.connection.notes}
                  />
                </ConnectionModalLabel>
                {values.connection.isCommon && (
                  <Box sx={{ maxWidth: '420px' }}>
                    <p className="gray-text">
                      This note is attached to the "Shared Connection" and is not unique to any
                      single beneficiary
                    </p>
                  </Box>
                )}
                <div className="tl-form-actions-row" style={{ margin: '1em' }}>
                  <TrueLinkButton
                    className={classNames('btn btn-success', {
                      disabled: !isValid || isSubmitting,
                    })}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    variant="none"
                  >
                    {isSubmitting ? 'Saving...' : 'Save Connection'}
                  </TrueLinkButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </TrueLinkModal>
    );
  }
}

DashboardTrustBeneficiaryProfileConnectionForm.propTypes = {
  onClose: PropTypes.func,
  connection: connectionShape,
  trustBeneficiarySlug: PropTypes.string.isRequired,
  statementMailingEnabled: PropTypes.bool.isRequired,
  isTaxDocRecipient: PropTypes.bool.isRequired,
};

DashboardTrustBeneficiaryProfileConnectionForm.defaultProps = {
  connection: {
    person: { firstName: null, lastName: null, mobile: null },
    address: { street1: null, street2: null, city: null, state: null, zip: null },
    connectionRelationship: { social: null, professional: null },
    isCommon: false,
    companyName: null,
    notes: null,
  },
};
