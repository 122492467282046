/* eslint-disable react/prop-types */
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PendingDisbursementsDetailView from 'react/member/components/dashboard/disbursements/filter_table/detail_view/PendingDisbursementsDetailView';
import userRoleShape from 'react/member/shapes/UserRoleShape';

export default function PendingDisbursementsDetailViewWrapper({
  clientSlug,
  disbursementId,
  renderOnHoldDisbursements,
  showSendDisbursementBackToPending,
  userRoles,
}) {
  const [disbursement, setDisbursement] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const disbursementUrl = RailsRoutes.dashboard_organization_disbursement_path(
        clientSlug,
        disbursementId,
        { format: 'json' },
      );
      const response = await axios.get(disbursementUrl);
      setDisbursement(response.data);
    };

    if (clientSlug && disbursementId) {
      fetchData();
    }
  }, [clientSlug, disbursementId]);

  return (
    <>
      {disbursement && (
        <PendingDisbursementsDetailView
          disbursement={disbursement}
          renderOnHoldDisbursements={renderOnHoldDisbursements}
          showSendDisbursementBackToPending={showSendDisbursementBackToPending}
          userRoles={userRoles}
        />
      )}
    </>
  );
}

PendingDisbursementsDetailView.propTypes = {
  clientSlug: PropTypes.string,
  disbursementId: PropTypes.string,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
};
