import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import SpendingSettingsSdkContainer from 'react/member/card/components/spending_monitor_tab/SpendingSettingsSdkContainer';

const useStyles = makeStyles({
  sdkContainer: {
    '&.SpendingSettings input[type="text"]': {
      border: '0',
      boxShadow: 'none',
      padding: '8.5px 14px 8.5px 0',
      margin: '0',
      height: '40px',
    },

    '&.SpendingSettings input[disabled]': {
      backgroundColor: '#fff',
    },

    '&.SpendingSettings h1': {
      display: 'none',
    },
    '&.SpendingSettings h2': {
      fontSize: '26px',
    },
    '& .empty-state-box': {
      color: '#757575',
      fontSize: '21px',
      fontStyle: 'italic',
    },
  },
  '@global': {
    'input#merchant_limit[type="number"], input#merchant_name[type="text"]': {
      height: '40px !important',
      marginBottom: '0 !important',
    },
    'input#merchant-cluster[type="text"]': {
      boxShadow: 'none',
      border: 'none',
      margin: '0',
    },
    '#merchant_info': {
      boxShadow: 'none',
    },
  },
});

export default function CardSpendingMonitorTabContainer({ accountId, canEdit }) {
  const classes = useStyles();

  return (
    <div className={classes.sdkContainer}>
      <SpendingSettingsSdkContainer accountId={accountId} canEdit={canEdit} />
    </div>
  );
}

CardSpendingMonitorTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
