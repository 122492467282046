import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SaveOrCancelButtons from './SaveOrCancelButtons';
import TrashCan from 'images/trash-can.png';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardCardTransactionNoteStore from 'react/member/stores/DashboardCardTransactionNoteStore';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

export default function TransactionNote({ canEdit, cardTransactionId }) {
  const [transactionNote, setTransactionNote] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDestroyModal, setShowDestroyModal] = useState(false);
  const inputRef = useRef(null);

  const hideFormFn = useCallback(() => {
    setShowForm(false);
  }, [setShowForm]);

  const hideDestroyModalFn = () => {
    setShowDestroyModal(false);
  };

  const resetTransactionNote = () => {
    setTransactionNote(null);
  };

  const onFetchNotes = useCallback(
    (notes) => {
      const note = notes[0];
      if (note && note.card_transaction_id === Number(cardTransactionId)) {
        setTransactionNote(notes[0]);
      }
    },
    [cardTransactionId],
  );

  const onCreateNote = useCallback(
    (note) => {
      if (note && note.card_transaction_id === Number(cardTransactionId)) {
        setTransactionNote(note);
        hideFormFn();
      }
    },
    [cardTransactionId, hideFormFn],
  );

  useEffect(() => {
    DashboardCardTransactionNoteStore.on('cardTransactionNotes.fetch', onFetchNotes);
    DashboardCardTransactionNoteStore.on('cardTransactionNote.create', onCreateNote);
    DashboardActions.fetchCardTransactionNotes(cardTransactionId);

    return () => {
      DashboardCardTransactionNoteStore.off('cardTransactionNotes.fetch', onFetchNotes);
      DashboardCardTransactionNoteStore.off('cardTransactionNote.create', onCreateNote);
    };
  }, [cardTransactionId, onFetchNotes, onCreateNote]);

  const updateNoteContent = (evt) => {
    setTransactionNote((prevNote) => ({
      ...prevNote,
      note_content: evt.target.value,
      card_transaction_id: cardTransactionId,
    }));
  };

  const saveNoteContent = (_evt) => {
    const { card_transaction_id, note_content } = transactionNote;
    const cardTransactionNote = { card_transaction_id, note_content };

    if (!transactionNote || !transactionNote.note_content?.trim()) {
      Truelink.flash('alert', 'Please enter a value');
      return;
    }

    if (transactionNote.updated_at) {
      Truelink.flash('success', 'Updated the note.');
      hideFormFn();
      DashboardActions.updateCardTransactionNote(transactionNote.id, {
        card_transaction_note: cardTransactionNote,
      });
    } else {
      Truelink.flash('success', 'Added the note.');
      DashboardActions.createCardTransactionNote({ card_transaction_note: cardTransactionNote });
    }
  };

  const destroyNote = (_evt) => {
    Truelink.flash('success', 'Deleted the note.');
    resetTransactionNote();
    hideDestroyModalFn();
    DashboardActions.destroyCardTransactionNote(transactionNote.id);
  };

  const showDestroyModalFn = (e) => {
    e.preventDefault();
    setShowDestroyModal(true);
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const newNote = (e) => {
    e.preventDefault();
    setTransactionNote({
      note_content: '',
      card_transaction_id: cardTransactionId,
    });
    setShowForm(true);
    window.setTimeout(focusInput, 0);
  };

  const showFormFn = (e) => {
    e.preventDefault();
    setShowForm(true);
    window.setTimeout(focusInput, 0);
  };

  const saveOnEnter = (e) => {
    // Enter key = keycode 13
    if (e.which === 13 && e.target.value && e.target.value.trim().length > 0) {
      saveNoteContent();
    }
  };

  let buttonsSection, contentSection;
  if (showForm) {
    contentSection = (
      <input
        id={`transaction_note_content${cardTransactionId}`}
        name="card_transaction_note[note_content]"
        onChange={updateNoteContent}
        onKeyUp={saveOnEnter}
        ref={inputRef}
        style={{ width: '100%' }}
        type="text"
        value={transactionNote ? transactionNote.note_content : ''}
      />
    );
    buttonsSection = (
      <div className="nowrap" style={{ paddingLeft: '36px' }}>
        <SaveOrCancelButtons onCancel={hideFormFn} onSave={saveNoteContent} />
      </div>
    );
  } else if (!transactionNote?.updated_at) {
    contentSection = canEdit && (
      <TrueLinkLink ariaLabel="Add a transaction note" onClick={newNote}>
        + Add note
      </TrueLinkLink>
    );
    buttonsSection = null;
  } else {
    contentSection = (
      <div className="flex-1">
        <div className="attachment__text">{transactionNote.note_content}</div>
        <div className="attachment__meta flex flex--space-between">
          <div>
            {canEdit && <TrueLinkLink onClick={showFormFn}>Edit</TrueLinkLink>}
            &nbsp;&nbsp;&bull;&nbsp;&nbsp;
            {moment(transactionNote.updated_at).format('M/D/YY h:mm A')}
            &nbsp;&nbsp;&bull;&nbsp;&nbsp;
            {transactionNote.author}
          </div>
          {canEdit && (
            <TrueLinkButton
              onClick={showDestroyModalFn}
              style={{ border: 0, background: 'none' }}
              variant="none"
            >
              <img alt="Delete note" height={23} src={TrashCan} width={23} />
            </TrueLinkButton>
          )}
        </div>
      </div>
    );
    buttonsSection = null;
  }

  return (
    <div className="new-form__section new-form--compact new-form__section--inset transaction_note">
      <div className="new-form-field">
        <div className="new-form__label">Notes:</div>
        <div className="new-form__data flex flex--space-between">
          {contentSection}
          {buttonsSection}
        </div>
      </div>
      {showDestroyModal && (
        <AreYouSurePopUp
          key={transactionNote.id}
          messageText="Are you sure you want to delete this note?"
          onClose={hideDestroyModalFn}
          onSubmit={destroyNote}
          openModal={showDestroyModal}
          submitText="Yes, delete"
        />
      )}
    </div>
  );
}

TransactionNote.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  cardTransactionId: PropTypes.string.isRequired,
};
