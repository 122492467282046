export const black = '#000';
export const blackShadow = 'rgba(0, 0, 0, 0.15)';
export const blackTransparent = 'rgba(0, 0, 0, 0.7)';
export const blueLight = '#E6F2FA';
export const blue = '#0070C2';
export const blueDark = '#004B81';
export const blueRoyal = '#1E0ED2';
export const emerald = '#0D875C';
export const emeraldLight = '#E7F3EF';
export const evergreen = '#1F514C';
export const paleAqua = '#eef9f9';
export const paleMint = '#EFF8EC';
export const grey = '#F9F9F8';
export const grey1 = '#333333';
export const grey2 = '#4F4F4F';
export const grey3 = '#828282';
export const grey4 = '#BDBDBD';
export const grey5 = '#E0E0E0';
export const grey6 = '#F2F2F2';
export const orangeLight = '#ECDDCE';
export const orange = '#BF5A00';
export const orangeDark = '#994800';
export const redLight = '#F7E4E3';
export const red = '#C94841';
export const redDark = '#A13A34';
export const white = '#FFF';

const PALETTE = {
  black,
  blackShadow,
  blackTransparent,
  blueLight,
  blue,
  blueDark,
  emerald,
  emeraldLight,
  evergreen,
  grey,
  grey1,
  grey2,
  grey3,
  grey4,
  grey5,
  grey6,
  orangeLight,
  orange,
  orangeDark,
  paleAqua,
  paleMint,
  redLight,
  red,
  redDark,
  white,
};

export default PALETTE;
