import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorText from 'react/signups/card/common/ErrorText';

const useStyles = makeStyles(() => ({
  selectLabel: {
    '&.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
}));

export default function TrueLinkSelect({
  id,
  className,
  disabled,
  onChange,
  placeholder,
  options,
  onOpen,
  required,
  name,
  value,
  sx,
}) {
  const classes = useStyles();

  return (
    <FormControl className={className} fullWidth size="small">
      <InputLabel className={classes.selectLabel}>{placeholder}</InputLabel>
      <Select
        disabled={disabled}
        displayEmpty
        fullWidth
        id={id}
        inputProps={{
          'data-testid': id,
        }}
        name={name}
        onChange={onChange}
        onOpen={onOpen}
        required={required}
        sx={sx}
        value={value}
      >
        {options.map((option) => (
          <MenuItem id={option.id} key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <ErrorText name={name} />
    </FormControl>
  );
}

TrueLinkSelect.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.object,
};
