import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import PopUp from 'react/shared/components/popups/PopUp';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileAccountClosing extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedModalOption: null,
      isClosable: this.context.client.closable,
      submitting: false,
    };

    bindAll(this);
  }

  handleOptionChange(ev) {
    return this.setState({ selectedModalOption: ev.target.value });
  }

  sendMessage() {
    if (this.state.selectedModalOption) {
      this.setState({ submitting: true });
      let data;
      const clientId = this.context.client.id;
      const closeReason = this.state.selectedModalOption;
      const closeNotes = $(ReactDOM.findDOMNode(this.refs.messageContent)).val();
      if (this.state.selectedModalOption === 'deceased') {
        const dateOfDeath = $(ReactDOM.findDOMNode(this.refs.dateOfDeath)).val();
        const trustbalanceAtDeath = $(ReactDOM.findDOMNode(this.refs.trustbalanceAtDeath)).val();
        const medicaidClaimAmount = $(ReactDOM.findDOMNode(this.refs.medicaidClaimAmount)).val();
        data = {
          reason: closeReason,
          notes: closeNotes,
          dateOfDeath: moment(dateOfDeath).format('YYYY-MM-DD'),
          trustbalanceAtDeath,
          medicaidClaimAmount,
        };
      } else {
        data = { reason: closeReason, notes: closeNotes };
      }

      $.ajax({
        url: RailsRoutes.dashboard_trust_beneficiary_send_destroy_request_path(clientId, {
          format: 'json',
        }),
        method: 'PUT',
        dataType: 'JSON',
        data,
      })
        .done(() => {
          this.closeModal();
          this.setState({ isClosable: false });
          $('#close-account').hide();
          this.props.onSuccess();
          Truelink.flash('success', 'A request to close the account has been created.');
        })
        .always(() => {
          this.setState({ submitting: false });
        });
    }
  }

  deceasedFormContent() {
    return (
      <div style={{ width: '30rem', marginTop: '10px', marginLeft: '25px' }}>
        <div className="new-form-field">
          <div className={'new-form__label'}>Date of Death</div>
          <div className={'new-form__data new-form__data--editable'}>
            <input
              defaultValue={this.context.client.date_of_death}
              placeholder="YYYY-MM-DD"
              ref="dateOfDeath"
              type="date"
            />
          </div>
        </div>
        <div className="new-form-field">
          <div className={'new-form__label'}>Trust Balance at Death</div>
          <div className={'new-form__data new-form__data--editable'}>
            <input
              defaultValue={this.context.client.trust_balance_at_death}
              ref="trustbalanceAtDeath"
              step="0.01"
              type="number"
            />
          </div>
        </div>
        <div className="new-form-field">
          <div className={'new-form__label'}>Medicaid Claim Amount</div>
          <div className={'new-form__data new-form__data--editable'}>
            <input
              defaultValue={this.context.client.medicaid_claim_amount}
              ref="medicaidClaimAmount"
              step="0.01"
              type="number"
            />
          </div>
        </div>
      </div>
    );
  }

  modalContent() {
    const deceasedForm =
      this.state.selectedModalOption === 'deceased' ? this.deceasedFormContent() : undefined;

    switch (this.state.modalBody) {
      case 'close':
        return (
          <div
            className="radio-group radio-group--block"
            style={{ width: '30rem', marginTop: '-10px' }}
          >
            <label htmlFor="closeReasonBeneficiaryDeceased">
              <input
                checked={this.state.selectedModalOption === 'deceased'}
                id="closeReasonBeneficiaryDeceased"
                onChange={this.handleOptionChange}
                type="radio"
                value="deceased"
              />{' '}
              Death of beneficiary{' '}
            </label>
            <label htmlFor="closeReasonLowBalance">
              <input
                checked={this.state.selectedModalOption === 'low_balance'}
                id="closeReasonLowBalance"
                onChange={this.handleOptionChange}
                type="radio"
                value="low_balance"
              />{' '}
              Low Account Balance{' '}
            </label>
            <label htmlFor="closeReasonCourtOrdered">
              <input
                checked={this.state.selectedModalOption === 'court_ordered'}
                id="closeReasonCourtOrdered"
                onChange={this.handleOptionChange}
                type="radio"
                value="court_ordered"
              />{' '}
              Court Ordered Termination{' '}
            </label>
            <label htmlFor="closeReasonSuccessor">
              <input
                checked={this.state.selectedModalOption === 'successor'}
                id="closeReasonSuccessor"
                onChange={this.handleOptionChange}
                type="radio"
                value="successor"
              />{' '}
              Successor Trustee Appointed{' '}
            </label>
            <label htmlFor="closeReasonAgeOfAttainment">
              <input
                checked={this.state.selectedModalOption === 'age_of_attainment'}
                id="closeReasonAgeOfAttainment"
                onChange={this.handleOptionChange}
                type="radio"
                value="age_of_attainment"
              />{' '}
              Age of Attainment{' '}
            </label>
            <label htmlFor="closeReasonOther">
              <input
                checked={this.state.selectedModalOption === 'other'}
                id="closeReasonOther"
                onChange={this.handleOptionChange}
                type="radio"
                value="other"
              />{' '}
              Other (mark below)
            </label>
            {deceasedForm}
            <label htmlFor="closeReasonNotes">Notes</label>
            <textarea
              id="closeReasonNotes"
              ref="messageContent"
              style={{ width: '90%', height: '100px', marginBottom: '0', marginLeft: '25px' }}
            />
          </div>
        );
    }
  }

  modalFooter() {
    const subject = (() => {
      switch (this.state.modalBody) {
        case 'close':
          return 'Custom reporting request';
      }
    })();

    return (
      <div className="pull-left flex">
        <button
          className="normal btn btn-alert"
          disabled={this.state.submitting}
          onClick={() => this.sendMessage(subject)}
          style={{ marginLeft: '1.2rem' }}
          type="button"
        >
          Confirm Closing Account
        </button>
        <button
          className="btn btn-default cancel"
          onClick={this.closeModal}
          style={{ marginLeft: '.7rem' }}
          type="button"
        >
          Cancel
        </button>
      </div>
    );
  }

  closeModal() {
    this.setState({ header: '', modalOpen: false });
  }

  openModal(type) {
    if (type === 'close') {
      this.setState({
        header: 'Why are you closing this account?',
        modalOpen: true,
        modalBody: 'close',
      });
    }
  }

  render() {
    if (isRestrictedViewUser()) {
      return null;
    }

    return (
      <div id="close-account">
        <div className="info-box close-account-box">
          Close Beneficiary Account
          <div className="pull-right">
            <button
              className="btn btn-danger normal close-account-button"
              onClick={() => this.openModal('close')}
              type="button"
            >
              Close Account
            </button>
          </div>
        </div>

        <PopUp
          footer={this.modalFooter()}
          header={this.state.header}
          maxWidth={'35rem'}
          openModal={this.state.modalOpen}
        >
          {this.modalContent()}
        </PopUp>
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileAccountClosing.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

DashboardTrustBeneficiaryProfileAccountClosing.contextTypes = {
  client: PropTypes.object,
};
