import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkSurveyBanner from 'react/shared/components/customer_banners/TrueLinkSurveyBanner';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: PALETTE.grey,
  },
  headerInner: {
    width: '940px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 0px',

    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  surveyBanner: {
    width: '940px',
    margin: '0 auto',
  },
  accountRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '27px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
      flexDirection: 'column',
    },
  },

  headerText: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
}));

export default function AccountsListHeader(props) {
  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={classes.header}>
          <div className={classes.headerInner}>
            <div className={classes.accountRow}>
              <div className={classes.headerText}>All Card Accounts</div>
              {props.showOrderCardButton && (
                <form action={props.cardOrderPath} method="POST">
                  <button className="btn btn-success" id="order-card-btn" type="submit">
                    + Add a new cardholder
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className={classes.surveyBanner}>
            <TrueLinkSurveyBanner email={props.email} />
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

AccountsListHeader.propTypes = {
  cardOrderPath: PropTypes.string.isRequired,
  showOrderCardButton: PropTypes.bool,
  email: PropTypes.string,
};
