import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReportDateRange from 'react/shared/card/components/reports_tab/ReportDateRange';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TlFieldValidators from 'react/shared/utils/tl_field_validators';

const { dateRegExp } = TlFieldValidators;

const useStyles = makeStyles({
  wrapper: {
    marginTop: '20px',
    '& button': {
      marginRight: '5px',
    },
  },
  checkboxLabel: {
    marginBottom: '25px',
    marginTop: '-10px',
  },
});

export default function TransactionReport(props) {
  const classes = useStyles();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [includeReceipts, setIncludeReceipts] = useState(false);

  const makeRoute = (params) =>
    props.isCardholder
      ? RailsRoutes.cardholder_transaction_report_path(props.accountId, params)
      : RailsRoutes.transaction_report_path(props.accountId, params);

  const validDateFormats = () => {
    const valid = dateRegExp.test(startDate) && dateRegExp.test(endDate);
    if (!valid) {
      Truelink.flash('error', 'Invalid start or end date', true, true);
    }
    return valid;
  };

  const handleOpen = (event) => {
    event.preventDefault();
    if (!validDateFormats()) return;

    const route = makeRoute({ start_date: startDate, end_date: endDate });
    window.open(route);
  };

  const handleDownload = (event) => {
    event.preventDefault();
    if (!validDateFormats()) return;

    const route = makeRoute({
      start_date: startDate,
      end_date: endDate,
      download: true,
      include_receipts: includeReceipts,
    });
    window.location.href = route;
  };

  return (
    <div className={classes.wrapper}>
      <ReportDateRange
        endDate={endDate}
        onSetEndDate={setEndDate}
        onSetStartDate={setStartDate}
        reportName="transaction_report"
        startDate={startDate}
      />
      {props.showIncludeReceiptsCheckbox && (
        <div>
          <label
            className={`${classes.checkboxLabel} new-form__checkbox`}
            htmlFor="include_receipts_transaction_report"
          >
            <input
              checked={includeReceipts}
              id="include_receipts_transaction_report"
              name="include_receipts_transaction_report"
              onChange={() => {
                setIncludeReceipts(!includeReceipts);
              }}
              type="checkbox"
            />{' '}
            Include receipts in report export
          </label>
        </div>
      )}
      <TrueLinkButton disabled={includeReceipts} onClick={handleOpen} variant="primary">
        Open
      </TrueLinkButton>
      <TrueLinkButton onClick={handleDownload} variant="primary">
        Download as Spreadsheet
      </TrueLinkButton>
    </div>
  );
}

TransactionReport.propTypes = {
  accountId: PropTypes.string.isRequired,
  isCardholder: PropTypes.bool.isRequired,
  showIncludeReceiptsCheckbox: PropTypes.bool,
};
