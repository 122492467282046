import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import UniqueAssetsTable from 'react/member/components/dashboard/clients/dashboard_client/unique_assets/UniqueAssetsTable';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: theme.spacing(3),
  },
  backLinkWrapper: {
    fontWeight: 400,
    color: PALETTE.grey2,
    marginBottom: theme.spacing(4),
  },
  backLinkIcon: {
    marginRight: theme.spacing(1),
    fontSize: '16px',
  },
  backLinkLink: {
    color: PALETTE.grey2,
  },
}));

export default function UniqueAssetsPage({ beneficiarySlug, clientId, userRoles, firstName }) {
  const classes = useStyles();
  const handleBackToOverview = () =>
    (window.location.href = RailsRoutes.dashboard_client_path(beneficiarySlug));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Typography className={classes.backLinkWrapper}>
            <TrueLinkLink className={classes.backLinkLink} onClick={handleBackToOverview}>
              <TrueLinkIcon className={classes.backLinkIcon} icon="chevron-left" />
              Back to {firstName}'s Dashboard
            </TrueLinkLink>
          </Typography>
          <div>
            <UniqueAssetsTable clientId={clientId} userRoles={userRoles} />
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

UniqueAssetsPage.propTypes = {
  beneficiarySlug: PropTypes.string.isRequired,
  clientId: PropTypes.number,
  userRoles: userRoleShape.isRequired,
  firstName: PropTypes.string,
};
