import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

export default function AttachmentsTable({ fetchAttachments, onDeleteAttachment }) {
  const [attachments, setAttachments] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchAttachments();
      setAttachments(response.data);
    };
    fetchData();
  }, [fetchAttachments]);

  const handleDelete = async () => {
    await axios.delete(`/api/v2/attachments/${selectedAttachment.id}`);
    const response = await fetchAttachments();
    setAttachments(response.data);
    if (onDeleteAttachment) onDeleteAttachment();
    setShowDeleteModal(false);
    setSelectedAttachment(null);
  };

  if (!attachments || attachments.length === 0) {
    return null;
  }

  return (
    <Box>
      <Table
        sx={{
          width: '100%',
          mb: 2.5,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ pt: 0, fontWeight: 'bold' }}>File name</TableCell>
            <TableCell sx={{ pt: 0, textAlign: 'right', fontWeight: 'bold' }}>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment) => (
            <TableRow key={attachment.id}>
              <TableCell sx={{ wordBreak: 'break-word' }}>
                <Box
                  component="a"
                  href={`/api/v2/attachments/${attachment.id}`}
                  sx={{ display: 'inline-block' }}
                >
                  {attachment.attributes.name}
                </Box>
              </TableCell>
              <TableCell sx={{ textAlign: 'right', width: 75 }}>
                <Box
                  component="a"
                  onClick={() => {
                    setSelectedAttachment(attachment);
                    setShowDeleteModal(true);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      setSelectedAttachment(attachment);
                      setShowDeleteModal(true);
                    }
                  }}
                  role="link"
                  tabIndex="0"
                >
                  <TrueLinkIcon icon="trash" style={{ cursor: 'pointer' }} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {showDeleteModal && (
        <AreYouSurePopUp
          messageText="Are you sure you want to delete this document?"
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedAttachment(null);
          }}
          onSubmit={handleDelete}
          openModal={showDeleteModal}
          submitText="Yes, delete"
        />
      )}
    </Box>
  );
}

AttachmentsTable.propTypes = {
  fetchAttachments: PropTypes.func.isRequired,
  onDeleteAttachment: PropTypes.func,
};
