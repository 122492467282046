import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import AttachCard from './AttachCard';
import DashboardAccountHeader from './DashboardAccountHeader';
import CardInInvestAccountTabContainer from 'react/member/card/components/account_tab/CardInInvestAccountTabContainer';
import CardInInvestAlertsTabContainer from 'react/member/card/components/alerts_tab/CardInInvestAlertsTabContainer';
import CardInInvestReportsTabContainer from 'react/member/card/components/reports_tab/CardInInvestReportsTabContainer';
import CardInInvestSpendingMonitorTabContainer from 'react/member/card/components/spending_monitor_tab/CardInInvestSpendingMonitorTabContainer';
import CardInInvestTransactionsTabContainer from 'react/member/card/components/transactions_tab/CardInInvestTransactionsTabContainer';
import { getAccountsAnalysis } from 'react/member/components/dashboard/clients/dashboard_client/overview/api';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';

export default function DashboardAccount(props) {
  const [showSpendingSettings, setShowSpendingSettings] = useState(false);

  const navigateToBeneOverview = useCallback(() => {
    // For a better UX, this should send the user to the card page.
    // For now, we don't have the card ID, so send them to the overview
    window.location.href = RailsRoutes.dashboard_client_path(props.client.id);
  }, [props.client.id]);

  useEffect(() => {
    async function fetchData() {
      if (props.selectedAccountId !== null && props.selectedAccountId?.trim() !== '') {
        const accountsRes = await getAccountsAnalysis(props.selectedAccountId, [
          'showSpendingSettings',
        ]);
        setShowSpendingSettings(accountsRes?.showSpendingSettings);
      }
    }
    fetchData();

    DashboardClientStore.on('client.linkCard', navigateToBeneOverview);
    return () => {
      DashboardClientStore.off('client.linkCard', navigateToBeneOverview);
    };
  }, [props.selectedAccountId, navigateToBeneOverview]);

  function clientHasCards() {
    return props.client.cards && props.client.cards.length > 0;
  }

  function isViewOnly() {
    return props.userRoles.viewOnly;
  }

  function tabList() {
    const spendingMonitorTab = {
      id: 'wm-spending-monitor-tab',
      title: 'Spending Settings',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const alertsTab = {
      id: 'wm-alerts-tab',
      title: 'Alerts',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const reportsTab = {
      id: 'wm-reports-tab',
      title: 'Reports',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const transactionsTab = {
      id: 'wm-transactions-tab',
      title: 'Transactions',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const accountTab = {
      id: 'wm-account-tab',
      title: 'Account',
      className: 'btn btn-secondary',
      hideHref: true,
    };

    return [
      showSpendingSettings ? spendingMonitorTab : null,
      showSpendingSettings ? alertsTab : null,
      reportsTab,
      transactionsTab,
      isViewOnly() ? null : accountTab,
    ].filter((tab) => tab !== null);
  }

  if (!clientHasCards()) {
    const orgSlug = props.client.organization.id;
    return <AttachCard clientId={props.client.id} organizationId={orgSlug} />;
  }

  const viewOnly = isViewOnly();
  const {
    client: { firstName },
    selectedAccountId,
  } = props;
  const selectedAccount = props.client.accounts.find((account) => account.id === selectedAccountId);
  const selectedCard = props.client.cards.find(
    (card) => card.id === selectedAccount.relationships?.currentCard?.id,
  );

  return (
    <div style={{ textAlign: 'center', marginTop: '25px' }}>
      <DashboardAccountHeader
        accountId={selectedAccountId}
        firstName={firstName}
        lastFour={selectedCard && selectedCard.lastFour}
      />
      <TabNavigation
        classOptions={'btn-group'}
        initialTabIndex={0}
        style={{ marginBottom: 20 }}
        tabs={tabList()}
      >
        {showSpendingSettings && (
          <TabContent>
            <CardInInvestSpendingMonitorTabContainer
              accountId={selectedAccountId}
              canEdit={!viewOnly}
            />
          </TabContent>
        )}
        {showSpendingSettings && (
          <TabContent>
            <CardInInvestAlertsTabContainer accountId={selectedAccountId} canEdit={!viewOnly} />
          </TabContent>
        )}
        <TabContent>
          <CardInInvestReportsTabContainer accountId={selectedAccountId} canEdit={!viewOnly} />
        </TabContent>
        <TabContent>
          <CardInInvestTransactionsTabContainer accountId={selectedAccountId} />
        </TabContent>
        {!viewOnly && (
          <TabContent>
            <CardInInvestAccountTabContainer
              accountId={selectedAccountId}
              personName={`${props.client.firstName} ${props.client.lastName}`}
            />
          </TabContent>
        )}
      </TabNavigation>
    </div>
  );
}

DashboardAccount.propTypes = {
  client: PropTypes.object,
  userRoles: userRoleShape.isRequired,
  selectedAccountId: PropTypes.string,
};
