import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';

export default function TrueLinkMaskedTextInput(props) {
  return (
    <ReactInputMask {...props}>
      {(inputProps) => (
        <div>
          <TrueLinkTextInput {...inputProps} />
        </div>
      )}
    </ReactInputMask>
  );
}

TrueLinkMaskedTextInput.propTypes = {
  errorOverride: PropTypes.bool,
  labelAdornment: PropTypes.node,
  labelText: PropTypes.string,
  mask: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
