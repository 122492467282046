import axios from 'axios';
import _filter from 'underscore/modules/filter';
import _find from 'underscore/modules/find';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

const DashboardDisbursementStore = flux.createStore({
  disbursements: [],
  externalCheckDisbursements: [],
  actions: [
    DashboardActions.destroyDisbursement,
    DashboardActions.fetchDisbursements,
    DashboardActions.fetchDisbursementsByPortfolio,
    DashboardActions.fetchExternalCheckDisbursementsByOrganization,
    DashboardActions.fetchExternalCheckDisbursements,
    DashboardActions.approveDisbursement,
    DashboardActions.openDisbursementDenialModal,
    DashboardActions.denyOneTimeDisbursement,
    DashboardActions.updateDisbursement,
    DashboardActions.returnDisbursementForRevision,
  ],

  destroyDisbursement(disbursement) {
    const url = RailsRoutes.api_v2_disbursement_path(disbursement.id);

    return axios
      .delete(url)
      .then(() => {
        this.emit('disbursement.delete', disbursement.id);
        Truelink.flash('success', 'Disbursement deleted');
      })
      .catch(() => {
        Truelink.flash(
          'error',
          `Unable to delete Disbursement (ID=${disbursement.id}). Please contact True Link support for further assistance.`,
        );
      });
  },

  fetchDisbursements(client_id) {
    return $.get(
      RailsRoutes.dashboard_client_disbursements_path(client_id, { format: 'json' }),
      (disbursements) => {
        this.disbursements = disbursements;
        this.emit('disbursements.fetch', this.disbursements);
      },
    );
  },

  fetchDisbursementsByPortfolio(portfolioId) {
    return $.get(
      RailsRoutes.dashboard_portfolio_disbursements_path(portfolioId, {
        format: 'json',
      }),
      (disbursements) => {
        this.disbursements = disbursements;
        this.emit('disbursements.fetch', this.disbursements);
      },
    );
  },

  fetchExternalCheckDisbursementsByOrganization(organization_id, page, per_page, future) {
    const params = { format: 'json', page, per_page, future };
    return $.get(
      RailsRoutes.dashboard_organization_external_checks_path(organization_id, params),
      (externalCheckDisbursements) => {
        this.externalCheckDisbursements = externalCheckDisbursements.disbursements;
        if (future) {
          this.emit('externalCheckDisbursements.future.fetch', this.externalCheckDisbursements);
        } else {
          this.emit('externalCheckDisbursements.fetch', this.externalCheckDisbursements);
        }
      },
    );
  },

  fetchExternalCheckDisbursements(page, per_page, future) {
    const params = { format: 'json', page, per_page, future };
    return $.get(
      RailsRoutes.dashboard_external_checks_path(params),
      (externalCheckDisbursements) => {
        this.externalCheckDisbursements = externalCheckDisbursements.disbursements;
        if (future) {
          this.emit('externalCheckDisbursements.future.fetch', this.externalCheckDisbursements);
        } else {
          this.emit('externalCheckDisbursements.fetch', this.externalCheckDisbursements);
        }
      },
    );
  },

  async approveDisbursement(disbursement, user_id, callback) {
    const url = RailsRoutes.dashboard_disbursement_approve_path(disbursement.id, {
      format: 'json',
    });
    const oldDeliveryDate = disbursement.delivery_date;

    try {
      const request = await axios.put(url, { user_id }, AXIOS_XHR_CONFIG);
      let approvalNotification;
      if (request.data.transfer_funded) {
        approvalNotification =
          'Disbursement approved, card funding has been initiated. Check card details for updated balance!';
      } else if (oldDeliveryDate === request.data.delivery_date || oldDeliveryDate === null) {
        approvalNotification = 'Disbursement approved!';
      } else {
        approvalNotification =
          'The process date of this disbursement has been automatically updated.';
      }

      Truelink.flash('success', approvalNotification);
      this.emit('disbursement.update', request.data);
      callback && callback(true);
    } catch (error) {
      Truelink.flash('error', 'Could not approve this disbursement');
      callback && callback(false);
    }
  },

  openDisbursementDenialModal(id, type) {
    this.emit('disbursementDenialModal.open', id, type);
  },

  denyOneTimeDisbursement(id, reason) {
    const url = RailsRoutes.dashboard_disbursement_deny_path(id, { format: 'json' });
    return $.ajax({
      url,
      type: 'PUT',
      data: { reason },
      success: (disbursement) => {
        Truelink.flash('notice', 'Disbursement denied!');
        this.emit('disbursement.update', disbursement);
        this.emit('disbursementDenialModal.close');
      },
    });
  },

  updateDisbursement(disbursement_id, data, eventOpts = {}) {
    const url = RailsRoutes.dashboard_disbursement_path(disbursement_id, {
      format: 'json',
    });
    return $.ajax({
      type: 'PUT',
      url,
      data,
      success: (disbursement) => {
        Truelink.flash('success', 'Disbursement updated!');
        this.emit('disbursement.update', disbursement, eventOpts);
      },
      fail: () => {
        Truelink.flash('error', 'There was a problem updating this disbursement.');
      },
      complete: () => {
        eventOpts.onRequestCompleted && eventOpts.onRequestCompleted();
      },
    });
  },

  returnDisbursementForRevision(disbursement_id) {
    const data = [{ name: 'disbursement[status]', value: 'Pending' }];
    const url = RailsRoutes.dashboard_disbursement_return_for_revision_path(disbursement_id, {
      format: 'json',
    });
    return $.ajax({
      type: 'PATCH',
      url,
      data,
      success: (disbursement) => {
        Truelink.flash('success', 'Disbursement returned for revision.');
        this.emit('disbursement.update', disbursement);
      },
      fail: () => {
        Truelink.flash('error', 'There was a problem returning this disbursement for revision.');
      },
    });
  },

  exports: {
    get(id) {
      return _find(this.disbursements, (disbursement) => disbursement.id === id);
    },

    pending() {
      return _filter(this.disbursements, (disbursement) => disbursement.status === 'Pending');
    },

    getExternalCheckDisbursements() {
      return this.externalCheckDisbursements;
    },
  },
});

export default DashboardDisbursementStore;
