import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import CardholderLoginFormFields from './CardholderLoginFormFields';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import theme from 'react/shared/theme/Theme';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

const validationSchema = Yup.object().shape({
  card: Yup.string()
    .matches(/^\d{4}$/, 'Please enter a value of 4 digits long.')
    .required('Required'),
  ssn: Yup.string()
    .matches(/^\d{4}$/, 'Please enter a value of 4 digits long.')
    .required('Required'),
  dob: Yup.string()
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/, 'Must have format mm/dd/yyyy')
    .required('Required'),
});

export default function CardholderLoginForm({ formFill, programName }) {
  const initialValues = {
    card: formFill?.card || '', // formFill is the object passed from the parent component in the cardholder_login/new.html.haml file
    ssn: '',
    dob: '',
  };

  const handleSubmit = async (values) => {
    const response = await axios.post(RailsRoutes.cardholder_sign_in_path(), values, {
      ...AXIOS_XHR_CONFIG,
    });
    if (response.data.includes('Current Balance')) {
      window.location.href = response.request.responseURL;
    } else {
      // The server returns an error page with a flash message
      // Extract just the flash message container from the response
      const parser = new window.DOMParser();
      const doc = parser.parseFromString(response.data, 'text/html');
      const flashContainer = doc.querySelector('#flash');

      if (flashContainer) {
        // Replace only the flash container in the current page
        const currentFlash = document.querySelector('#flash');
        if (currentFlash) {
          currentFlash.innerHTML = flashContainer.innerHTML;
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CardContent sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <Box className="login-header" sx={{ paddingTop: '16px' }}>
          <Typography variant="h3">
            {`${programName !== undefined ? programName : ''}`} Cardholder log in
          </Typography>
          <Typography sx={{ fontStyle: 'italic' }} variant="body2">
            Check your balance and review transactions.
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ touched, isSubmitting, handleChange, handleSubmit, setFieldTouched, values }) => (
            <>
              <CardholderLoginFormFields
                isSubmitting={isSubmitting}
                onChange={handleChange}
                setFieldTouched={setFieldTouched}
                touched={touched}
                values={values}
              />
              <Grid
                container
                justifyContent="center"
                paddingLeft="24px"
                paddingRight="24px"
                paddingTop="0px"
              >
                <TrueLinkButton
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  size="large"
                  type="submit"
                  variant="primary"
                >
                  Log in
                </TrueLinkButton>
              </Grid>
            </>
          )}
        </Formik>
      </CardContent>
    </ThemeProvider>
  );
}

CardholderLoginForm.propTypes = {
  formFill: PropTypes.object,
  programName: PropTypes.string,
};
