import createReactClass from 'create-react-class';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _contains from 'underscore/modules/contains';
import _map from 'underscore/modules/map';
import DashboardActions from 'react/member/actions/dashboard_actions';
import AddressEmbed from 'react/shared/components/addresses/AddressEmbed';
import CheckboxField from 'react/shared/components/forms/CheckboxField';
import Controller from 'react/shared/components/forms/Controller';
import Form from 'react/shared/components/forms/Form';
import HiddenField from 'react/shared/components/forms/HiddenField';
import MaskedStringField from 'react/shared/components/forms/MaskedStringField';
import SelectField from 'react/shared/components/forms/SelectField';
import StringField from 'react/shared/components/forms/StringField';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import { asMoney } from 'react/shared/utils/Money';
import { extractFormData } from 'react/shared/utils/form_serialization';

const DashboardNewTrustBeneficiaryForm = createReactClass({
  propTypes: {
    ...Controller.getControllerPropTypes(),
    allowBeneDisbursementRequestsByDefault: PropTypes.bool,
    object: PropTypes.shape({
      person: PropTypes.object.isRequired,
      portfolios: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
      ).isRequired,
      trust: PropTypes.object,
    }).isRequired,
    // Due to the controller mix in needing these, they are required to be here
    // eslint-disable-next-line react/no-unused-prop-types
    editable: PropTypes.bool,
    // Due to the controller mix in needing these, they are required to be here
    // eslint-disable-next-line react/no-unused-prop-types
    trustBeneficiary: PropTypes.object,
    fee: PropTypes.string,
  },

  mixins: [Controller],

  getDefaultProps() {
    return { model: 'TrustBeneficiary', action: 'new' };
  },

  getInitialState() {
    return this.getControllerInitialState({
      show: {
        government_benefits: true,
        trust_remainder_beneficiaries: false,
        trust_settlors: false,
        trust_vehicles: false,
        trust_homes: false,
        trust_agencies: false,
        trust_beneficiary_connections: false,
      },
    });
  },

  componentDidMount() {
    this.url = this._url; // overrides url from the Controller mixin
    // close popup once the client is succesfully added
    DashboardClientStore.on('clients.add', this.closePopup);

    const object = this.object();
    // we store an empty object so we can return to it when the modal closes, clearing the form
    const state = { modalOpen: false, object: { ...object }, emptyObject: { ...object } };
    this.setState(state);
  },

  componentWillUnmount() {
    DashboardClientStore.off('clients.add', this.closePopup);
  },

  loadPortfolios() {
    $.get(
      RailsRoutes.dashboard_trust_portfolios_path(this.object().trust.slug, { format: 'json' }),
      (portfolios) => {
        if (this.state.modalOpen) {
          const options = portfolios.map((portfolio) => ({
            id: `${portfolio.id}`,
            name: portfolio.name,
          }));
          return this.refs.portfolios.setState({ options });
        }
      },
    );
  },

  prefixOptions(target) {
    const options = _map($tlf.domains.personPrefixes.flat(), (option) => ({
      id: option,
      name: option,
    }));
    return target.setState({ options });
  },

  suffixOptions(target) {
    const options = _map($tlf.domains.personSuffixes.flat(), (option) => ({
      id: option,
      name: option,
    }));
    return target.setState({ options });
  },

  genderOptions(target) {
    const options = _map($tlf.domains.genderOptions.flat(), (option) => ({
      id: option,
      name: option,
    }));
    return target.setState({ options });
  },

  yesNoOptions(target) {
    const options = [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ];

    return target.setState({ options });
  },

  closePopup(_ev) {
    this.setState({ modalOpen: false, object: { ...this.state.emptyObject } });
  },

  openPopup() {
    this.setState({ modalOpen: true });
    this.loadPortfolios();
  },

  checkForRequiredFields(data) {
    const errors = [];
    const dob = data['trust_beneficiary[person_attributes][dob]'];
    const ssn = data['trust_beneficiary[ssn]'];
    const soleTrustee = data['trust_beneficiary[sole_trustee]'];
    const investmentDirective = data['trust_beneficiary[investment_directive]'];

    if (!data['trust_beneficiary[person_attributes][first_name]']) {
      errors.push('first name');
    }
    if (!data['trust_beneficiary[person_attributes][last_name]']) {
      errors.push('last name');
    }
    if (!dob) {
      errors.push('date of birth');
    }
    if (!ssn) {
      errors.push('social security number');
    }
    if (!data['trust_beneficiary[portfolio_ids]']) {
      errors.push('portfolio');
    }
    if (!moment(dob, 'MM-DD-YYYY', true).isValid()) {
      errors.push('a valid date of birth in MM-DD-YYYY format');
    }
    if (!/^\d{3}-\d{2}-\d{4}$/.test(ssn)) {
      errors.push('exactly nine digits for social security number');
    }
    if (!soleTrustee && this.props.object.trust.organization.show_sole_trustee_features) {
      errors.push('sole trustee option');
    }
    if (
      !investmentDirective &&
      this.props.object.trust.organization.show_investment_directive_features
    ) {
      errors.push('investment directive option');
    }

    return errors;
  },

  handleSubmit(ev) {
    ev.preventDefault();
    const trustBeneficiary = this.object();
    const trustId = trustBeneficiary.trust.id;
    const trustBeneficiaryForm = $('#new_dashboard_trust_trust_beneficiary_form');
    const serializedData = trustBeneficiaryForm.serializeArray();
    const data = extractFormData(serializedData);
    const errors = this.checkForRequiredFields(data);
    if (errors.length === 0) {
      const url = RailsRoutes.dashboard_trust_trust_beneficiaries_path(trustId);
      return DashboardActions.createTrustBeneficiary(data, url);
    }
    const last_error = errors.pop();
    let msg = errors.length === 0 ? last_error : `${errors.join(', ')} and ${last_error}`;
    msg = _contains(['a', 'e', 'i', 'o', 'u'], msg[0]) ? `an ${msg}` : `a ${msg}`;
    Truelink.flash('error', `Please enter ${msg}.`);
  },

  _url() {
    // Does nothing overriding controller
  },

  formMarkup() {
    const feeInput = this.props.fee ? (
      <CheckboxField
        controller={this}
        editable
        label={`Charge Initial Set-Up Fee? (${asMoney(this.props.fee)})`}
        name="charge_setup_fee"
        optional
        value
      />
    ) : undefined;

    return (
      <div className="beneficiary-info">
        <Form
          className={'beneficiary-info__form beneficiary-info__form--editable'}
          controller={this}
          formId={'new_dashboard_trust_trust_beneficiary_form'}
          ref="form"
        >
          <div className={'new-form__section'}>
            <SelectField
              association
              controller={this}
              editable={false}
              format={this.name}
              name="trust"
            />
            <SelectField
              association
              controller={this}
              editable
              fakeMultiple
              label="Portfolio"
              name="portfolios"
              ref="portfolios"
            />
          </div>
          <div
            className={'new-form__section new-form__section--no-border'}
            style={{ padding: '20px 0' }}
          >
            <div className="beneficiary-info__form__bottom--two-column__left">
              <SelectField
                allowBlank
                controller={this}
                editable
                label="Prefix"
                name="person.prefix"
                optional
                options={this.prefixOptions}
              />
              <StringField controller={this} editable label="First Name" name="person.first_name" />
              <StringField
                controller={this}
                editable
                label="Middle Name"
                name="person.middle_name"
                optional
              />
              <StringField controller={this} editable label="Last Name" name="person.last_name" />
              <SelectField
                allowBlank
                controller={this}
                editable
                label="Gender"
                name="person.gender"
                optional
                options={this.genderOptions}
              />
              <SelectField
                allowBlank
                controller={this}
                editable
                label="Suffix"
                name="person.suffix"
                optional
                options={this.suffixOptions}
              />
              <MaskedStringField
                controller={this}
                editable
                label="DOB"
                mask="99-99-9999"
                name="person.dob"
                placeholder="MM-DD-YYYY"
              />
              <MaskedStringField
                controller={this}
                editable
                label="SSN admin"
                mask="999-99-9999"
                name="ssn"
                placeholder="###-##-####"
              />
              <div style={{ borderTop: '1px solid #ddd', paddingTop: 20, marginTop: 20 }}>
                <StringField
                  controller={this}
                  editable
                  label="External ID"
                  name="external_id"
                  optional
                />
                <StringField
                  controller={this}
                  editable
                  label="Beneficiary ID"
                  name="beneficiary_id"
                  optional
                />
              </div>
            </div>
            <div className="beneficiary-info__form__bottom--two-column__right">
              <MaskedStringField
                controller={this}
                editable
                label="Primary Phone"
                mask="999-999-9999"
                name="person.mobile"
                optional
              />
              <MaskedStringField
                controller={this}
                editable
                label="Secondary Phone"
                mask="999-999-9999"
                name="person.alternate_phone"
                optional
              />
              <StringField controller={this} editable label="Email" name="email" optional />
              <CheckboxField
                controller={this}
                editable
                label="Mail Statement"
                name="mail_statement"
                optional
              />
              <AddressEmbed abbreviate controller={this} editable optional scope="address" />
              <HiddenField controller={this} name="status" value="Pending" />
              <CheckboxField
                controller={this}
                editable
                label="Settlement"
                name="settlement"
                optional
              />
              <CheckboxField
                controller={this}
                editable
                label="Welcome Letter Signed"
                name="welcome_letter_signed_at"
                optional
              />
              <CheckboxField
                controller={this}
                editable
                label="Disbursement Creation Enabled"
                name="allow_disbursement_requests"
                optional
                value={this.props.allowBeneDisbursementRequestsByDefault}
              />
              {this.props.object.trust.organization.show_sole_trustee_features && (
                <SelectField
                  allowBlank
                  controller={this}
                  editable
                  label="Sole Trustee"
                  name="sole_trustee"
                  optional={false}
                  options={this.yesNoOptions}
                />
              )}

              {this.props.object.trust.organization.show_investment_directive_features && (
                <SelectField
                  allowBlank
                  controller={this}
                  editable
                  label="Investment Directive"
                  name="investment_directive"
                  optional={false}
                  options={this.yesNoOptions}
                />
              )}

              {feeInput}
            </div>
          </div>
        </Form>
      </div>
    );
  },

  renderNewBeneButton() {
    if (!_contains($tlf.permissions.userRoles, 'subaccount_management')) return;
    return (
      <TrueLinkLink className="btn-link btn-link--add" onClick={this.openPopup}>
        Add new beneficiary
      </TrueLinkLink>
    );
  },

  renderNewBeneModal() {
    const modalFooter = (
      <div>
        <div className="pull-left">
          <TrueLinkLink className="btn btn-default cancel" onClick={this.closePopup}>
            Cancel
          </TrueLinkLink>
        </div>
        <div className="pull-right">
          <TrueLinkButton onClick={this.handleSubmit} type="submit" variant="primary">
            Submit
          </TrueLinkButton>
        </div>
      </div>
    );

    return (
      <PopUp
        footer={modalFooter}
        header={'New Beneficiary'}
        maxWidth="700px"
        onClose={this.closePopup}
        openModal={this.state.modalOpen}
      >
        {this.formMarkup()}
      </PopUp>
    );
  },

  render() {
    return (
      <div>
        {this.renderNewBeneButton()}
        {this.renderNewBeneModal()}
      </div>
    );
  },
});

export default DashboardNewTrustBeneficiaryForm;
