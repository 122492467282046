import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { createAttachment } from 'react/member/components/dashboard/disbursements/filter_table/utils/attachments_api';
import IdentifierShape from 'react/shared/shapes/IdentifierShape';

const useStyles = makeStyles(() => ({
  dropzoneContainer: {
    flex: '1',
    display: 'flex !important',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '1px',
    borderRadius: '2px',
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '260px',
    textAlign: 'center',
  },
  helperText: {
    color: 'gray',
    borderTop: '1px solid #ddd',
    paddingTop: '20px',
  },
}));

export default function AttachmentDropZone({
  onBeginUpload,
  onSuccess,
  attachable,
  upload,
  hideToast,
}) {
  const classes = useStyles();

  const isDisallowedExtension = (file) => {
    const fileName = file.name;
    const ext = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
    return !$tlf.domains.allowedExtensions.includes(ext);
  };

  const allowedExtensionsErrorMessage = (fileName) =>
    `You tried to upload "${fileName}". We currently do not support this file type. Please use these file types: ${$tlf.domains.allowedExtensions.join(
      ', ',
    )}`;

  const onDrop = useCallback(
    (acceptedFiles) => {
      onBeginUpload && onBeginUpload();
      const queries = [];
      acceptedFiles.map((file) => {
        if (isDisallowedExtension(file)) {
          Truelink.flash('error', allowedExtensionsErrorMessage(file.name));
          return;
        }
        if (upload) {
          queries.push(upload(file));
        } else {
          queries.push(createAttachment({ file, attachable }));
        }
      });
      axios.all(queries).then(() => {
        if (!hideToast) {
          Truelink.flash(
            'success',
            `${queries.length} ${queries.length == 1 ? 'file' : 'files'} successfully uploaded`,
            true,
            true,
          );
        }

        onSuccess && onSuccess();
      });
    },
    [onBeginUpload, onSuccess, attachable, upload, hideToast],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const allowedExtensionsMessage = `Supported file types: ${$tlf.domains.allowedExtensions.join(
    ', ',
  )}`;

  const dropzoneClasses = classNames('dropzone', classes.dropzoneContainer);

  return (
    <Container id="file-upload-dropzone" {...getRootProps({ className: dropzoneClasses })}>
      <input
        id="dropzone-input"
        {...getInputProps()}
        aria-label="Upload a file"
        name="file"
        type="file"
      />
      {isDragActive ? (
        <p>Drop files here...</p>
      ) : (
        <>
          <p>Drag and drop files from your computer here</p>
          <p>or</p>
          <div className="btn btn-mini normal">Select file</div>
          <br />
          <small className={classes.helperText}>{allowedExtensionsMessage}</small>
        </>
      )}
    </Container>
  );
}

AttachmentDropZone.propTypes = {
  onBeginUpload: PropTypes.func,
  onSuccess: PropTypes.func,
  upload: PropTypes.func,
  attachable: IdentifierShape,
  hideToast: PropTypes.bool,
};
