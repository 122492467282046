import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import BulkReportRequestModal from './BulkReportRequestModal';
import ButtonCell from './ButtonCell';
import { originShape } from './OriginShape';
import ReportRequestModal from './ReportRequestModal';
import RadioDropdownCollectionsShape from 'react/member/components/dashboard/reports/RadioDropdownCollectionsShape';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';

const useStyles = makeStyles({
  table: {
    marginBottom: 48,
    border: 0,
    '& thead': {
      color: '#c8c8c8',
      boxShadow: 'none',
      borderBottom: '1px solid #ddd',
      textAlign: 'left',
    },
    '& tbody tr': {
      borderBottom: '1px solid #ddd',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      alignSelf: 'center',
    },
    '& td:first-child': {
      width: '275px',
    },
    '& td:last-child': {
      textAlign: 'right',
    },
  },
});

export default function ReportSection({ data, origin, title, entityCollections }) {
  const classes = useStyles();

  const [selectedReport, setSelectedReport] = useState();
  const [reportRequestOpen, setReportRequestOpen] = useState(false);

  const closeReportRequestModal = () => {
    setReportRequestOpen(false);
  };

  const handleReportRequest = useCallback((report) => {
    setReportRequestOpen(true);
    setSelectedReport(report);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Report Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        style: {
          textAlign: 'right',
        },
      },
      {
        Header: '',
        id: 'download',
        accessor: (report) => ({ onClick: () => handleReportRequest(report), title: 'Download' }),
        Cell: ButtonCell,
      },
    ],
    [handleReportRequest],
  );

  return (
    <div>
      <SubSectionHeader style={{ borderBottom: 'none' }}>{title}</SubSectionHeader>

      <TrueLinkTable
        className={classes.table}
        columns={columns}
        data={data}
        minColumnWidth={5}
        resizable={false}
        sortable={false}
      />

      {!$tlf.flipperBulkTrustReporting && reportRequestOpen && (
        <ReportRequestModal
          entityCollections={entityCollections}
          onClose={closeReportRequestModal}
          origin={origin}
          report={selectedReport}
        />
      )}

      {$tlf.flipperBulkTrustReporting && reportRequestOpen && (
        <BulkReportRequestModal
          entityCollections={entityCollections}
          onClose={closeReportRequestModal}
          origin={origin}
          report={selectedReport}
        />
      )}
    </div>
  );
}

ReportSection.propTypes = {
  entityCollections: RadioDropdownCollectionsShape.isRequired,
  data: PropTypes.array.isRequired,
  origin: originShape,
  title: PropTypes.string.isRequired,
};
