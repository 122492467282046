import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import FormDatepickerField from 'react/shared/components/forms/form_datepicker_field';

const useStyles = makeStyles({
  dateRangeWrapper: {
    '& .react-datepicker__tab-loop': {
      display: 'inline-block',
    },
  },
});

export default function DateRange({ minDate, maxDate, startDate, endDate }) {
  const classes = useStyles();

  return (
    <div className={classes.dateRangeWrapper}>
      <p>Select time period</p>
      <Field
        component={FormDatepickerField}
        datepickerProps={{
          className: 'date_input',
          id: 'range-start-date-select',
          startDate: new Date(`${startDate}T00:00:00`),
          endDate: new Date(`${endDate}T00:00:00`),
          minDate: new Date(`${minDate}T00:00:00`),
          maxDate: new Date(`${maxDate}T00:00:00`),
          selectsStart: true,
          popperProps: {
            strategy: 'fixed',
          },
        }}
        name="startDate"
        value={startDate}
      />
      <span style={{ paddingLeft: 10, paddingRight: 10 }}>to</span>
      <Field
        component={FormDatepickerField}
        datepickerProps={{
          className: 'date_input',
          id: 'range-end-date-select',
          startDate: new Date(`${startDate}T00:00:00`),
          endDate: new Date(`${endDate}T00:00:00`),
          minDate: new Date(`${startDate}T00:00:00`),
          maxDate: new Date(`${maxDate}T00:00:00`),
          selectsEnd: true,
          popperProps: {
            strategy: 'fixed',
          },
        }}
        name="endDate"
        value={endDate}
      />
    </div>
  );
}

DateRange.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};
