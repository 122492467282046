import PropTypes from 'prop-types';
import React from 'react';
import _find from 'underscore/modules/find';
import BeneficiaryCardReportsSection from './BeneficiaryCardReportsSection';
import SpendingSettingsSdkContainer from 'react/member/card/components/spending_monitor_tab/SpendingSettingsSdkContainer';
import TransactionList from 'react/shared/card/components/transaction_list/TransactionList';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import { isRepPayee } from 'react/shared/utils/account_card_program';
function BeneficiaryDashboardCardTab(props) {
  if (!props.account) {
    return <div />;
  }

  const { account } = props;

  // card_balance loads from javascripts in client/dashboard/home.html.haml
  return (
    <div>
      <div className="widescreen-inner-container">
        <h3 className="current-balance-header">
          <div className="pull-right">Status: {account.dashboard_status}</div>
          Current Balance: &nbsp;
          <div className="card_balance" />
          &nbsp;
          <small>
            <br />
            card ending in {account.card_last_four}
          </small>
        </h3>
        <ul id="cardholder_home_menu">
          <li>
            <a href="#recent_transactions">Recent Transactions</a>
          </li>
          <li>
            <a href="#transaction_report">Transaction Report</a>
          </li>
          <li>
            <a href="#monthly_statements">Monthly Statements</a>
          </li>
          <li>
            <a href="#card_settings">Card Settings</a>
          </li>
        </ul>
      </div>
      <div>
        <SubSectionHeader id="recent_transactions">Recent Transactions</SubSectionHeader>
        <TransactionList
          accountId={account.slug}
          cardCount={account.card_count}
          hideClosedCardTransactions={false}
          hideClosedCardTransactionsEnabled
          isCardholderView
          isRepPayee={isRepPayee(account.card_program_number)}
          showNotes={account.show_transaction_notes}
          showQDE={account.show_QDE}
          showReceipts={props.showReceipts}
          supportEmail={props.supportEmail}
        />
      </div>
      <BeneficiaryCardReportsSection
        account={_find(
          // competing notions of account serialization
          props.trustBeneficiary.accounts,
          (acct) => acct.id === props.account.account_id,
        )}
      />
      {props.displayCardSettings && (
        <div>
          <SubSectionHeader id="card_settings">Card Settings</SubSectionHeader>
          <SpendingSettingsSdkContainer accountId={`${props.account.account_id}`} canEdit={false} />
        </div>
      )}
    </div>
  );
}

export default BeneficiaryDashboardCardTab;

BeneficiaryDashboardCardTab.propTypes = {
  trustBeneficiary: PropTypes.object.isRequired,
  account: PropTypes.object,
  displayCardSettings: PropTypes.bool,
  showReceipts: PropTypes.bool,
  supportEmail: PropTypes.string.isRequired,
};
