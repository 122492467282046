import axios from 'axios';
import { entityTypes } from 'react/member/components/dashboard/reports/Constants';

export default {
  createReportRequest(params) {
    const url = RailsRoutes.api_v1_report_generation_requests_path();
    return axios.post(url, params);
  },
  checkReportStatus(requestId) {
    const url = RailsRoutes.api_v1_report_generation_request_path(requestId);
    return axios.get(url, { responseType: 'json' });
  },
  downloadReport(requestId) {
    const path = RailsRoutes.report_api_v1_report_generation_request_path(requestId);
    window.location.assign(path);
  },
  getEntityDateRange(type, entitySlug) {
    let route;
    switch (type) {
      case entityTypes.trust:
      case entityTypes.standaloneTrust:
      case entityTypes.pooledTrust:
        route = RailsRoutes.dashboard_trust_path;
        break;
      case entityTypes.trustBeneficiary:
        route = RailsRoutes.dashboard_client_path;
        break;
      case entityTypes.portfolio:
        route = RailsRoutes.dashboard_portfolio_path;
        break;
    }

    const url = route(entitySlug, { format: 'json' });
    return axios.get(url);
  },
};
