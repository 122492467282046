import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function TrueLinkIcon({ ariaLabel, className, icon, size, style, onClick }) {
  const iconComponent = (
    <i
      aria-label={ariaLabel}
      className={classNames(`fa fa-${icon}`, { [className]: className })}
      style={style}
      title={icon}
    />
  );
  if (onClick) {
    return (
      <IconButton onClick={onClick} size={size || 'large'}>
        {iconComponent}
      </IconButton>
    );
  }
  return iconComponent;
}

TrueLinkIcon.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
};
