import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PALETTE from 'react/shared/theme/palette';

export default function ImageViewer({ url }) {
  const [rotation, setRotation] = useState(0);

  const rotateImage = () => {
    setRotation((prev) => (prev - 90) % 360);
  };

  const actionButtons = (
    <Box
      display="flex"
      gap={2}
      sx={{ color: PALETTE.white, position: 'absolute', bottom: '10px', left: '32px' }}
    >
      <Button
        onClick={rotateImage}
        startIcon={<RefreshIcon sx={{ fill: PALETTE.white, transform: 'scaleX(-1)' }} />}
        sx={{
          '&.MuiButton-root': {
            color: PALETTE.white,
          },
        }}
      >
        Rotate
      </Button>
    </Box>
  );

  return (
    <div className="image-viewer" style={{ color: 'white' }}>
      <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
        <Box
          alt="Image Preview"
          component="img"
          src={url}
          sx={{
            transform: `rotate(${rotation}deg)`,
            transition: 'transform 0.3s ease-in-out',
            maxWidth: '100%',
            maxHeight: '70vh',
          }}
        />
      </Box>

      {actionButtons}
    </div>
  );
}

ImageViewer.propTypes = {
  url: PropTypes.string.isRequired,
};
