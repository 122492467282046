import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';
import PALETTE from 'react/shared/theme/palette';

export default function BudgetRemainderNotification({
  spendingPeriod,
  currentBalance,
  spendingLimit,
}) {
  const budgetSpent = currentBalance <= 0;
  const borderColor = budgetSpent ? PALETTE.red : PALETTE.blue;
  const color = budgetSpent ? 'error' : 'blue';

  const budgetSpentText = (
    <Box sx={{ color: PALETTE.black }}>
      <b>
        You have reached your {spendingPeriod} budget of ${spendingLimit}.
      </b>{' '}
      Contact your administrator with any questions.
    </Box>
  );

  const budgetAvailableText = (
    <Box sx={{ color: PALETTE.black }}>
      You have <b>${currentBalance}</b> available to spend of your ${spendingLimit} {spendingPeriod}{' '}
      budget.
    </Box>
  );

  const text = budgetSpent ? budgetSpentText : budgetAvailableText;

  return (
    <Box className="budget-remainder-notification" sx={{ mb: 2 }}>
      <TrueLinkChip
        color={color}
        icon={<InfoIcon color={borderColor} />}
        label={text}
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          border: `1px solid ${borderColor}`,
          borderRadius: '6px',
          m: 0,
          p: '24px 16px',
        }}
      />
    </Box>
  );
}

BudgetRemainderNotification.propTypes = {
  spendingPeriod: PropTypes.string.isRequired,
  currentBalance: PropTypes.number.isRequired,
  spendingLimit: PropTypes.number.isRequired,
};
