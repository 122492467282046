import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import JotFormReact from 'jotform-react';
import PropTypes from 'prop-types';
import React from 'react';

export default function JotFormSurveyModal({ formUrl, onSubmit, onClose }) {
  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open>
      <Box sx={{ height: 660 }}>
        <IconButton
          aria-label="close"
          id="cta-close-survey-modal"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <JotFormReact
          allowFullScreen
          allowTransparency
          autoResize
          formURL={formUrl}
          onSubmit={onSubmit}
        />
      </Box>
    </Dialog>
  );
}

JotFormSurveyModal.propTypes = {
  formUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
