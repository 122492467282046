import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import useAutocomplete from '@mui/material/useAutocomplete';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react';
import AutocompleteOption from './AutocompleteOption';
import ManualEntryAddressInput from './ManualEntryAddressInput';
import { isMapsAvailable, getAutoCompleteResults, getPlaceDetails } from './MapsApi';
import { translatePlaceToAddress } from './MapsService';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';
import { grey } from 'react/shared/theme/palette';
import addressShape from 'react/signups/card/shapes/AddressShape';

const useStyles = makeStyles({
  listItem: {
    '&[data-focus="true"]': {
      backgroundColor: grey,
    },
  },
});

export default function TrueLinkAddressInput({
  required,
  labelPrefix,
  setFieldTouched,
  onChange,
  values,
  street1Adornment,
}) {
  const classes = useStyles();
  const startingOptions = useMemo(() => {
    const { street1, city, state } = values.address;
    if (street1) {
      return [{ description: `${street1}, ${city}, ${state}`, types: [] }];
    }
    return [];
  }, [values.address]);
  const [autocompleteValue, setAutocompleteValue] = useState(startingOptions[0] || null);
  const [options, setOptions] = useState(startingOptions);
  const [manualEntry, setManualEntry] = useState(false);
  const liveSearching = useRef(null); // Make a ref and give it the count
  const manualEntryText = 'Manual Entry';
  const anchorEl = useRef(null);

  useEffect(() => {
    setManualEntry(!isMapsAvailable());
  }, []);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      options,
      value: autocompleteValue,
      getOptionLabel: (option) => option?.description || '',
      filterOptions: (options) => [
        ...options.filter((o) => !o.types.includes('route')),
        { description: manualEntryText },
      ],
      onChange: (option, value) => {
        if (!value) {
          return;
        }
        if (value.description === manualEntryText) {
          setManualEntry(true);
        } else {
          setAutocompleteValue(value);
          getPlaceDetails(value.place_id).then((result) => {
            onChange({
              ...values,
              address: {
                ...values.address,
                ...translatePlaceToAddress(result),
              },
            });
          });
        }
      },
    });

  const getPredictions = useCallback((predictionText) => {
    getAutoCompleteResults(predictionText).then((result) => {
      if (liveSearching.current !== predictionText) {
        getPredictions(liveSearching.current);
      } else {
        liveSearching.current = null;
        setAutocompleteValue(null);
        setOptions(result.predictions);
      }
    });
  }, []);

  const handleTextChange = useCallback(
    (e) => {
      const inputText = e.target.value;
      if (!inputText) {
        return;
      }
      if (!isMapsAvailable()) {
        onChange({
          ...values,
          address: {
            ...values.address,
            street1: inputText,
          },
        });
        return;
      }
      if (liveSearching.current === null) {
        getPredictions(inputText);
      }
      liveSearching.current = inputText;
    },
    [getPredictions, onChange, values],
  );

  const handleFieldChange = useCallback(
    (e) => {
      const field = e.target.name.split('address.')[1];
      onChange({ ...values, address: { ...values.address, [field]: e.target.value } });
    },
    [onChange, values],
  );

  return (
    <div>
      {manualEntry && (
        <ManualEntryAddressInput
          labelPrefix={labelPrefix}
          onChange={handleFieldChange}
          setFieldTouched={setFieldTouched}
          street1Adornment={street1Adornment}
          values={values}
        />
      )}
      {!manualEntry && (
        <div>
          <div ref={anchorEl} {...getRootProps()}>
            <TrueLinkTextInput
              disabled={manualEntry}
              inputProps={{ ...getInputProps(), autoComplete: 'none' }}
              labelAdornment={street1Adornment}
              labelText={labelPrefix ? `${labelPrefix} address line 1` : 'Address line 1'}
              name="address.street1"
              onChange={handleTextChange}
              placeholder="Street address"
              required={required}
              setFieldTouched={setFieldTouched}
            />
            {!manualEntry && groupedOptions?.length && anchorEl.current ? (
              <Popper
                anchorEl={anchorEl.current}
                className={classes.popper}
                open
                placement="bottom-start"
                style={{
                  width: anchorEl ? anchorEl.clientWidth : null,
                }}
              >
                <Paper>
                  <List {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                      <React.Fragment key={index}>
                        {!!index && <Divider />}
                        <ListItem
                          {...getOptionProps({ option, index })}
                          className={classes.listItem}
                        >
                          <AutocompleteOption
                            isManualEntry={option?.description === manualEntryText}
                            key={index}
                            option={option}
                          />
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              </Popper>
            ) : null}
          </div>
          {!manualEntry && (
            <TrueLinkTextInput
              labelText={labelPrefix ? `${labelPrefix} address line 2` : 'Address line 2'}
              name="address.street2"
              onChange={handleFieldChange}
              placeholder="Suite or apartment number"
              required
              setFieldTouched={setFieldTouched}
              value={values.address.street2}
            />
          )}
        </div>
      )}
    </div>
  );
}

TrueLinkAddressInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  labelPrefix: PropTypes.string,
  street1Adornment: PropTypes.node,
  value: PropTypes.string,
  values: PropTypes.shape({
    address: addressShape.isRequired,
  }).isRequired,
};
