/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
// import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: PALETTE.red,
    margin: `${theme.spacing(2)} auto`,
  },
  inputDiv: {
    '& input': {
      padding: theme.spacing(2),
      margin: 0,
      height: 'auto',
      '&::placeholder': {
        fontStyle: 'normal',
      },
    },
  },
  listContainer: {
    maxHeight: 225,
    overflow: 'auto',
  },
}));

export default function ClientFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState(filterValue || []);
  const [name, setName] = useState('');
  const [foundClients, setFoundClients] = useState([]);
  const containerRef = useRef(null);
  const showClientChips = clients.length > 0;
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    const result = [...options.values()].filter((option) => option !== null);
    setFoundClients(result);
    return result;
  }, [id, preFilteredRows]);

  useEffect(() => {
    if (filterValue === undefined) {
      setClients([]);
    }
  }, [filterValue]);

  const handleOpenPopper = () => setOpen((prev) => !prev);

  const handleClientApply = () => {
    setFilter(clients && clients.length ? clients : undefined);
    setOpen(false);
  };

  const handleClearClients = () => {
    setName('');
    setClients([]);
    setFilter();
    setFoundClients(options);
  };

  const handleClientDelete = (selectedClient) => {
    const updatedClients = clients.filter((ele) => ele !== selectedClient);
    setClients(updatedClients);
    setFilter(updatedClients);
  };

  const handleToggle = (value) => () => {
    const currentIndex = clients.indexOf(value);
    const newChecked = [...clients];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setClients(newChecked);
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = options.filter((option) =>
        option.toLowerCase().startsWith(keyword.toLowerCase()),
      );
      setFoundClients(results);
    } else {
      setFoundClients(options);
    }

    setName(keyword);
  };

  const handleClickAway = () => {
    handleClearClients();
    setOpen(false);
  };

  return (
    <>
      <div id="client-popper-container" ref={containerRef}>
        {showClientChips && !open ? (
          <Paper
            component={'ul'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              listStyle: 'none',
              marginRight: '10px',
              marginTop: '9px',
              marginLeft: '10px',
              alignItems: 'center',
              height: '44px',
            }}
          >
            <ListItem style={{ paddingRight: 0 }}>
              {clients.map((client) => (
                <Chip
                  key={client}
                  label={client}
                  onDelete={() => handleClientDelete(client)}
                  style={{
                    color: PALETTE.emerald,
                    borderRadius: '6px',
                    backgroundColor: PALETTE.emeraldLight,
                  }}
                />
              ))}
            </ListItem>
            <TrueLinkButton
              onClick={handleOpenPopper}
              size="small"
              style={{ border: 'none', margin: 'auto 0', padding: '7px' }}
              variant="neutralOutline"
            >
              <TrueLinkIcon icon="caret-down" />
            </TrueLinkButton>
          </Paper>
        ) : (
          <TrueLinkButton
            onClick={handleOpenPopper}
            size="small"
            style={{
              height: '42px',
              marginLeft: '5px',
              marginRight: '10px',
              padding: '8px',
            }}
            variant="neutralOutline"
          >
            Client
            <TrueLinkIcon icon="caret-down" style={{ marginLeft: '5px' }} />
          </TrueLinkButton>
        )}
      </div>
      <Popper
        anchorEl={containerRef.current}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundariesElement: 'viewport',
            },
          },
        ]}
        open={open}
        placement="bottom"
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            style={{
              width: 'auto',
              height: 'auto',
              padding: '15px',
            }}
          >
            <TextField
              className={classes.inputDiv}
              onChange={filter}
              placeholder="Client"
              value={name}
            />
            <List className={classes.listContainer}>
              {foundClients.length > 0 ? (
                foundClients.sort().map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem dense key={value} onClick={handleToggle(value)} role={undefined}>
                      <ListItemIcon>
                        <Checkbox
                          checked={clients.includes(value)}
                          disableRipple
                          edge="start"
                          inputProps={{ 'aria-labelledby': labelId }}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value} />
                    </ListItem>
                  );
                })
              ) : (
                <Typography>No results</Typography>
              )}
            </List>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <TrueLinkButton onClick={handleClientApply} size="small" variant="primary">
                Apply
              </TrueLinkButton>
              <TrueLinkLink onClick={handleClearClients} size="small" variant="error">
                Clear
              </TrueLinkLink>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
