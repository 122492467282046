import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import CardTransactionAttachments from './CardTransactionAttachments';
import SaveOrCancelButtons from './SaveOrCancelButtons';
import TransactionNote from './TransactionNote';
import CardTransactionShape from 'react/member/card/shapes/CardTransactionShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { capitalize } from 'react/shared/utils/Strings';

export default function TransactionRowDetails({
  cardCount,
  detailKey,
  hideClosedCardTransactionsEnabled,
  isCardholderView,
  showNotes,
  showQDE,
  showReceipts,
  supportEmail,
  transaction,
  ruleSetId,
}) {
  const getQDEValue = () => transaction.attributes.qde;

  const [merchantSettingSelected, setMerchantSettingSelected] = useState(false);
  const [ruleType, setRuleType] = useState('do_nothing');
  const [maxAllowAmount, setMaxAllowAmount] = useState('');
  const [noLimitChecked, setNoLimitChecked] = useState(true);
  const [showSettings, setShowSettings] = useState(true);
  const [showQDEbuttons, setShowQDEbuttons] = useState(false);
  const [QDEValue, setQDEValue] = useState(getQDEValue());

  const canEdit = () => transaction.meta.permissions.edit;

  const hideQualifiedDisabilityExpense = () => !transaction.id || !showQDE;

  const hideNotes = () => !transaction.id || !showNotes;

  const hideReceipts = () => !transaction.id || !showReceipts;

  const hideQDEButtons = () => {
    setShowQDEbuttons(false);
  };

  const handleSelectChange = (e) => {
    setRuleType(e.target.value);
    setMerchantSettingSelected(e.target.value !== '');
  };

  const handleMaxAllowAmount = (e) => {
    setMaxAllowAmount(e.target.value);
  };

  const setTransactionLimitRadio = (e) => {
    const noLimitChecked = e.target.value === 'true';

    if (noLimitChecked) {
      setMaxAllowAmount('');
    }
    setNoLimitChecked(noLimitChecked);
  };

  const ajaxCall = async (url, data, successMessage, callback, type) => {
    Truelink.flash('info', 'Sending...', false);
    try {
      const response = await axios({
        url,
        method: type,
        data,
        dataType: 'json',
      });

      Truelink.flashClear();
      if (successMessage) Truelink.flash('success', successMessage, false);
      if (callback) {
        const responseData = response.data;
        callback(responseData);
      }
      return response.data;
    } catch (error) {
      Truelink.flashClear();
      Truelink.flash('error', 'An error occurred while saving. Please try again.', false);
      throw error;
    }
  };

  const saveRulesetV2 = (ruleType, maxAllowAmount) => {
    const { merchantName } = transaction.attributes;
    let action = 'block_all';
    let maxAllowAmountValue = null;

    if (ruleType === 'never_block') {
      action = 'allow_all';
    }

    if (maxAllowAmount) {
      action = 'enforce_limit';
      maxAllowAmountValue = maxAllowAmount;
    }

    const successMessage = `Your settings for ${merchantName} have been updated. To change other settings please visit your Spending Settings.`;
    const rule = {
      action,
      limit: maxAllowAmountValue
        ? {
            amount: Number.parseFloat(maxAllowAmountValue),
            currency: 'USD',
          }
        : null,
      name: merchantName,
    };

    const url = `/api/v2/merchant_exceptions/`;
    const params = {
      data: {
        type: 'merchant_exception',
        attributes: rule,
        relationships: {
          spendingSettings: {
            data: {
              type: 'spending_settings',
              id: ruleSetId,
            },
          },
        },
      },
    };

    axios.post(url, params).then(() => {
      Truelink.flashClear();
      Truelink.flash('success', successMessage, false);
      setShowSettings(false);
    });
  };

  const updateQDE = (QdeValue, onHideQDEButtons) => {
    const { id: cardTransactionId } = transaction;
    const url = RailsRoutes.dashboard_card_transaction_path(cardTransactionId, { format: 'json' });

    const params = {
      card_transaction: {
        qde: QdeValue,
      },
    };
    const successMessage = 'The QDE setting has been saved!';
    ajaxCall(url, params, successMessage, onHideQDEButtons, 'PUT');
  };

  const renderCardInfo = () => {
    if (cardCount > 1) {
      const cardInfo = transaction?.meta?.card.attributes;
      const expiryDate = cardInfo.expiryDate
        ? moment(cardInfo.expiryDate).format('MM/DD/YYYY')
        : 'This card has expired.';

      return (
        <div className="new-form__section new-form--compact new-form__section--subheader">
          <div className="new-form-field">
            <div className="new-form__label">Card information:</div>
          </div>
          <div className="new-form-field">
            <div className="new-form__label" style={{ width: '86px' }}>
              Ending in:
            </div>
            <div className="new-form__data" style={{ paddingRight: '10px' }}>
              {cardInfo.lastFour}
            </div>
          </div>
          <div className="new-form-field">
            <div className="new-form__label" style={{ width: '60px' }}>
              Expiry:
            </div>
            <div className="new-form__data" style={{ paddingRight: '10px' }}>
              {expiryDate}
            </div>
          </div>
        </div>
      );
    }
  };

  const showHideClosedCardTransactionsTooltip = useCallback(() => {
    window.scrollTo(window.pageXOffset, 0);
    $('#hide-closed-card-transactions-tooltip').tooltip('show');
  }, []);

  const isClosedCardTransaction = useMemo(
    () =>
      cardCount > 1 &&
      transaction.attributes.block?.reason === 'AccountClosed' &&
      hideClosedCardTransactionsEnabled,
    [cardCount, transaction.attributes.block?.reason, hideClosedCardTransactionsEnabled],
  );

  const renderExplanation = (showSettings) => {
    const boxStyle = showSettings
      ? {
          boxShadow: '0px 1px 2px #ccc',
          padding: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
        }
      : { padding: '10px' };

    const explanationStyle =
      transaction.attributes.status === 'blocked'
        ? { backgroundColor: 'rgba(255, 106, 106, 0.33)', borderRadius: '5px' }
        : { background: 'none' };

    let explanation;
    if (!transaction.attributes.includeMerchantSetting) {
      if (transaction.attributes.explanation) {
        explanation = <div>{capitalize(transaction.attributes.explanation)}</div>;
      } else {
        explanation = (
          <div>Please feel free to email us at {supportEmail} if you have any questions.</div>
        );
      }
    } else if (transaction.attributes.explanation) {
      if (isClosedCardTransaction) {
        explanation = (
          <div>
            <b>{capitalize(transaction.attributes.explanation)}</b> This transaction was attempted
            on a card that was closed. If you no longer wish to see transactions on closed cards,
            check the box at the top of the page or{' '}
            <a
              className="cursor-pointer"
              onClick={showHideClosedCardTransactionsTooltip}
              onKeyUp={showHideClosedCardTransactionsTooltip}
              role="link"
              tabIndex="0"
            >
              click here
            </a>
            .
          </div>
        );
      } else if (isCardholderView && transaction.attributes.status == 'flagged') {
        explanation = (
          <div>
            This is a {transaction.attributes.humanReadableType}. Please feel free to email us at{' '}
            {supportEmail} if you have any questions.
          </div>
        );
      } else {
        explanation = <div>{capitalize(transaction.attributes.explanation)}</div>;
      }
    } else if (transaction.attributes.humanReadableType) {
      explanation = (
        <div>
          This is a {transaction.attributes.humanReadableType}. Please feel free to email us at{' '}
          {supportEmail} if you have any questions.
        </div>
      );
    } else {
      return;
    }

    return (
      <div className="new-form__section" style={boxStyle}>
        <div className="new-form--compact new-form__section--subheader" style={explanationStyle}>
          <div className="new-form-field">
            <div className="new-form__label">Explanation:</div>
            <div className="new-form__data" style={{ paddingRight: 10 }}>
              {explanation}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSettings = (showSettings, ruleType, noLimitChecked) => {
    if (
      isCardholderView ||
      !canEdit() ||
      !showSettings ||
      !transaction.attributes.includeMerchantSetting
    )
      return;

    let amountInput;
    if (ruleType === 'never_block') {
      amountInput = (
        <div className="new-form-field" style={{ marginTop: 7 }}>
          <div className="new-form__label">Allow transactions:</div>
          <div className="new-form__data flex flex--space-between">
            <div className="radio-group radio-group--inline">
              <label
                className="margin-bottom-0"
                htmlFor={`allowTransactionsNoLimit${transaction.id}`}
              >
                <input
                  checked={noLimitChecked}
                  id={`allowTransactionsNoLimit${transaction.id}`}
                  name="budget_item_type"
                  onChange={setTransactionLimitRadio}
                  type="radio"
                  value="true"
                />
                Of any amount
              </label>
              <label
                className="margin-bottom-0"
                htmlFor={`allowTransactionsDollarLimit${transaction.id}`}
              >
                <input
                  checked={!noLimitChecked}
                  id={`allowTransactionsDollarLimit${transaction.id}`}
                  name="budget_item_type"
                  onChange={setTransactionLimitRadio}
                  type="radio"
                  value="false"
                />
                Up to&nbsp;&nbsp;
                <div className="money-input-wrapper">
                  <input
                    disabled={noLimitChecked}
                    onChange={handleMaxAllowAmount}
                    style={{ width: 100, marginBottom: 0 }}
                    type="number"
                  />
                </div>
              </label>
            </div>
            <TrueLinkButton
              disabled={!merchantSettingSelected}
              onClick={() => saveRulesetV2(ruleType, maxAllowAmount)}
              variant="primary"
            >
              Save Merchant Setting
            </TrueLinkButton>
          </div>
        </div>
      );
    }

    return (
      <div
        className="new-form__section new-form--compact relative"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
      >
        <div className="new-form-field">
          <div className="new-form__label">Merchant:</div>
          <div className="new-form__data">{transaction.attributes.merchantName}</div>
        </div>
        <div className="new-form-field">
          <div className="new-form__label">
            <label htmlFor="transaction_setting">Transaction setting:</label>
          </div>
          <div className="new-form__data flex flex--space-between">
            <select
              defaultValue=""
              id="transaction_setting"
              onChange={handleSelectChange}
              required
              style={{ marginBottom: 0 }}
            >
              <option disabled hidden value="">
                Select a new setting
              </option>
              <option value="" />
              <option value="never_block">Always allow</option>
              <option value="block">Always block</option>
            </select>
            {!amountInput && (
              <TrueLinkButton
                disabled={!merchantSettingSelected}
                onClick={saveRulesetV2}
                variant="primary"
              >
                Save Merchant Setting
              </TrueLinkButton>
            )}
          </div>
        </div>

        {amountInput}
      </div>
    );
  };

  const updateQDEState = (e) => {
    setQDEValue(e.target.value === 'true');
    setShowQDEbuttons(true);
  };

  const resetQDE = () => {
    setQDEValue(getQDEValue());
    setShowQDEbuttons(false);
  };

  const getQDEDisplayValue = (qdeValue) => {
    if (qdeValue === true) {
      return 'Yes';
    }
    if (qdeValue === false) {
      return 'No';
    }
    return 'Unset';
  };

  const renderQDE = (QDEValue, showQDEbuttons) => {
    if (hideQualifiedDisabilityExpense()) return;

    const yesId = `QDEYes${detailKey}`;
    const noId = `QDENo${detailKey}`;

    if (!canEdit()) {
      return (
        <div className="new-form__section new-form--compact new-form__section--inset new-form__section--last relative">
          <div className="new-form-field qualified-disability-expense">
            <div className="new-form__label">Qualified Disability Expense:</div>
            <div className="new-form__data">{getQDEDisplayValue(getQDEValue())}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="new-form__section new-form--compact new-form__section--inset new-form__section--last relative">
        <div className="new-form-field qualified-disability-expense">
          <div className="new-form__label">Qualified Disability Expense:</div>
          <div className="new-form__data flex flex--space-between">
            <div className="radio-group radio-group--inline">
              <label className="margin-bottom-0" htmlFor={yesId}>
                <input
                  checked={QDEValue == true}
                  id={yesId}
                  onChange={updateQDEState}
                  type="radio"
                  value="true"
                />{' '}
                Yes
              </label>
              <label className="margin-bottom-0" htmlFor={noId}>
                <input
                  checked={QDEValue == false}
                  id={noId}
                  onChange={updateQDEState}
                  type="radio"
                  value="false"
                />{' '}
                No
              </label>
            </div>
            {showQDEbuttons && (
              <SaveOrCancelButtons
                onCancel={resetQDE}
                onSave={() => updateQDE(QDEValue, hideQDEButtons)}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAttachments = () => {
    if (hideReceipts()) return;

    return (
      <CardTransactionAttachments
        canEdit={canEdit()}
        cardTransactionId={transaction.id}
        isCardholderView={isCardholderView}
        style="link"
      />
    );
  };

  const renderNotes = () => {
    if (hideNotes()) return;

    return <TransactionNote canEdit={canEdit()} cardTransactionId={transaction.id} />;
  };

  const getDialogId = (transaction) => {
    switch (transaction.attributes.humanReadableType) {
      case 'charge':
        if (!transaction.attributes.includeMerchantSetting) {
          if (transaction.attributes.explanation) {
            return 'transaction_p3_dialog_prototype';
          }
          return 'transaction_other_dialog_prototype';
        }
        return 'transaction_charge_dialog_prototype';

      case 'withdrawal':
        if (transaction.attributes.explanation) {
          return 'transaction_p3_dialog_prototype';
        }
        return 'transaction_other_dialog_prototype';

      default:
        return 'transaction_other_dialog_prototype';
    }
  };

  const dialogId = getDialogId(transaction);

  let slidedownContent;
  switch (dialogId) {
    case 'transaction_p3_dialog_prototype': {
      slidedownContent = (
        <div className="table-slidedown">
          {renderCardInfo()}
          {renderExplanation(showSettings)}
          {renderAttachments()}
        </div>
      );

      break;
    }
    case 'transaction_other_dialog_prototype': {
      slidedownContent = (
        <div className="table-slidedown">
          {renderCardInfo()}
          <div className="new-form__section new-form--compact new-form__section--last relative">
            <div style={{ margin: '10px 0' }}>
              This is a {transaction.attributes.humanReadableType}. Please feel free to email us at{' '}
              {supportEmail} if you have any questions.
            </div>
          </div>
          {renderAttachments()}
        </div>
      );

      break;
    }
    case 'transaction_charge_dialog_prototype': {
      slidedownContent = (
        <div className="table-slidedown">
          {renderCardInfo()}
          {renderExplanation(showSettings)}
          {renderSettings(showSettings, ruleType, noLimitChecked)}
          {renderAttachments()}
          {renderNotes()}
          {renderQDE(QDEValue, showQDEbuttons)}
        </div>
      );

      break;
    }
    // No default
  }

  const colspan = showReceipts ? 7 : 6;

  return (
    <tr className="table-detail-row" key={detailKey}>
      <td className="nopadding transaction-row-new" colSpan={colspan}>
        {slidedownContent}
      </td>
    </tr>
  );
}

TransactionRowDetails.propTypes = {
  cardCount: PropTypes.number.isRequired,
  detailKey: PropTypes.number.isRequired,
  hideClosedCardTransactionsEnabled: PropTypes.bool.isRequired,
  isCardholderView: PropTypes.bool,
  showNotes: PropTypes.bool.isRequired,
  showQDE: PropTypes.bool.isRequired,
  showReceipts: PropTypes.bool.isRequired,
  supportEmail: PropTypes.string.isRequired,
  transaction: CardTransactionShape.isRequired,
  ruleSetId: PropTypes.string,
};
