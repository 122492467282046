import PropTypes from 'prop-types';
import React from 'react';
import DisbursementsIndex from './DisbursementsIndex';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardBudgetItemForm from 'react/member/components/dashboard/budget_items/DashboardBudgetItemForm';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardBudgetItemStore from 'react/member/stores/DashboardBudgetItemStore';
import DashboardDraftDisbursementStore from 'react/member/stores/DashboardDraftDisbursementStore';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPendingDisbursements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisbursementForm: false,
      payeesLoaded: false,
      resumedDraftDisbursementId: undefined,
      currentOrgSlug: this.props.orgSlug,
    };

    this._isMounted = false;

    bindAll(this);
  }

  componentDidMount() {
    this._isMounted = true;

    DashboardPayeeStore.on('payees.clear', this.onChangeBeneficiaryPayee);
    DashboardPayeeStore.on('payees.fetch', this.onChangeBeneficiaryPayee);
    DashboardBudgetItemStore.on('budgetItem.create', this.callAfterCreate);
    DashboardDraftDisbursementStore.on('draftDisbursement.update', this.callAfterCreate);
    DashboardDraftDisbursementStore.on(
      'draftDisbursement.fetch',
      this.resumeEditingDraftDisbursement,
    );
  }

  componentWillUnmount() {
    this._isMounted = false;

    DashboardPayeeStore.off('payees.clear', this.onChangeBeneficiaryPayee);
    DashboardPayeeStore.off('payees.fetch', this.onChangeBeneficiaryPayee);
    DashboardBudgetItemStore.off('budgetItem.create', this.callAfterCreate);
    DashboardDraftDisbursementStore.off('draftDisbursement.update', this.callAfterCreate);
  }

  onChangeBeneficiaryPayee(payees, bool) {
    this.setState({
      payees,
      payeesLoaded: bool,
    });
  }

  callAfterCreate(disbursement) {
    if (disbursement.status === 'Draft') {
      Truelink.flash('success', 'Draft disbursement created!');
    } else {
      Truelink.flash('success', 'New disbursement created!');
    }
    this.toggleDisbursementForm(null);
  }

  resumeEditingDraftDisbursement(data) {
    this.setState({
      currentOrgSlug: data.organization_slug,
      showDisbursementForm: true,
    });
  }

  handleResumeDraft(draftDisbursementId) {
    if (!this.props.orgSlug) {
      DashboardActions.fetchDraftDisbursementById(draftDisbursementId);
      this.setState({
        resumedDraftDisbursementId: draftDisbursementId,
        budgetItemType: 'one_time',
      });
    } else {
      this.setState({
        showDisbursementForm: true,
        resumedDraftDisbursementId: draftDisbursementId,
        budgetItemType: 'one_time',
      });
    }
  }

  form() {
    const orgSlug = this.props.organization
      ? this.props.organization.slug
      : this.props.orgSlug || this.state.currentOrgSlug;

    return (
      <DashboardBudgetItemForm
        actionPath={this.actionPath}
        budgetItemType={this.state.budgetItemType}
        currentClientSlug={this.state.currentClientSlug}
        displayedPaymentMethods={this.props.displayedPaymentMethods}
        initialClient={this.props.initialClient}
        organizationSlug={orgSlug}
        payees={this.state.payees || []}
        payeesLoaded={this.state.payeesLoaded || false}
        resumedDraftDisbursementId={this.state.resumedDraftDisbursementId}
        setBudgetItemType={this.setBudgetItemType}
        threeMonthInfo={this.state.threeMonthInfo}
        toggleBudgetItemForm={this.toggleDisbursementForm}
        userRoles={this.props.userRoles}
      />
    );
  }

  toggleDisbursementForm(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }

    return this.setState({
      showDisbursementForm: !this.state.showDisbursementForm,
      budgetItemType: 'one_time',
      resumedDraftDisbursementId: null,
    });
  }

  setBudgetItemType(budgetItemType) {
    this.setState({ budgetItemType });
  }

  actionPath(disbursementType, isDraft = false) {
    if (disbursementType === 'recurring') {
      return RailsRoutes.dashboard_recurring_disbursements_path({ format: 'json' });
    } else if (disbursementType === 'one_time' && isDraft) {
      return RailsRoutes.dashboard_draft_disbursements_path({ format: 'json' });
    }

    return RailsRoutes.dashboard_disbursements_path({ format: 'json' });
  }

  render() {
    return this.state.showDisbursementForm ? (
      this.form()
    ) : (
      <DisbursementsIndex
        onResumeDraft={this.handleResumeDraft}
        orgSlug={this.props.orgSlug}
        renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
        showAdditionalApprovalTable={this.props.showAdditionalApprovalTable}
        showDisbursementNotificationBubble={this.props.showNotificationIcon}
        showDraftDisbursementNotificationBubble={this.props.showDraftNotificationIcon}
        showOnHoldDisbursementNotificationBubble={this.props.showOnHoldNotificationIcon}
        showRecurringDisbursementNotificationBubble={this.props.showRecurringNotificationIcon}
        showSendDisbursementBackToPending={this.props.showSendDisbursementBackToPending}
        userRoles={this.props.userRoles}
      />
    );
  }
}

DashboardPendingDisbursements.propTypes = {
  displayedPaymentMethods: PropTypes.array,
  initialClient: PropTypes.object,
  orgSlug: PropTypes.string,
  showAdditionalApprovalTable: PropTypes.bool.isRequired,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  organization: PropTypes.object,
  userRoles: userRoleShape.isRequired,
  showNotificationIcon: PropTypes.bool.isRequired,
  showRecurringNotificationIcon: PropTypes.bool.isRequired,
  showOnHoldNotificationIcon: PropTypes.bool.isRequired,
  showDraftNotificationIcon: PropTypes.bool.isRequired,
};
