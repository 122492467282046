import PropTypes from 'prop-types';
import React from 'react';
import {
  originTypes,
  entityTypes,
  reportTargetTypeOptions,
} from 'react/member/components/dashboard/reports/Constants';
import RadioDropdownCollectionsShape from 'react/member/components/dashboard/reports/RadioDropdownCollectionsShape';
import { originShape } from 'react/member/components/dashboard/reports/reports_section/OriginShape';
import TrueLinkSelect from 'react/shared/components/true_link/main/form/TrueLinkSelect';

export default function EntityTypeDropdown({
  entityCollections,
  origin,
  reportSupportsSearchBy,
  value,
  onChange,
}) {
  // buildOptions: Builds and returns a list of dropdown options, each with a label, value, targetType,
  // and optional targetSubset (for specifying pooled or individual trusts within an org target)
  // If the `reportSupportsSearchBy` prop contains a type and we have a collection for that type
  // then that type will be displayed as an option.
  const buildOptions = () => {
    const opts = [];
    const exclusiveTrustType = reportSupportsSearchBy.trustType;

    if (origin.type === originTypes.organization) {
      // Don't show the organization option if the report is exclusive to a trust type because it would be redundant
      if (reportSupportsSearchBy.organization && !exclusiveTrustType) {
        opts.push(reportTargetTypeOptions.organization);
      }

      if (
        reportSupportsSearchBy.beneficiary &&
        entityCollections[entityTypes.trustBeneficiary]?.length
      ) {
        opts.push(reportTargetTypeOptions.trustBeneficiary);
      }

      if (
        reportSupportsSearchBy.organization &&
        exclusiveTrustType !== entityTypes.standaloneTrust &&
        entityCollections[entityTypes.pooledTrust]?.length
      ) {
        opts.push(reportTargetTypeOptions.allPooledTrusts);
      }

      if (
        reportSupportsSearchBy.trust &&
        exclusiveTrustType !== entityTypes.standaloneTrust &&
        entityCollections[entityTypes.pooledTrust]?.length
      ) {
        opts.push(reportTargetTypeOptions.pooledTrust);
      }

      if (
        reportSupportsSearchBy.portfolio &&
        entityCollections[entityTypes.portfolio]?.length &&
        entityCollections[entityTypes.pooledTrust]?.length
      ) {
        opts.push(reportTargetTypeOptions.portfolio);
      }

      if (
        reportSupportsSearchBy.organization &&
        exclusiveTrustType !== entityTypes.pooledTrust &&
        entityCollections[entityTypes.standaloneTrust]?.length
      ) {
        opts.push(reportTargetTypeOptions.allStandaloneTrusts);
      }

      if (
        reportSupportsSearchBy.trust &&
        exclusiveTrustType !== entityTypes.pooledTrust &&
        entityCollections[entityTypes.standaloneTrust]?.length
      ) {
        opts.push(reportTargetTypeOptions.standaloneTrust);
      }
    }

    return opts;
  };

  const options = buildOptions();

  if (origin.type === originTypes.organization && options.length > 0) {
    return (
      <>
        <p>Export data for:</p>
        <TrueLinkSelect
          ariaLabel="Export data for"
          id="entity-type-select"
          name="entityType"
          onChange={(event) => {
            const selectedOption = options.find((opt) => opt.value === event.target.value);
            onChange(selectedOption);
          }}
          options={options}
          sx={{
            marginBottom: 4,
          }}
          value={value}
        />
      </>
    );
  } else if ((origin.name && origin.name.trim().length > 0) || options.length > 0) {
    return <p>Export data for: {origin.name}</p>;
  }
}

EntityTypeDropdown.propTypes = {
  entityCollections: RadioDropdownCollectionsShape.isRequired,
  origin: originShape,
  reportSupportsSearchBy: PropTypes.shape({
    beneficiary: PropTypes.bool.isRequired,
    organization: PropTypes.bool.isRequired,
    portfolio: PropTypes.bool.isRequired,
    trust: PropTypes.bool.isRequired,
    trustType: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
