import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import AmountFilter from './amount_filter';
import CategoryFilter from './category_filter';
import DateRangeFilter from './date_range_filter';
import DetailView from './detail_view';
import PayeeFilter from './payee_filter';
import StatusFilter from './status_filter';
import ViewButton from './utils/ViewButton';
import { dateBetweenFilterFn } from './utils/utils';
import useDisbursementIndexQuery from 'react/member/components/dashboard/clients/hooks/useDisbursementIndexQuery';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';

export default function ApprovedDisbursementsTable({ clientId }) {
  const { data: tableData } = useDisbursementIndexQuery({
    clientId,
    filters: 'Cleared,Processed,Approved,Hold,Stopped,Stopped And Redeposited',
  });
  const deliveryDateTooltipText = (
    <span style={{ fontSize: '14px' }}>
      The date the disbursement was sent out - e.g. the date the check was put in the mail, the date
      the EFT was initiated.
    </span>
  );
  const clearedOnTooltipText = (
    <span style={{ fontSize: '14px' }}>
      The date the funds left the account - e.g. the date the check was cashed, the date the EFT was
      removed from the account.
    </span>
  );

  const globalFilterIncludingCategorySearch = (rows, id, query) =>
    rows.filter((row) => {
      const hasMatchingCategory = row.original.categoryAmounts.some((categoryAmount) =>
        categoryAmount.attributes.category.toLowerCase().includes(String(query).toLowerCase()),
      );

      const hasMatchingCellValue = Object.values(row.values).some((cellValue) =>
        String(cellValue).toLowerCase().includes(String(query).toLowerCase()),
      );

      const hasMatchingIdValue = row.original.id === String(query);

      return hasMatchingCategory || hasMatchingCellValue || hasMatchingIdValue;
    });

  const memoizedColumns = React.useMemo(
    () => [
      {
        Header: (
          <TrueLinkTooltip tooltipContent={deliveryDateTooltipText}>
            <span
              style={{
                borderBottom: `1px dashed ${PALETTE.grey3}`,
              }}
            >
              Process Date
            </span>
          </TrueLinkTooltip>
        ),
        accessor: 'deliveryDate',
        Filter: DateRangeFilter,
        filter: dateBetweenFilterFn,
        minWidth: 140,
        sortType: (a, b) => new Date(a.values.deliveryDate) - new Date(b.values.deliveryDate),
      },
      {
        Header: (
          <TrueLinkTooltip tooltipContent={clearedOnTooltipText}>
            <span
              style={{
                borderBottom: `1px dashed ${PALETTE.grey3}`,
              }}
            >
              Cleared On
            </span>
          </TrueLinkTooltip>
        ),
        accessor: 'clearedDate',
        disableFilters: true,
        minWidth: 140,
        sortType: (a, b) => new Date(a.values?.clearedDate) - new Date(b.values?.clearedDate),
      },
      {
        Header: 'Payee',
        accessor: 'attributes.payeeName',
        Filter: PayeeFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Category',
        accessor: 'meta.displayCategory',
        Filter: CategoryFilter,
        filter: 'includesSome',
        minWidth: 170,
      },
      {
        Header: 'Amount',
        accessor: (row) => row.attributes.amount.amount,
        Cell: ({ row }) => asMoney(row.values.Amount),
        filter: 'between',
        Filter: AmountFilter,
      },
      {
        Header: 'Status',
        accessor: 'attributes.status',
        Filter: StatusFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Details',
        Cell: ({ row }) => ViewButton(row),
        disableSort: true,
        sortable: false,
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const initialSortBy = useMemo(() => [{ id: 'deliveryDate', desc: true }], []);
  const memoizedData = useMemo(() => tableData, [tableData]);
  const renderRowSubComponent = useCallback(
    (row) => <DetailView clientId={clientId} disbursementId={row.original.id} />,
    [clientId],
  );
  return tableData ? (
    <>
      <TrueLinkTable
        className="approved-disbursements-table"
        columns={memoizedColumns}
        data={memoizedData}
        filterSection
        filterType="approved"
        globalFilterOverride={globalFilterIncludingCategorySearch}
        initialSortBy={initialSortBy}
        isFilterable
        pageSize={10}
        paginated
        rowSubComponent={renderRowSubComponent}
        showPagination
        variant="compressed"
      />
      {tableData?.length > 3000 && (
        <Typography>Note: Limited to the most recent 3,000 disbursements.</Typography>
      )}
      {tableData?.length <= 3000 && (
        <Typography>Note: Limited to disbursements created in the past 10 years.</Typography>
      )}
    </>
  ) : (
    <>
      <Typography style={{ fontSize: '24px', lineHeight: '31px' }} variant="body1">
        Approved Disbursements
      </Typography>
      <LoadingIndicator />
    </>
  );
}

ApprovedDisbursementsTable.propTypes = {
  clientId: PropTypes.string,
};
