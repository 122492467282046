import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as Yup from 'yup';
import ReferralIncentivesForm from './ReferralIncentivesForm';
import HowItWorksReferralCard from './referral_cards/HowItWorksReferralCard';
import GiftSvg from 'images/referrals/gift.svg';
import WizardCard from 'react/shared/components/true_link/lab/form/wizard/WizardCard';
import theme, { mediumBreakpointMediaQuery } from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import schema from 'react/shared/utils/schemas';

const useStyles = makeStyles(() => ({
  containerWapper: {
    marginTop: '60px',
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
    },
  },
  cardContainer: {
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
    padding: '54px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      padding: '10px',
    },
  },
  textContainer: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      gap: '20px',
    },
  },
}));

export default function ReferralIncentives(props) {
  const classes = useStyles();
  const desktop = useMediaQuery(mediumBreakpointMediaQuery);
  const title =
    props.referrerType == 'consumer'
      ? 'Share True Link and get 3 monthly fees waived*'
      : 'Share True Link and give three monthly fees waived*';
  const subTitle = `Do you know someone who could benefit from True Link?
  ${
    props.referrerType == 'consumer'
      ? " We'll waive* 3 months of True Link fees for both of you when they activate the "
      : " We'll waive* three months of True Link monthly fees for the person you refer when they activate their "
  }True Link Visa® Prepaid Card.`;

  const wrapperContainer = document.querySelector('#wrapper');
  if (wrapperContainer && desktop) {
    wrapperContainer.style.backgroundColor = PALETTE.grey6;
  } else if (wrapperContainer) {
    wrapperContainer.style.backgroundColor = PALETTE.white;
  }

  const initialValues = {
    email: '',
    referralUrl: props.referralUrl,
  };

  const goBack = () => {
    window.location.href = RailsRoutes.accounts_list_path();
  };

  const validationSchema = Yup.object().shape({
    email: schema.email.required(),
  });

  return (
    <Container className={classes.containerWapper} maxWidth="md">
      <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <WizardCard className={classes.cardContainer} onBack={goBack} showBackButton>
          <Grid className={classes.gridContainer} container spacing={2}>
            <Grid item>
              <img alt="gift icon" src={GiftSvg} width={68} />
            </Grid>
            <Grid item md={10} sm={12}>
              <div className={classes.textContainer}>
                <Typography variant="h3">{title}</Typography>
                <Typography variant="body1">{subTitle}</Typography>
              </div>
              <Formik initialValues={initialValues} validationSchema={validationSchema}>
                {({ handleChange, handleReset, setFieldTouched, values }) => (
                  <ReferralIncentivesForm
                    onChange={handleChange}
                    referralSlug={props.referralSlug}
                    resetForm={handleReset}
                    setFieldTouched={setFieldTouched}
                    values={values}
                  />
                )}
              </Formik>
            </Grid>
          </Grid>
        </WizardCard>
      </BrowserRouter>
      <HowItWorksReferralCard referrerType={props.referrerType} />
    </Container>
  );
}

ReferralIncentives.propTypes = {
  referrerType: PropTypes.oneOf(['consumer', 'professional']).isRequired,
  referralSlug: PropTypes.string,
  referralUrl: PropTypes.string,
};
