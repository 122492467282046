import PropTypes from 'prop-types';
import React from 'react';
import DocumentViewButton from 'react/shared/components/document_viewer/DocumentViewButton';

export default function DisbursementCategoryAmountAttachment({ attachment, isCheckInsert }) {
  const smartpayablesInsertControl = isCheckInsert ? (
    <span>
      <i className="icon-checkmark" />
    </span>
  ) : null;

  return (
    <tr>
      <td className="break-word">
        <a className="inline-block" href={RailsRoutes.api_v2_attachment_path(attachment.id)}>
          {' '}
          {attachment.attributes.name}
        </a>
      </td>
      <td>
        <div className="inline-block">{smartpayablesInsertControl}</div>
      </td>
      <td style={{ textAlign: 'right' }}>
        {$tlf.flipperShowViewDocumentButton && (
          <DocumentViewButton attachment={attachment.attributes} />
        )}
      </td>
    </tr>
  );
}

DisbursementCategoryAmountAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  isCheckInsert: PropTypes.bool.isRequired,
};
