/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import PostageType from 'react/member/components/dashboard/disbursements/PostageType';
import DashboardOneTimePayeeUpdateForm from 'react/member/components/dashboard/disbursements/disbursement_details/DashboardOneTimePayeeUpdateForm';
import DashboardPayee from 'react/member/components/dashboard/payees/DashboardPayee';
import PayeeDropdown from 'react/member/components/dashboard/payees/PayeeDropdown';
import PaymentMethodDropdown from 'react/shared/components/PaymentMethodDropdown';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

const mapPaymentMethod = [
  { label: 'True Link Card', id: 'Card' },
  { label: 'Electronic Funds Transfer', id: 'EFT' },
  { label: 'Check (True Link Fulfills)', id: 'Check' },
  { label: 'Check (Generate PDF)', id: 'External Check' },
  {
    label: 'Direct Debit',
    id: 'Direct Debit',
  },
  {
    label: 'Wire',
    id: 'Wire',
  },
  {
    label: 'Exchange',
    id: 'Exchange',
  },
];

const useStyles = makeStyles(() => ({
  newFormField: {
    minHeight: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  newFormLabel: {
    width: '220px',
    lineHeight: '1.2em',
    paddingTop: '9px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  newFormData: {
    width: 'calc(100% - 220px)',
    fontSize: '18px',
    lineHeight: '1.2em',
  },
  disbursementPaymentForm: {
    '& .new-form__label ': {
      width: '220px',
    },
    '& .new-form__data ': {
      width: 'calc(100% - 220px)',
    },
    '& .MuiInputBase-root': {
      width: '300px',
    },
    '& select': {
      width: '300px',
      height: '45px',
    },
    '& #payee_payment_method': {
      width: '300px',
    },
  },
}));

export default function DisbursementPaymentForm({
  data,
  displayedPaymentTypes,
  handleChange,
  isEditing,
  payees,
  payeesLoaded,
  setFieldTouched,
  setFieldValue,
  trustBeneData,
  userRoles,
  values,
}) {
  const classes = useStyles();
  const { toYesNo } = tlFieldTransformers;
  const [showNewPayeeModal, setShowNewPayeeModal] = useState(false);
  const [showOneTimePayeeUpdateModal, setShowOneTimePayeeUpdateModal] = useState(false);

  const paymentMethod = values.payment_method;

  const canEditPayee =
    isEditing && ['Check', 'External Check', 'EFT', 'Wire'].includes(paymentMethod);

  const canEditPostageType = isEditing && ['Check'].includes(paymentMethod);

  const canEditCheckMemoLine =
    isEditing && (paymentMethod === 'Check' || paymentMethod === 'External Check');

  const canOneTimeEdit = isEditing && ['Check', 'External Check'].includes(paymentMethod);

  const paymentMethodLabel = mapPaymentMethod.find((method) => method.id === paymentMethod)?.label;

  const supportedPaymentMethods = displayedPaymentTypes?.filter(
    (type) => !['Exchange', ''].includes(type),
  );

  useEffect(() => {
    if (!values.card_id && values.payment_method === 'Card' && isEditing) {
      const client = DashboardClientStore.get(trustBeneData.id);
      let nonClosedCards;
      if (client) {
        nonClosedCards = client.cards.filter((card) => card.status !== 'CLOSED');
      } else {
        nonClosedCards = [];
      }

      const selectedCard =
        nonClosedCards.find((card) => card.id === values.card_id) || nonClosedCards[0];

      if (selectedCard) {
        setFieldValue('card_id', selectedCard.id);
        setFieldTouched('card_id', true);
      }
    }
  }, [
    values.card_id,
    setFieldValue,
    values.payment_method,
    isEditing,
    trustBeneData.id,
    setFieldTouched,
  ]);

  const handlePayeeChange = (selectedPayee) => {
    if (selectedPayee) {
      setFieldValue('payee_id', selectedPayee);
      setFieldTouched('payee_id', true);
    } else {
      setFieldValue('payee_id', null);
    }
  };

  const paymentMethodFormInput = (
    <PaymentMethodDropdown
      displayedPaymentMethods={supportedPaymentMethods}
      formType="edit"
      handlePaymentMethod={handleChange}
      hideLabel
      isEditable
      paymentMethod={values.payment_method}
    />
  );

  const payeeFormInput = (
    <PayeeDropdown
      btnStyle
      canCreatePayee={!userRoles.viewOnly}
      currentClientId={trustBeneData.id}
      currentPaymentMethod={paymentMethod}
      handlePayeeChosen={(selectedPayee) => handlePayeeChange(selectedPayee)}
      hideLabel
      isEditing
      key={payees.length ? 'loaded' : 'not loaded'}
      payees={payees}
      payeesLoaded={payeesLoaded}
      selectedPayee={values.payee_id}
      togglePayeeForm={() => setShowNewPayeeModal(true)}
    />
  );

  const cardField = () => {
    const client = DashboardClientStore.get(trustBeneData.id);
    let nonClosedCards;
    if (client) {
      nonClosedCards = client.cards.filter((card) => card.status !== 'CLOSED');
    } else {
      nonClosedCards = [];
    }

    const cards = nonClosedCards.map((card) => (
      <option key={card.slug} value={card.id}>
        {card.name}
      </option>
    ));

    if (_isEmpty(cards)) {
      const uri = window.location.href.split('#')[0];
      const link = `${uri}?reload=1#client-card-tab`;

      return (
        <div className="new-form__callout new-form__callout--warning">
          {"There are no True Link Cards linked to this beneficiary's account."}
          <br />
          Please click <a href={link}>here</a> to link a True Link Card to this beneficiary.
        </div>
      );
    }
    return (
      <select name="card_id" onChange={handleChange} value={values.card_id}>
        {cards}
      </select>
    );
  };

  const checkMemoLineField = isEditing ? (
    <TrueLinkTextInput
      name="check_memo_line"
      onChange={handleChange}
      setFieldTouched={setFieldTouched}
      value={values.check_memo_line}
    />
  ) : (
    data.attributes.checkMemoLine
  );

  const paymentTypeSection = (
    <Box className={`${classes.newFormField} payment-type-field`}>
      <Box className={classes.newFormLabel}>Payment Type:</Box>
      <Box className={classes.newFormData}>
        {isEditing ? paymentMethodFormInput : paymentMethodLabel}
      </Box>
    </Box>
  );
  const payeeSection = (
    <Box className={`${classes.newFormField} payee-field`}>
      <Box className={classes.newFormLabel}>Payee:</Box>
      <Box className={classes.newFormData}>
        {canEditPayee ? payeeFormInput : data.attributes.payeeName}
      </Box>
    </Box>
  );
  const checkMemoLineSection = (values.check_memo_line || canEditCheckMemoLine) && (
    <Box className={`${classes.newFormField} check-memo-field`}>
      <Box className={classes.newFormLabel}>Check Memo Line:</Box>
      <Box className={classes.newFormData}> {checkMemoLineField}</Box>
    </Box>
  );
  const cardSection = values.payment_method === 'Card' && isEditing && (
    <Box className={`${classes.newFormField} card-field`}>
      <Box className={classes.newFormLabel}>Card:</Box>
      <Box className={classes.newFormData}> {cardField()}</Box>
    </Box>
  );

  const postageTypeInput = (
    <PostageType
      codeCallback={handleChange}
      inputName="postage_code"
      selectedPostage={values.postage_code}
    />
  );

  const postageTypeSection = (data.attributes.postageType || isEditing) && (
    <>
      {canEditPostageType ? (
        postageTypeInput
      ) : (
        <Box className={`${classes.newFormField} postage-type-field`}>
          <Box className={classes.newFormLabel}>Postage Type:</Box>
          <Box className={classes.newFormData}> {data.attributes.postageType} </Box>
        </Box>
      )}
    </>
  );
  const toggleOneTimePayeeUpdateModal = () => {
    setShowOneTimePayeeUpdateModal(!showOneTimePayeeUpdateModal);
  };

  const renderOneTimePayeeUpdateModalLink = (
    <Box className={`${classes.newFormField} one-time-edit-field`}>
      <Box className={classes.newFormLabel} />
      <Box className={classes.newFormData}>
        <a href="#" onClick={toggleOneTimePayeeUpdateModal}>
          One Time Edit
        </a>
        <InfoTooltip
          placement="right"
          tooltipText="This is a one time edit of the payee address. This edit does not affect the existing payee record on the 'Payees' tab."
        />
      </Box>
    </Box>
  );

  return (
    <tr
      className={`${classes.disbursementPaymentForm} disbursement-payment-form`}
      style={{ borderBottom: '1px solid lightgrey' }}
    >
      <td colSpan={8}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '90%' }}>
            {paymentTypeSection}
            {cardSection}
            {payeeSection}
            {data.attributes.payeeAddress && (
              <Box className={`${classes.newFormField} payee-address-field`}>
                <Box className={classes.newFormLabel}>Payee Address:</Box>
                <Box className={classes.newFormData}>
                  {`${data.attributes.payeeAddress.street1 || ''} ${
                    data.attributes.payeeAddress.street2 || ''
                  }`}
                  {'  '}
                  {` ${data.attributes.payeeAddress.city || ''}, ${
                    data.attributes.payeeAddress.state || ''
                  } ${data.attributes.payeeAddress.zip || ''}`}
                </Box>
              </Box>
            )}
            {canOneTimeEdit && renderOneTimePayeeUpdateModalLink}
            {showOneTimePayeeUpdateModal && (
              <DashboardOneTimePayeeUpdateForm
                closeModal={toggleOneTimePayeeUpdateModal}
                disbursement={{
                  ...data.attributes.payeeAddress,
                  payeeMemo: data.attributes.payeeMemo,
                  id: data.id,
                }}
              />
            )}
            {data.attributes.payeeMemo && (
              <Box className={`${classes.newFormField} payee-memo-field`}>
                <Box className={classes.newFormLabel}>Memo:</Box>
                <Box className={classes.newFormData}>{data.attributes.payeeMemo}</Box>
              </Box>
            )}
            {data.attributes.payeeDisposition && (
              <Box className={`${classes.newFormField} payee-disposition-field`}>
                <Box className={classes.newFormLabel}>Disposition:</Box>
                <Box className={classes.newFormData}>{data.attributes.payeeDisposition}</Box>
              </Box>
            )}
            {data.attributes.requestorDescription && (
              <Box className={`${classes.newFormField} requestor-description-field`}>
                <Box className={classes.newFormLabel}>Requestor Description:</Box>
                <Box className={classes.newFormData}>{data.attributes.requestorDescription}</Box>
              </Box>
            )}
            {data.attributes.payeeSwiftCode && (
              <Box className={`${classes.newFormField} swift-code-field`}>
                <Box className={classes.newFormLabel}>SWIFT Code:</Box>
                <Box className={classes.newFormData}>{data.attributes.payeeSwiftCode}</Box>
              </Box>
            )}
            {data.attributes.payeeBankAccount && (
              <Box className={`${classes.newFormField} bank-account-field`}>
                <Box className={classes.newFormLabel}>
                  <span>Payee Bank</span>
                  <br />
                  <span>Information:</span>
                </Box>
                <Box className={classes.newFormData}>
                  {data.attributes.payeeBankAccount?.routingNumber && (
                    <span>Routing #: {data.attributes.payeeBankAccount?.routingNumber}</span>
                  )}
                  <br />
                  {data.attributes.payeeBankAccount?.accountNumber && (
                    <span>Account #: {data.attributes.payeeBankAccount?.accountNumber}</span>
                  )}
                </Box>
              </Box>
            )}
            {data.attributes.payeeSpecialInstructions && (
              <Box className={`${classes.newFormField} special-instructions-field`}>
                <Box className={classes.newFormLabel}>Special Instructions:</Box>
                <Box className={classes.newFormData}>
                  {data.attributes.payeeSpecialInstructions}
                </Box>
              </Box>
            )}
            {data.attributes.checkNum && (
              <Box className={`${classes.newFormField} check-number-field`}>
                <Box className={classes.newFormLabel}>Check Number:</Box>
                <Box className={classes.newFormData}>{data.attributes.checkNum}</Box>
              </Box>
            )}
            {checkMemoLineSection}
            {postageTypeSection}
            {showNewPayeeModal && (
              <DashboardPayee
                className="dashboard-new-payee"
                clientSlug={trustBeneData.id}
                displayedPaymentMethods={displayedPaymentTypes}
                onClose={() => setShowNewPayeeModal(false)}
                paymentMethod={paymentMethod}
                userRoles={userRoles}
              />
            )}
          </div>
          <div style={{ width: '50%' }}>
            {trustBeneData?.organization?.meta?.ableToOverdrawDisbursements && (
              <Box className={`${classes.newFormField} overdraw-allowed-field`}>
                <Box className={classes.newFormLabel}>Overdraw Allowed:</Box>
                <Box className={classes.newFormData}>
                  {toYesNo(trustBeneData.organization.overdrawAllowed).toUpperCase()}
                </Box>
              </Box>
            )}
            {trustBeneData?.govtBenefitsResults.map((benefit) => (
              <Box className={`${classes.newFormField} govt-benefit-field`} key={benefit.name}>
                <Box className={classes.newFormLabel}>{benefit.name}: </Box>
                <Box className={classes.newFormData}>{benefit.value}</Box>
              </Box>
            ))}
          </div>
        </div>
      </td>
    </tr>
  );
}
