import PropTypes from 'prop-types';
import React from 'react';
import PayeeDropdown from 'react/member/components/dashboard/payees/PayeeDropdown';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPaymentMethodInputs extends React.Component {
  constructor(props) {
    super(props);

    const { defaultPayee, checkMemoLine } = this.props;

    this.state = defaultPayee
      ? {
          payee: defaultPayee,
          enteredCheckMemo: checkMemoLine || false,
          currentClientAccountNumber: defaultPayee.client_account_number,
          payeeDisposition: defaultPayee.disposition || '',
          currentMerchant: '',
        }
      : {
          payee: null,
          enteredCheckMemo: '',
          currentClientAccountNumber: '',
          payeeDisposition: '',
          currentMerchant: '',
        };

    bindAll(this);
  }

  componentDidUpdate(prevProps) {
    // This is legacy behavior and would require refactoring the state structure to fix. New behavior should not thrash the render cycle this way.
    if (prevProps.currentMerchant !== this.props.currentMerchant) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentMerchant: this.props.currentMerchant || '' });
    }
    if (prevProps.currentPaymentMethod !== this.props.currentPaymentMethod) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        payee: null,
        currentClientAccountNumber: '',
        enteredCheckMemo: '',
        payeeDisposition: '',
      });
    }
    if (prevProps.defaultPayee !== this.props.defaultPayee) {
      const { defaultPayee } = this.props;
      const { checkMemoLine } = this.props;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        payee: defaultPayee,
        enteredCheckMemo: checkMemoLine || '',
        currentClientAccountNumber: defaultPayee ? defaultPayee.client_account_number : '',
        payeeDisposition: defaultPayee ? defaultPayee.disposition : '',
      });
    }
  }

  handleCheckMemoLineChange(e) {
    if (this.props.onCheckMemoChange) {
      this.props.onCheckMemoChange(e);
    }
    this.setState({
      enteredCheckMemo: e.target.value,
    });
  }

  handleMerchantChange(e) {
    this.setState({
      currentMerchant: e.target.value,
    });
  }

  handlePayeeDispositionChange(e) {
    this.setState({
      payeeDisposition: e.target.value || '',
    });
  }

  handlePayeeChosen(payee) {
    if (this.props.onPayeeChange) {
      this.props.onPayeeChange(payee);
    }
    this.setState({
      payee,
      currentClientAccountNumber: payee && payee.client_account_number,
      payeeDisposition: payee && payee.disposition,
    });
  }

  hiddenCheckMemo() {
    return [this.state.currentClientAccountNumber, this.state.enteredCheckMemo].join(' ');
  }

  maxCheckMemoLength() {
    const { currentClientAccountNumber } = this.state;

    if (currentClientAccountNumber) {
      return 47 - currentClientAccountNumber.length;
    }
    return 48;
  }

  charactersLeft(fieldName, maxCharacters) {
    const field = this.state[fieldName];
    const fieldLength = field ? field.length : 0;
    return maxCharacters - fieldLength;
  }

  renderCheckMemoLineInput() {
    const { currentClientAccountNumber } = this.state;
    if (currentClientAccountNumber && currentClientAccountNumber.length >= 48) {
      return;
    }

    const charactersLeft = this.charactersLeft('enteredCheckMemo', this.maxCheckMemoLength());

    return (
      <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
        <input
          id="checkMemoLine"
          maxLength={this.maxCheckMemoLength()}
          name="check_memo_line"
          onChange={this.handleCheckMemoLineChange}
          style={{ marginRight: 10 }}
          type="text"
          value={this.state.enteredCheckMemo}
        />
        <input name="check_memo" type="hidden" value={this.hiddenCheckMemo()} />
        <span className={charactersLeft < 10 ? 'red' : null}>{charactersLeft} characters left</span>
      </div>
    );
  }

  renderCheckMemoLineFields() {
    const { currentPaymentMethod } = this.props;
    const { currentClientAccountNumber } = this.state;

    if (!['Check', 'External Check'].includes(currentPaymentMethod)) {
      return;
    }

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor="checkMemoLine">Additional check memo (Optional)</label>
        </div>
        <div className="new-form__data">
          {currentClientAccountNumber && (
            <div style={{ display: 'inline-block', marginRight: '10px' }}>
              <span>{currentClientAccountNumber}</span>
              <div
                style={{
                  borderTop: '1px solid #ccc',
                  fontStyle: 'italic',
                  fontWeight: '300',
                }}
              >
                Memo
              </div>
            </div>
          )}
          {this.renderCheckMemoLineInput()}
        </div>
      </div>
    );
  }

  renderCheckDisposition() {
    const { currentPaymentMethod } = this.props;
    if (currentPaymentMethod !== 'External Check') {
      return;
    }

    const maxDispositionLength = 17;

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor="check_payee_disposition">Disposition</label>
        </div>
        <div className="new-form__data">
          <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
            <input
              id="check_payee_disposition"
              maxLength={maxDispositionLength}
              name="payee_disposition"
              onChange={this.handlePayeeDispositionChange}
              type={'hidden'}
              value={this.state.payeeDisposition || ''}
            />
            <span>{this.state.payeeDisposition}</span>
          </div>
        </div>
      </div>
    );
  }

  renderPayeeDropdown() {
    const { currentPaymentMethod, payees, payeesLoaded, togglePayeeForm } = this.props;
    if (!['Check', 'External Check', 'EFT', 'Wire'].includes(currentPaymentMethod)) {
      return;
    }

    let key = 'not loaded';
    if (payees.length > 0) {
      key = 'loaded';
    }

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor="payeeId">Payee</label>
        </div>
        <div className="new-form__data">
          <PayeeDropdown
            canCreatePayee={this.props.canCreatePayee}
            currentPaymentMethod={currentPaymentMethod}
            handlePayeeChosen={this.handlePayeeChosen}
            hideLabel
            key={key}
            payees={payees}
            payeesLoaded={payeesLoaded}
            selectedPayee={this.state.payee}
            togglePayeeForm={togglePayeeForm}
          />
          {this.props.formErrors && this.props.formErrors.payee_id && (
            <p className="form-error">{this.props.formErrors.payee_id}</p>
          )}
        </div>
      </div>
    );
  }

  renderMerchant() {
    const { currentPaymentMethod } = this.props;

    if (currentPaymentMethod !== 'Direct Debit') {
      return;
    }

    return (
      <div>
        <div className="new-form__label">
          <label htmlFor="merchant">Merchant</label>
        </div>
        <div className="new-form__data">
          <input
            id="merchant"
            name="merchant"
            onChange={this.handleMerchantChange}
            type="text"
            value={this.state.currentMerchant}
          />
        </div>
      </div>
    );
  }

  render() {
    const payeeDropdown = this.renderPayeeDropdown();
    const checkMemoLine = this.renderCheckMemoLineFields();
    const checkDisposition = this.renderCheckDisposition();
    const merchant = this.renderMerchant();

    if (payeeDropdown || checkMemoLine || merchant) {
      return (
        <div className="new-form__section">
          {payeeDropdown}
          {checkMemoLine}
          {checkDisposition}
          {merchant}
        </div>
      );
    }
    return <div />;
  }
}

DashboardPaymentMethodInputs.propTypes = {
  checkMemoLine: PropTypes.string,
  disposition: PropTypes.string,
  currentPaymentMethod: PropTypes.string.isRequired,
  currentMerchant: PropTypes.string,
  client: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  payees: PropTypes.array.isRequired,
  payeesLoaded: PropTypes.bool.isRequired,
  togglePayeeForm: PropTypes.func.isRequired,
  onPayeeChange: PropTypes.func,
  onCheckMemoChange: PropTypes.func,
  formErrors: PropTypes.shape({
    payee_id: PropTypes.string,
  }),
  canCreatePayee: PropTypes.bool.isRequired,
  defaultPayee: PropTypes.shape({
    id: PropTypes.number,
    client_account_number: PropTypes.string,
    disposition: PropTypes.string,
  }),
};
