import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DocumentViewButton from 'react/shared/components/document_viewer/DocumentViewButton';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import PALETTE from 'react/shared/theme/palette';
export default function CardTransactionAttachmentRow({
  attachment,
  canEdit,
  cardTransactionId,
  isCardholderView,
}) {
  const [showModal, setShowModal] = useState(false);

  const closeDestructionModal = () => {
    setShowModal(false);
  };

  const showDestructionModal = () => {
    setShowModal(true);
  };

  const onDestroy = () => {
    DashboardActions.destroyTransactionAttachment(attachment.id, cardTransactionId);
    closeDestructionModal();
  };

  return (
    <div className="attachment" key={attachment.id}>
      <div className="attachment__text">{attachment.attributes?.name}</div>
      <div className="attachment__meta flex flex--space-between">
        {!isCardholderView && (
          <>
            <div>
              Uploaded {moment(attachment.attributes?.createdAt).format('M/D/YY h:mm A')}
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;
              {attachment.attributes?.uploader}
            </div>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              {$tlf.flipperShowViewDocumentButton && (
                <DocumentViewButton attachment={attachment.attributes} />
              )}
              <Button
                aria-label="download document"
                href={RailsRoutes.api_v2_attachment_path(attachment.id)}
                size="small"
                startIcon={<DownloadIcon sx={{ fill: PALETTE.blue }} />}
                sx={{
                  '&.MuiButton-root': {
                    textTransform: 'capitalize',
                    color: PALETTE.blue,
                  },
                }}
              >
                Download
              </Button>
              {canEdit && (
                <Button
                  aria-label="delete document"
                  id="delete-document-button"
                  onClick={showDestructionModal}
                  size="small"
                  startIcon={<DeleteIcon sx={{ fill: PALETTE.blue }} />}
                  sx={{
                    '&.MuiButton-root': {
                      textTransform: 'capitalize',
                      color: PALETTE.blue,
                    },
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
          </>
        )}
        {isCardholderView && (
          <div>Uploaded {moment(attachment.attributes?.createdAt).format('M/D/YY h:mm A')}</div>
        )}
      </div>
      <input name="attachment_ids[]" readOnly style={{ display: 'none ' }} value={attachment.id} />
      <AreYouSurePopUp
        messageText={'Are you sure you want to delete this receipt?'}
        onClose={closeDestructionModal}
        onSubmit={onDestroy}
        openModal={showModal}
        submitText={'Yes, delete'}
      />
    </div>
  );
}

CardTransactionAttachmentRow.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      uploader: PropTypes.string.isRequired,
    }),
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
  cardTransactionId: PropTypes.string.isRequired,
  isCardholderView: PropTypes.bool,
};
