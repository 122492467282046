/* eslint-disable react/no-multi-comp */
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useEffect } from 'react';
import AmountFilter from './amount_filter';
import CategoryFilter from './category_filter';
import ClientFilter from './client_filter';
import PayeeFilter from './payee_filter';
import RequestedByFilter from './requested_by_filter';
import TrustFilter from './trust_filter';
import ApprovalButtons from './utils/ApprovalButtons';
import usePendingDisbursementIndexQuery from 'react/member/components/dashboard/clients/hooks/usePendingDisbursementIndexQuery';
import PendingDisbursementsDetailViewWrapper from 'react/member/components/dashboard/disbursements/disbursement_details/PendingDisbursementsDetailViewWrapper';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardDisbursementStore from 'react/member/stores/DashboardDisbursementStore';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';

export default function PendingDisbursementsTable({
  clientSlug,
  organizationSlug,
  renderOnHoldDisbursements,
  showSendDisbursementBackToPending,
  userRoles,
  filter,
}) {
  const { data: tableData, refetch } = usePendingDisbursementIndexQuery({
    clientId: clientSlug,
    filters: filter,
    organizationSlug,
  });

  useEffect(() => {
    DashboardDisbursementStore.on('disbursement.update', refetch);
    DashboardDisbursementStore.on('disbursement.delete', refetch);
    RecurringDisbursementStore.on('recurringDisbursement.update', refetch);

    return () => {
      DashboardDisbursementStore.off('disbursement.update', refetch);
      DashboardDisbursementStore.off('disbursement.delete', refetch);
      RecurringDisbursementStore.off('recurringDisbursement.update', refetch);
    };
  }, [refetch]);

  const deliveryDateTooltipText = (
    <span style={{ fontSize: '14px' }}>
      The date the disbursement was sent out - e.g. the date the check was put in the mail, the date
      the EFT was initiated.
    </span>
  );

  const globalFilterIncludingCategorySearch = (rows, id, query) =>
    rows.filter((row) => {
      const hasMatchingCategory = row.original.categoryAmounts?.some((categoryAmount) =>
        categoryAmount.attributes.category.toLowerCase().includes(String(query).toLowerCase()),
      );

      const hasMatchingCellValue = Object.values(row.values)?.some((cellValue) =>
        String(cellValue).toLowerCase().includes(String(query).toLowerCase()),
      );

      const hasMatchingIdValue = row.original.id === String(query);

      return hasMatchingCategory || hasMatchingCellValue || hasMatchingIdValue;
    });

  const formatDate = (date) => {
    if (date) {
      return moment(date).format('L');
    }
  };
  const formatFromNow = (row) => {
    if (row.attributes?.createdAt) {
      return moment(row.attributes?.createdAt).fromNow(true);
    }
  };

  const formatProcessDate = (row) => {
    if (row.deliveryDate) {
      return formatDate(row.deliveryDate);
    }
    if (row.attributes.paymentMethod === 'Card') {
      return 'Immediately on Approval';
    }
    return 'On Approval';
  };

  const formatClient = (row) => {
    const beneficiaryId = row.trustBeneficiary.id;
    const trustId = row.trustBeneficiary?.relationships.trust.data.id;
    const clientRoute = RailsRoutes.dashboard_trust_client_path(trustId, beneficiaryId);
    const clientName = row.trustBeneficiary.fullName;
    return (
      <a href={clientRoute} rel="noreferrer" target="_blank">
        {clientName}
      </a>
    );
  };

  const memoizedColumns = React.useMemo(
    () => [
      {
        Header: (
          <TrueLinkTooltip tooltipContent={deliveryDateTooltipText}>
            <span
              style={{
                borderBottom: `1px dashed ${PALETTE.grey3}`,
              }}
            >
              Process Date
            </span>
          </TrueLinkTooltip>
        ),
        // eslint-disable-next-line no-unused-vars
        Cell: ({ row }) => formatProcessDate(row.original),
        accessor: 'deliveryDate',
        disableFilters: true,
        sortType: (a, b) => new Date(a.values.deliveryDate) - new Date(b.values.deliveryDate),
      },
      {
        Header: 'Days Pending',
        Cell: ({ row }) => formatFromNow(row.original),
        accessor: 'clearedDate',
        disableFilters: true,
      },
      {
        Header: 'Client',
        Cell: ({ row }) => formatClient(row.original),
        accessor: 'trustBeneficiary.fullName',
        disableFilters: !!clientSlug,
        Filter: ClientFilter,
        filter: 'includesSome',
        hidden: !!clientSlug,
      },
      {
        Header: 'Trust',
        accessor: 'trustName',
        disableFilters: !!clientSlug,
        Filter: TrustFilter,
        filter: 'includesSome',
        hidden: !!clientSlug,
      },
      {
        Header: 'Requested By',
        accessor: 'requestedBy',
        Filter: RequestedByFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Payee',
        accessor: 'attributes.payeeDescription',
        Filter: PayeeFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Category',
        accessor: 'meta.displayCategory',
        Filter: CategoryFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Amount',
        accessor: (row) => row.attributes.amount.amount,
        Cell: ({ row }) => asMoney(row.values.Amount),
        filter: 'between',
        Filter: AmountFilter,
      },
      {
        Header: 'Approval',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <ApprovalButtons row={row} />,
        disableFilters: true,
        disableSort: true,
        sortable: false,
        width: 200,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const initialSortBy = useMemo(() => [{ id: 'deliveryDate', desc: false }], []);
  const memoizedData = useMemo(() => tableData, [tableData]);
  const renderRowSubComponent = useCallback(
    (row) => (
      <PendingDisbursementsDetailViewWrapper
        clientSlug={row.original.trustBeneficiary?.id}
        disbursementId={row.original.id}
        renderOnHoldDisbursements={renderOnHoldDisbursements}
        showSendDisbursementBackToPending={showSendDisbursementBackToPending}
        userRoles={userRoles}
      />
    ),
    [renderOnHoldDisbursements, showSendDisbursementBackToPending, userRoles],
  );

  return tableData ? (
    <>
      <TrueLinkTable
        className="pending-disbursements-table"
        columns={memoizedColumns}
        data={memoizedData}
        filterSection
        filterType={filter}
        globalFilterOverride={globalFilterIncludingCategorySearch}
        initialSortBy={initialSortBy}
        isFilterable
        pageSize={10}
        paginated
        rowSubComponent={renderRowSubComponent}
        showPagination
        variant="compressed"
      />
      {tableData?.length > 3000 && (
        <Typography>Note: Limited to the most recent 3,000 disbursements.</Typography>
      )}
      {tableData?.length <= 3000 && (
        <Typography>Note: Limited to disbursements created in the past 10 years.</Typography>
      )}
    </>
  ) : (
    <>
      <Typography style={{ fontSize: '24px', lineHeight: '31px' }} variant="body1">
        Disbursements Pending {filter === 'additional approval' ? 'Additional' : ''} Approval
      </Typography>
      <LoadingIndicator />
    </>
  );
}

PendingDisbursementsTable.propTypes = {
  clientSlug: PropTypes.string,
  organizationSlug: PropTypes.string,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
  filter: PropTypes.string,
};
